import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { showNotification } from "@mantine/notifications";
import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export type GetDeviceParametersCommand = {
    DeviceId: number;
};

export const getDeviceParameters = (command: GetDeviceParametersCommand) => {
    return axios.post(`/devices/${command.DeviceId}/device-parameters/get`, command);
};

type UseGetDeviceParametersOptions = {
    config?: MutationConfig<typeof getDeviceParameters>;
};

export const useGetDeviceParameters = ({ config }: UseGetDeviceParametersOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {},
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка при отправке запроса!"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            showNotification({
                title: t("Успех!"),
                message: t("Запрос успешно отправлен!"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: getDeviceParameters,
    });
};
