import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { СhannelDeviceModelEnum } from "src/features/devices/types";

export const getAllChannels = (): Promise<{Id: СhannelDeviceModelEnum, Name: string}[]> => {
    return axios.get(`/Channels/all`);
};

type QueryFnType = typeof getAllChannels;

export const useAllChannels = () => {
    const { t } = useTranslation();
    let result = useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ["allChannels"],
        queryFn: () => getAllChannels(),
        refetchInterval: 30000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
    
    return result
};
