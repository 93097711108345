import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { Tower_small } from "../types";

export const getTowers = (): Promise<Tower_small[]> => {
    return axios.get(`/OverheadPowerLineTower`);
};

type QueryFnType = typeof getTowers;

export const useTowers = () => {
    const { t } = useTranslation();
    let result = useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ["Towers"],
        queryFn: () => getTowers(),
        refetchInterval: 30000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка при загрузке опор"),
            });
        },
    });

    return result;
};
