import { Button, Drawer, Flex, Group, Paper, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RemoteControlCard } from "./RemoteControlCard";
import { OverheadPowerLineTowerDeviceParametersDto, Tower_full } from "../types";
import { useDevices } from "src/features/devices/api/getDevices";
import { useCreateParameter } from "../api/createParameter";
import { CreateOPLChannelDrawer } from "./CreateOPLChannelDrawer";
import { CreateOPLParameterDrawer } from "./CreateOPLParameterDrawer";

type RemoteControlProps = {
    selectedTower: Tower_full,
};

export const RemoteControl: React.FC<RemoteControlProps> = ({selectedTower}) => {
    const { t } = useTranslation();

    const [ params, setParams ] = useState<OverheadPowerLineTowerDeviceParametersDto[]>([])

    useEffect(()=>{
        if (selectedTower && selectedTower?.OverheadPowerLineTowerDeviceParametersDtos){
            setParams(selectedTower.OverheadPowerLineTowerDeviceParametersDtos)
        }
    }, [selectedTower])

    const [openedAddParameter, {open: openAddParameter, close: closeAddParameter}] = useDisclosure(false)
    const [openedAddChannel, {open: openAddChannel, close: closeAddChannel}] = useDisclosure(false)

    return (
        <>
            <Stack>
                <Flex style={{width: "100%"}} justify="right">
                    <Group>
                        <Button leftIcon={<IconPlus/>} onClick={openAddParameter}>
                            {t("Добавить параметр")}
                        </Button>
                        <Button leftIcon={<IconPlus/>} onClick={openAddChannel}>
                            {t("Добавить канал")}
                        </Button>
                    </Group>
                </Flex>
                {params.map((parameter) => (
                <Stack>
                    <RemoteControlCard tower={selectedTower} parameter={parameter} />
                </Stack>
                ))}
            </Stack>
            <CreateOPLParameterDrawer
                opened={openedAddParameter}
                close={closeAddParameter}
                tower={selectedTower}
            />
            <CreateOPLChannelDrawer
                opened={openedAddChannel}
                close={closeAddChannel}
                tower={selectedTower}
            />
        </>
    );
};

RemoteControl.displayName = "RemoteControl";
