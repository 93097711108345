import { Drawer, Select } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { СhannelDeviceModelEnum } from "src/features/devices/types";
import { Tower_full } from "../types";
import { IconCheck } from "@tabler/icons-react";
import { Button } from "@mantine/core";
import { useAllParams } from "../api/getAllParameters";
import { useCreateParameter } from "../api/createParameter";

type CreateOPLParameterDrawerProps = {
    opened: boolean;
    close: () => void;
    tower: Tower_full;
};

export const CreateOPLParameterDrawer: React.FC<CreateOPLParameterDrawerProps> = ({ opened, close, tower }) => {
    const { t } = useTranslation();

    const [addingParameter, setAddingParameter] = useState<СhannelDeviceModelEnum | null>();

    const { data: allParams } = useAllParams();

    const createParameterMutation = useCreateParameter({ id: tower.Id });

    return (
        <Drawer
            opened={opened}
            onClose={() => {
                setAddingParameter(null);
                close();
            }}
            title={t("Добавление параметра")}
            padding="xl"
            size="xl"
            styles={{
                drawer: {
                    overflowY: "auto",
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                    "&::WebkitScrollbar": { width: 0 },
                },
            }}
            position="right"
        >
            <Select
                placeholder={t("Выберите параметр")}
                searchable
                nothingFound={t("Ничего не найдено")}
                maxDropdownHeight={280}
                value={addingParameter ? (addingParameter as number).toString() : null}
                data={
                    allParams?.map((x) => ({
                        value: x.Id.toString(),
                        label: t(x.Name),
                    })) || []
                }
                onChange={(e) => {
                    if (e) setAddingParameter(parseInt(e));
                }}
            />
            {addingParameter && (
                <Button
                    className="mt-2"
                    fullWidth
                    variant="outline"
                    leftIcon={<IconCheck />}
                    onClick={() => {
                        if (addingParameter)
                            createParameterMutation.mutateAsync({
                                ParameterId: addingParameter,
                                OverheadPowerLineTowerId: tower.Id,
                            });
                    }}
                >
                    {t("Добавить")}
                </Button>
            )}
        </Drawer>
    );
};
