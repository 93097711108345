import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { FirmwareDTO } from "../types";
import { PagedRequest } from "src/types";

export interface GetTowerFirmwaresQuery extends PagedRequest {
    TowerId: number;
}

export const getFirmware = (request: GetTowerFirmwaresQuery): Promise<FirmwareDTO[]> | undefined => {
    if (request.TowerId)
        return axios.post(`/OverheadPowerLineTower/${request.TowerId}/firmware`, request);
    else
        return undefined
};

type QueryFnType = typeof getFirmware;

type UseTowerFirmwaresOptions = {
    request: GetTowerFirmwaresQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useFirmware = ({request, config}: UseTowerFirmwaresOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: [`FirmwareTower${request.TowerId}`],
        queryFn: () => getFirmware(request),
    });
};
