import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { AllChartDataDto, OverheadPowerLineTowerChartDto } from "../types";
import qs from "qs";

export type getChartDataCommand = {
    DeviceIds: number[];
    OverheadPowerLineIds: number[];
    OverheadPowerLineTowerIds: number[];
    ChannelIds: number[];
    averaging?: number;
    startDt?: Date;
    endDt?: Date;
};

export const getChartData = (command: getChartDataCommand | undefined): Promise<AllChartDataDto> | undefined => {
    if (command)
        return axios.get(`/getChartData`, {
            params: command,
            paramsSerializer: function (params) {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        });
};

type QueryFnType = typeof getChartData;

export const useChartData = (command: getChartDataCommand | undefined) => {
    const { t } = useTranslation();
    let result = useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [`TowerChannel${JSON.stringify(command)}`],
        queryFn: () => getChartData(command),
        refetchInterval: 30000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка при загрузке канала"),
            });
        },
    });

    return result;
};
