import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";
import { OverheadPowerLineTowersPhaseEnum } from "../types";

export type bindOPLcommand = {
    OverheadPowerLineId: number;
    CompanyId: number;
};

export const bindOPL = (command: bindOPLcommand): Promise<EntityId<number>> => {
    return axios.post(`/Companies/${command.CompanyId}/tower/${command.OverheadPowerLineId}/bind`, command);
};

type UseBindOPLoptions = {
    command: bindOPLcommand;
    config?: MutationConfig<typeof bindOPL>;
};

export const useBindOPL = ({ command, config }: UseBindOPLoptions) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries([`CompanyOPLs/${command.CompanyId}`, `OPL${command.OverheadPowerLineId}`]);
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка привязки ВЛ"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries([`CompanyOPLs/${command.CompanyId}`, `OPL${command.OverheadPowerLineId}`]);
            showNotification({
                title: t("Успех!"),
                message: t("ВЛ успешно привязана"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: bindOPL,
    });
};
