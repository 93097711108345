import { ActionIcon, Checkbox, Popover, Table, Tooltip } from "@mantine/core";
import { IconChevronDown, IconSettings } from "@tabler/icons-react";
import { channel } from "diagnostics_channel";
import { useTranslation } from "react-i18next";
import {
    OverheadPowerLineTowerDeviceСhannelsDto,
    OverheadPowerLineTowersPhaseEnum,
    Tower_full,
} from "src/features/OPL/types";
import { HiddableChart } from "./HiddableChart";
import { useEffect, useState } from "react";
import { MainChart } from "./MainChart";
import dayjs from "dayjs";
import { ThresholdWindow } from "./ThresholdWindow";
import { isNull, isUndefined } from "lodash";

type ChannelsTableProps = {
    tower: Tower_full;
    filter: string[];
};

export const ChannelsTable: React.FC<ChannelsTableProps> = ({ tower, filter }) => {
    const { t } = useTranslation();

    const [openedCharts, setOpenedCharts] = useState<number[]>([]);

    const [devicesCols, setDevicesCols] = useState<{ id: number; col: number }[]>([]);

    type selectedChannel = {
        deviceId: number;
        channelId: number;
    };

    useEffect(() => {
        setOpenedCharts([]);
    }, [filter]);

    // const [ selectedChannels, setSelectedChannels ] = useState<selectedChannel[]>([])

    useEffect(() => {
        if (tower.DevicesDtos)
            setDevicesCols(
                tower.DevicesDtos?.map((device, index): { id: number; col: number } =>
                    Object.assign({
                        id: device.Id,
                        col: index,
                    })
                )
            );
    }, [tower]);

    const form_td_value = (channel: any, deviceId: any, tower: Tower_full) => {
        let value = channel.DeviceChannelsDto.filter((x: any)=>x.DeviceId === deviceId.id)[0] ? channel.DeviceChannelsDto.filter((x: any)=>x.DeviceId === deviceId.id)[0].Value : ''
        if (tower.DevicesDtos?.filter(x=>x.Id === deviceId.id)[0]){
            if (channel.DeviceChannelsDto[0].Name === 'ICCID'){
                value = tower.DevicesDtos?.filter(x=>x.Id === deviceId.id)[0].ICCIDModbus64
            }
            if (channel.DeviceChannelsDto[0].Name === 'MAC adress'){
                value = tower.DevicesDtos?.filter(x=>x.Id === deviceId.id)[0].MACModbusHEX
            }
            if (channel.DeviceChannelsDto[0].Name === 'Версия ПО'){
                value = tower.DevicesDtos?.filter(x=>x.Id === deviceId.id)[0].VersionModbus
            }
            if ((value!=='') && (!isUndefined(value)) && (!isNull(value))){
                if (typeof value !== "string")
                    value = value.toFixed(2)}
            else 
                value = '-'
        }
        let unit = channel.DeviceChannelsDto.filter((x: any)=>x.DeviceId === deviceId.id)[0] ? channel.DeviceChannelsDto.filter((x: any)=>x.DeviceId === deviceId.id)[0].Unit : ''
        if (!unit)
            unit = ''
        return value + unit
    }

    const get_value_timestamp = (
        channel: OverheadPowerLineTowerDeviceСhannelsDto,
        deviceId: { id: number; col: number }
    ) => {
        let tmp = channel.DeviceChannelsDto.filter((x) => x.DeviceId === deviceId.id)[0];
        if (tmp) return tmp.Timestamp;
        else return t("Информации о времени нет");
    };

    const [openedThresholdWindowChannelId, setOpenedThresholdWindowChannelId] = useState<{
        Id: number;
        Unit: string;
    } | null>();

    return (
        <>
            <Table withColumnBorders style={{ height: "fit-content" }}>
                <thead>
                    <tr>
                        {/* <th>

                    </th> */}
                        <th
                            style={{
                                textAlign: "center",
                                boxSizing: "border-box",
                                width: "10%",
                            }}
                        >
                            {t("Название")}
                        </th>
                        {tower.DevicesDtos?.map((device) => (
                            <th
                                style={{
                                    textAlign: "center",
                                    boxSizing: "border-box",
                                    width: `${tower.DevicesDtos ? 100 / tower.DevicesDtos?.length : 0}%`,
                                }}
                            >
                                <Tooltip
                                    label={
                                        <>
                                            <span>{`${t("Название")}: ${device.Name}`}</span>
                                            <br />
                                            <span>{`${t("Комментарий")}: ${device.Comment}`}</span>
                                            <br />
                                            <span>{`${t("CCID")}: ${device.CCID}`}</span>
                                        </>
                                    }
                                >
                                    <div>
                                        {t("Фаза") +
                                            " " +
                                            (device.OverheadPowerLineTowersPhase
                                                ? OverheadPowerLineTowersPhaseEnum[device.OverheadPowerLineTowersPhase]
                                                : "?")}
                                    </div>
                                </Tooltip>
                            </th>
                        ))}
                        {/* <th
                        style={{
                            textAlign: "center", 
                            boxSizing: "border-box",
                            width: "10%"
                        }}
                    >
                        {t("Уставки")}
                    </th> */}
                        {tower.OverheadPowerLineTowerDeviceСhannelsDtos &&
                        tower.OverheadPowerLineTowerDeviceСhannelsDtos?.filter(
                            (channel) =>
                                channel.DeviceChannelsDto[0] && filter.includes(channel.DeviceChannelsDto[0].Name)
                        ).length > 1 ? (
                            <th></th>
                        ) : (
                            ""
                        )}
                    </tr>
                </thead>
                {tower.OverheadPowerLineTowerDeviceСhannelsDtos?.length !== 0 ? (
                    <tbody>
                        {tower.OverheadPowerLineTowerDeviceСhannelsDtos?.filter(
                            (channel) =>
                                channel.DeviceChannelsDto[0] && filter.includes(channel.DeviceChannelsDto[0].Name)
                        ).map((channel, row_index) => (
                            <>
                                <tr>
                                    {/* <td>
                            <Checkbox
                                // onClick={()=>{
                                //     if (channel.DeviceChannelsDto.map(x=>x.Id))
                                // }}
                            />
                        </td> */}
                                    <td style={{ textAlign: "center" }}>{t(channel.DeviceChannelsDto[0].Name)}</td>
                                    {devicesCols.map((deviceId, col) => (
                                        <td style={{ textAlign: "center" }}>
                                            <Tooltip
                                                label={
                                                    get_value_timestamp(channel, deviceId)
                                                        ? dayjs(get_value_timestamp(channel, deviceId)).format(
                                                              "DD.MM.YYYY HH:mm:ss"
                                                          )
                                                        : "-"
                                                }
                                                style={
                                                    form_td_value(channel, deviceId, tower) !== "-"
                                                        ? {}
                                                        : { display: "none" }
                                                }
                                            >
                                                <div>{form_td_value(channel, deviceId, tower)}</div>
                                            </Tooltip>
                                        </td>
                                    ))}
                                    {/* <td>
                            <ActionIcon
                                size="lg" 
                                variant="default"
                                style={{margin: "auto"}}
                                onClick={()=>setOpenedThresholdWindowChannelId({Id: channel.DeviceModelChannelId, Unit: channel.DeviceChannelsDto[0].Unit || ''})}
                            >
                                <IconSettings/>
                            </ActionIcon>
                        </td> */}
                                    {tower.OverheadPowerLineTowerDeviceСhannelsDtos &&
                                    tower.OverheadPowerLineTowerDeviceСhannelsDtos?.filter(
                                        (channel) =>
                                            channel.DeviceChannelsDto[0] &&
                                            filter.includes(channel.DeviceChannelsDto[0].Name)
                                    ).length > 1 ? (
                                        <td>
                                            <ActionIcon
                                                onClick={() => {
                                                    let tmp = Array.from(openedCharts);
                                                    if (tmp.includes(row_index))
                                                        tmp = tmp.filter((index) => index !== row_index);
                                                    else tmp.push(row_index);
                                                    setOpenedCharts(tmp);
                                                }}
                                            >
                                                <IconChevronDown />
                                            </ActionIcon>
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr style={{ border: "none" }}>
                                    <td
                                        colSpan={(tower.DevicesDtos?.length ? tower.DevicesDtos?.length : 0) + 4}
                                        style={{ border: "none", padding: 0 }}
                                    >
                                        <HiddableChart
                                            channel={channel}
                                            opened={openedCharts.includes(row_index)}
                                            devices={tower.DevicesDtos ? tower.DevicesDtos : undefined}
                                        />
                                    </td>
                                </tr>
                            </>
                        )) || ""}
                    </tbody>
                ) : (
                    ""
                )}
            </Table>
            {openedThresholdWindowChannelId && (
                <ThresholdWindow
                    opened={!!openedThresholdWindowChannelId}
                    close={() => setOpenedThresholdWindowChannelId(null)}
                    channel={openedThresholdWindowChannelId}
                    Tower={tower}
                />
            )}
        </>
    );
};
