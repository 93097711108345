import { Center } from "@mantine/core"

type NotificationsCounterMarkerProps = {
    notificationsNumber?:   number | null,
    color?:                 string,
}

export const NotificationsCounterMarker: React.FC<NotificationsCounterMarkerProps> = ({notificationsNumber, color}) => {
    return(
        notificationsNumber ? 
            <Center style={{
                backgroundColor: color || "red",
                color: color !== "yellow" ? "white" : "black",
                width: "23px",
                height: "23px",
                borderRadius: "50%",
                fontSize: "10px",
                position: "absolute",
                padding: 0,
                top: "0",
                right: "0",
                fontWeight: 700,
            }}>
                {notificationsNumber > 99 ? "99+" : notificationsNumber}
            </Center>
        :
            <>
            </>
    )
}