import { Center, Stack, Title } from "@mantine/core"
import { IconDatabaseOff } from "@tabler/icons-react"

type DBoffMockProps = {
    label?: string
}

export const DBoffMock: React.FC<DBoffMockProps> = ({label}) => {
    return(
        <Center style={{height: "100%"}}>
            <Stack align="center">
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "100%",
                        backgroundColor: "#E9ECEF",
                        height: "24px",
                        width: "24px",
                        padding: "10px",
                    }}
                >   
                    <IconDatabaseOff color="#868E96" />
                    {/* <Title>{label}</Title> */}
                </div>
                {label ?
                    <div className="font-medium text-xl mb-4" style={{color: '#868E96'}}>{label}</div>
                : ''}
            </Stack>
        </Center>
    )
}