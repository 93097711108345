import { Button, Drawer, Paper, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelectedDevice } from "../hooks/useSelectedDevice";
import { CreateDeviceParameterForm } from "./CreateDeviceParameterForm";
import { RemoteControlCard } from "./RemoteControlCard";
import { DeviceDto } from "../types";
import { useGetOscilloscope } from "../api/getOscilloscope";
import { useGetDeviceParameters } from "../api/getDeviceParameters";
import { useUpdateDeviceParameters } from "../api/updateDeviceParameters";

type RemoteControlProps = {
    device: DeviceDto;
};

export const RemoteControl: React.FC<RemoteControlProps> = ({ device }) => {
    const { t } = useTranslation();
    const [opened, { open, close }] = useDisclosure(false);
    const selectedDevice = useSelectedDevice();

    const updateParametersMutation = useUpdateDeviceParameters();

    function handleUpdateParameters() {
        updateParametersMutation.mutateAsync({
            DeviceId: device.Id,
        });
    }

    const getDeviceParametersMutation = useGetDeviceParameters();

    function handleGetDeviceParameters() {
        getDeviceParametersMutation.mutateAsync({
            DeviceId: device.Id,
        });
    }

    if (!selectedDevice) {
        return null;
    }

    return (
        <>
            <Paper className="mb-4" p="md" shadow="sm">
                <div className="flex justify-end space-x-4 mb-4">
                    <Button onClick={handleGetDeviceParameters} disabled={device.GetParameters}>
                        {t("Считать параметры с устройства")}
                    </Button>
                    <Button onClick={handleUpdateParameters}>{t("Записать параметры с устройства в БД")}</Button>
                    <Button
                        leftIcon={<IconPlus />}
                        onClick={() => {
                            open();
                        }}
                        variant="outline"
                    >
                        {t("Добавить параметр")}
                    </Button>
                </div>
                <Stack>
                    {selectedDevice?.DeviceParameters.map((parameter) => (
                        <RemoteControlCard deviceId={selectedDevice.Id} parameter={parameter} />
                    ))}
                </Stack>
            </Paper>
            <Drawer
                opened={opened}
                onClose={close}
                title={t("Добавить параметр")}
                padding="xl"
                size="xl"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                <CreateDeviceParameterForm deviceId={selectedDevice.Id} onClose={close} />
            </Drawer>
        </>
    );
};

RemoteControl.displayName = "RemoteControl";
