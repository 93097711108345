import { Center } from "@mantine/core"
import { IconChecks } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

export const NoNotificationsMock: React.FC = () => {

    const { t } = useTranslation()

    return(
        <Center 
            style={{
                width: "520px",
                height: "100%",
                borderRadius: "8px",
                backgroundColor: "rgb(248, 250, 252)",
                padding: "30px",
                boxSizing: "border-box",
            }}
        >
            <IconChecks/>
            <div className="font-medium text-xl">{t("Нет новых уведомлений")}</div>
        </Center>
    )
}