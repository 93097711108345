import { ActionIcon, Box, Card, Collapse, Flex, Tabs, Title, Text, Badge, Grid, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowDown, IconArrowUp, IconBell, IconEdit, IconTrash } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDeleteChannelThreshold } from "../api/deleteChannelThreshold";
import { ChannelThresholdDto, ThresholdDirectionEnum } from "../types";
import { ChannelThresholdForm } from "./ChannelThresholdForm";
import { ChannelThresholdNotifications } from "./ChannelThresholdNotifications";

type ChannelThresholdProps = {
    index: number;
    channelId: number;
    channelUnit?: string;
    threshold: ChannelThresholdDto;
    onEdit?: () => void;
    onStopEdit?: () => void;
};

export enum SettingsTab {
    Threshold,
    Notifications,
}

export const ChannelThreshold: React.FC<ChannelThresholdProps> = ({
    threshold,
    channelId,
    channelUnit,
    index,
    onEdit,
    onStopEdit,
}) => {
    const { t } = useTranslation();
    const [opened, { toggle, close }] = useDisclosure(false);
    const { mutateAsync: deleteAsync } = useDeleteChannelThreshold();

    const handleStopEdit = () => {
        close();
        onStopEdit?.();
    };

    const notificationChannelsLength = threshold.ChannelThresholdNotifications.filter((x) => x.Enabled).length;

    return (
        <Box>
            <Card withBorder py="0">
                <Flex direction="column">
                    <Card.Section>
                        <Flex justify="space-between" align="center" p="md">
                            <Grid sx={{ flex: 1 }} align="center" gutter="xl">
                                <Grid.Col span={8}>
                                    <Text>{threshold.Description || `${t("Уставка")} ${index + 1}`}</Text>
                                </Grid.Col>
                                <Grid.Col span={2}>
                                    <Flex gap="xs" align="center" justify="flex-end">
                                        <Flex
                                            align="center"
                                            sx={(theme) => ({
                                                flexGrow: 0,
                                            })}
                                            justify="flex-end"
                                        >
                                            <Title order={6}>
                                                {threshold.Value} {channelUnit}
                                            </Title>
                                        </Flex>
                                        <Flex
                                            align="center"
                                            sx={(theme) => ({
                                                flexBasis: "20%",
                                                flexShrink: 0,
                                            })}
                                            justify="flex-start"
                                        >
                                            {threshold.Direction === ThresholdDirectionEnum.Upper && (
                                                <Box sx={{ color: threshold.Color }}>
                                                    <IconArrowUp />
                                                </Box>
                                            )}
                                            {threshold.Direction === ThresholdDirectionEnum.Lower && (
                                                <Box sx={{ color: threshold.Color }}>
                                                    <IconArrowDown />
                                                </Box>
                                            )}
                                        </Flex>
                                    </Flex>
                                </Grid.Col>
                                <Grid.Col span={2}>
                                    <Tooltip openDelay={750} withinPortal label={t("Кол-во каналов уведомления")}>
                                        <Badge
                                            pl={4}
                                            size="lg"
                                            variant="light"
                                            radius="sm"
                                            color={notificationChannelsLength > 0 ? "blue" : "gray.9"}
                                            leftSection={
                                                <Flex align="center">
                                                    <IconBell size="16" />
                                                </Flex>
                                            }
                                        >
                                            {notificationChannelsLength}
                                        </Badge>
                                    </Tooltip>
                                </Grid.Col>
                            </Grid>
                            <Flex gap="md">
                                <Tooltip openDelay={750} withinPortal label={t("Редактировать уставку")}>
                                    <ActionIcon
                                        onClick={() => {
                                            onEdit?.();
                                            toggle();
                                        }}
                                        variant="subtle"
                                        color="gray.9"
                                    >
                                        <IconEdit />
                                    </ActionIcon>
                                </Tooltip>
                                <Tooltip openDelay={750} withinPortal label={t("Удалить уставку")}>
                                    <ActionIcon
                                        onClick={() => deleteAsync({ ChannelId: channelId, Id: threshold.Id })}
                                        variant="subtle"
                                        color="red"
                                    >
                                        <IconTrash />
                                    </ActionIcon>
                                </Tooltip>
                            </Flex>
                        </Flex>
                    </Card.Section>
                    <Card.Section>
                        <Collapse in={opened}>
                            <Flex direction="column" gap="lg" mt="lg">
                                <Tabs variant="default" defaultValue={String(SettingsTab.Threshold)}>
                                    <Tabs.List pl="md">
                                        {[
                                            {
                                                label: t("Настройки уставки"),
                                                value: String(SettingsTab.Threshold),
                                            },
                                            {
                                                label: t("Настройки уведомлений"),
                                                value: String(SettingsTab.Notifications),
                                            },
                                        ].map((x) => (
                                            <Tabs.Tab fw="500" value={x.value}>
                                                {x.label}
                                            </Tabs.Tab>
                                        ))}
                                    </Tabs.List>
                                    <Tabs.Panel value={String(SettingsTab.Threshold)} pt="lg" p="md">
                                        <ChannelThresholdForm
                                            isEdit
                                            initialValue={threshold}
                                            channelId={channelId}
                                            onCancel={handleStopEdit}
                                            onSave={handleStopEdit}
                                        />
                                    </Tabs.Panel>
                                    <Tabs.Panel value={String(SettingsTab.Notifications)} pt="lg" p="md">
                                        <ChannelThresholdNotifications
                                            channelId={channelId}
                                            thresholdId={threshold.Id}
                                            thresholdNotifications={threshold.ChannelThresholdNotifications}
                                        />
                                    </Tabs.Panel>
                                </Tabs>
                            </Flex>
                        </Collapse>
                    </Card.Section>
                </Flex>
            </Card>
        </Box>
    );
};

ChannelThreshold.displayName = "ChannelThreshold";
