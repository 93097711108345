import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";
import { OverheadPowerLineTowersPhaseEnum } from "../types";

export type bindDeviceCommand = {
    OverheadPowerLineTowerId: number;
    OverheadPowerLineTowersPhase: OverheadPowerLineTowersPhaseEnum;
    Id?: number;
};

export const bindDevice = (command: bindDeviceCommand): Promise<EntityId<number>> => {
    return axios.post(
        `/OverheadPowerLineTower/${command.OverheadPowerLineTowerId}/device/${command.Id}/bind/${command.OverheadPowerLineTowersPhase}`,
        command
    );
};

type UseBindDeviceOptions = {
    command: bindDeviceCommand;
    config?: MutationConfig<typeof bindDevice>;
};

export const useBindDevice = ({ command, config }: UseBindDeviceOptions) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries([`Tower${command.OverheadPowerLineTowerId}`, `device${command.Id}`]);
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка привязки устройства"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries([`Tower${command.OverheadPowerLineTowerId}`, `device${command.Id}`]);
            showNotification({
                title: t("Успех!"),
                message: t("Устройство успешно привязано"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: bindDevice,
    });
};
