import {
    ActionIcon,
    Badge,
    Box,
    Button,
    Card,
    Center,
    Collapse,
    ColorInput,
    Flex,
    Grid,
    Input,
    Modal,
    NumberInput,
    SegmentedControl,
    Select,
    Switch,
    Tabs,
    Textarea,
    TextInput,
    Title,
    Tooltip,
    Text,
    Group,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure, useHover } from "@mantine/hooks";
import {
    IconAlertCircle,
    IconAlertOctagon,
    IconArrowDown,
    IconArrowUp,
    IconBell,
    IconBellOff,
    IconEdit,
    IconInfoCircle,
    IconPlus,
    IconTrash,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDevice } from "src/features/devices/api/getDevice";
import { useSelectedDevice } from "src/features/devices/hooks/useSelectedDevice";
import {
    CreateChannelThresholdCommand,
    ThresholdTypeEnum,
    useCreateChannelThreshold,
} from "src/features/notifications/api/createChannelThreshold";
import { useDeleteChannelThreshold } from "src/features/notifications/api/deleteChannelThreshold";
import { useToggleChannelThresholdNotification } from "src/features/notifications/api/toggleChannelThresholdNotification";
import {
    UpdateChannelThresholdCommand,
    useUpdateChannelThreshold,
} from "src/features/notifications/api/updateChannelThreshold";
import {
    ChannelThresholdDto,
    ChannelThresholdNotificationDto,
    ThresholdDirectionEnum,
} from "src/features/notifications/types";
import {
    CreateOverheadPowerLineTowerThresholdCommand,
    useCreateTowerThreshold,
} from "src/features/OPL/api/createTowerThreshold";
import { useTowerThresholds } from "src/features/OPL/api/getTowerThresholds";
import { Tower_full } from "src/features/OPL/types";
import { useTelegramBindings } from "src/features/profile/api/getTelegramBindings";
import { TelegramBindingDto } from "src/features/profile/types";
import { useAuth } from "src/lib/auth";

type ChannelThresholdNotificationCardProps = {
    telegramBinding: TelegramBindingDto;
    checked: boolean;
    onToggle: (value: boolean) => void;
};

export const ChannelThresholdNotificationCard: React.FC<ChannelThresholdNotificationCardProps> = ({
    telegramBinding,
    onToggle,
    checked,
}) => {
    const { hovered, ref } = useHover();
    return (
        <Card shadow={hovered ? "sm" : "0 1px 2px 0 rgb(0 0 0 / 0.05)"} ref={ref} withBorder>
            <Flex justify="space-between" align="center">
                <Text weight="bold">{telegramBinding.Name}</Text>
                <Switch
                    size="md"
                    display="flex"
                    onLabel={<IconBellOff size="1rem" stroke={2.5} />}
                    offLabel={<IconBell size="1rem" stroke={2.5} />}
                    checked={checked}
                    onChange={(e) => onToggle(e.currentTarget.checked)}
                ></Switch>
            </Flex>
        </Card>
    );
};

type ChannelThresholdNotificationsProps = {
    channelId: number;
    thresholdId: number;
    thresholdNotifications: ChannelThresholdNotificationDto[];
};

export const ChannelThresholdNotifications: React.FC<ChannelThresholdNotificationsProps> = ({
    channelId,
    thresholdId,
    thresholdNotifications,
}) => {
    const { data: telegramBindings } = useTelegramBindings({ onlyConnected: true });
    const { mutateAsync: toggleAsync } = useToggleChannelThresholdNotification({ channelId });
    return (
        <Flex direction="column" gap="xs">
            {telegramBindings?.map((binding) => (
                <ChannelThresholdNotificationCard
                    checked={thresholdNotifications.some((x) => x.TelegramBinding?.Id === binding.Id && x.Enabled)}
                    onToggle={() => toggleAsync({ ChannelThresholdId: thresholdId, TelegramBindingId: binding.Id })}
                    telegramBinding={binding}
                />
            ))}
        </Flex>
    );
};

type ChannelThresholdProps = {
    index: number;
    channelId: number;
    channelUnit?: string;
    threshold: ChannelThresholdDto;
    onEdit?: () => void;
    onStopEdit?: () => void;
    Tower: Tower_full;
};

export enum SettingsTab {
    Threshold,
    Notifications,
}

export const ChannelThreshold: React.FC<ChannelThresholdProps> = ({
    threshold,
    channelId,
    channelUnit,
    index,
    onEdit,
    onStopEdit,
    Tower,
}) => {
    const { t } = useTranslation();
    const [opened, { toggle, close }] = useDisclosure(false);
    const { mutateAsync: deleteAsync } = useDeleteChannelThreshold();

    const handleStopEdit = () => {
        close();
        onStopEdit?.();
    };

    const notificationChannelsLength = threshold.ChannelThresholdNotifications.filter((x) => x.Enabled).length;

    return (
        <Box>
            <Card withBorder py="0">
                <Flex direction="column">
                    <Card.Section>
                        <Flex justify="space-between" align="center" p="md">
                            <Grid sx={{ flex: 1 }} align="center" gutter="xl">
                                <Grid.Col span={8}>
                                    <Group>
                                        <Text>{threshold.Description || `${t("Уставка")} ${index + 1}`}</Text>
                                    </Group>
                                </Grid.Col>
                                <Grid.Col span={2}>
                                    <Flex gap="xs" align="center" justify="flex-end">
                                        <Flex
                                            align="center"
                                            sx={(theme) => ({
                                                flexGrow: 0,
                                            })}
                                            justify="flex-end"
                                        >
                                            <Title order={6}>
                                                {threshold.Value} {channelUnit}
                                            </Title>
                                        </Flex>
                                        <Flex
                                            align="center"
                                            sx={(theme) => ({
                                                flexBasis: "20%",
                                                flexShrink: 0,
                                            })}
                                            justify="flex-start"
                                        >
                                            {threshold.Direction === ThresholdDirectionEnum.Upper && (
                                                <Box sx={{ color: threshold.Color }}>
                                                    <IconArrowUp />
                                                </Box>
                                            )}
                                            {threshold.Direction === ThresholdDirectionEnum.Lower && (
                                                <Box sx={{ color: threshold.Color }}>
                                                    <IconArrowDown />
                                                </Box>
                                            )}
                                        </Flex>
                                    </Flex>
                                </Grid.Col>
                                <Grid.Col span={2}>
                                    <Tooltip openDelay={750} withinPortal label={t("Кол-во каналов уведомления")}>
                                        <Badge
                                            pl={4}
                                            size="lg"
                                            variant="light"
                                            radius="sm"
                                            color={notificationChannelsLength > 0 ? "blue" : "gray.9"}
                                            leftSection={
                                                <Flex align="center">
                                                    <IconBell size="16" />
                                                </Flex>
                                            }
                                        >
                                            {notificationChannelsLength}
                                        </Badge>
                                    </Tooltip>
                                </Grid.Col>
                            </Grid>
                            <Flex gap="md">
                                {/* <Tooltip openDelay={750} withinPortal label={t("Редактировать уставку")}>
                                    <ActionIcon
                                        onClick={() => {
                                            onEdit?.();
                                            toggle();
                                        }}
                                        variant="subtle"
                                        color="gray.9"
                                    >
                                        <IconEdit />
                                    </ActionIcon>
                                </Tooltip> */}
                                {/* <Tooltip openDelay={750} withinPortal label={t("Удалить уставку")}>
                                    <ActionIcon
                                        onClick={() => deleteAsync({ ChannelId: channelId, Id: threshold.Id })}
                                        variant="subtle"
                                        color="red"
                                    >
                                        <IconTrash />
                                    </ActionIcon>
                                </Tooltip> */}
                            </Flex>
                        </Flex>
                    </Card.Section>
                    <Card.Section>
                        <Collapse in={opened}>
                            <Flex direction="column" gap="lg" mt="lg">
                                <Tabs variant="default" defaultValue={String(SettingsTab.Threshold)}>
                                    <Tabs.List pl="md">
                                        {[
                                            {
                                                label: t("Настройки уставки"),
                                                value: String(SettingsTab.Threshold),
                                            },
                                            {
                                                label: t("Настройки уведомлений"),
                                                value: String(SettingsTab.Notifications),
                                            },
                                        ].map((x) => (
                                            <Tabs.Tab fw="500" value={x.value}>
                                                {x.label}
                                            </Tabs.Tab>
                                        ))}
                                    </Tabs.List>
                                    <Tabs.Panel value={String(SettingsTab.Threshold)} pt="lg" p="md">
                                        <ChannelThresholdForm
                                            isEdit
                                            initialValue={threshold}
                                            channelId={channelId}
                                            onCancel={handleStopEdit}
                                            onSave={handleStopEdit}
                                            TowerId={Tower.Id}
                                        />
                                    </Tabs.Panel>
                                    <Tabs.Panel value={String(SettingsTab.Notifications)} pt="lg" p="md">
                                        <ChannelThresholdNotifications
                                            channelId={channelId}
                                            thresholdId={threshold.Id}
                                            thresholdNotifications={threshold.ChannelThresholdNotifications}
                                        />
                                    </Tabs.Panel>
                                </Tabs>
                            </Flex>
                        </Collapse>
                    </Card.Section>
                </Flex>
            </Card>
        </Box>
    );
};

type ChannelThresholdFormProps = {
    isEdit?: boolean;
    channelId: number;
    initialValue?: Partial<ChannelThresholdDto>;
    onCancel?: () => void;
    onSave?: () => void;
    TowerId: number;
};

export const ChannelThresholdForm: React.FC<ChannelThresholdFormProps> = ({
    isEdit,
    initialValue: threshold,
    channelId,
    onCancel,
    onSave,
    TowerId,
}) => {
    const { t } = useTranslation();
    const { mutateAsync: createAsync } = useCreateTowerThreshold({}, TowerId);
    // const { mutateAsync: updateAsync } = useUpdateChannelThreshold();
    let updateAsync = () => {};

    const selectedDevice = useSelectedDevice();

    const form = useForm<CreateOverheadPowerLineTowerThresholdCommand>({
        initialValues: {
            Color: threshold?.Color || "#F03E3E",
            ChannelId: channelId,
            Description: threshold?.Description || "",
            Direction: threshold?.Direction || ThresholdDirectionEnum.Upper,
            ShowOnChart: true,
            Value: threshold?.Value || 0,
            MessageTemplate: threshold?.MessageTemplate ?? "",
            ImageEnabled: threshold?.ImageEnabled || false,
            ImageChannelId: threshold?.ImageChannelId || 0,
            CompanyId: null,
            ThresholdType: ThresholdTypeEnum.WarningThreshold,
            TowerId: TowerId,
        },
    });

    const { user } = useAuth();

    useEffect(() => {
        form.setFieldValue("CompanyId", user?.CompanyId || null);
    }, [user?.CompanyId]);

    return (
        <form
            onSubmit={form.onSubmit((values) => {
                onSave?.();
                isEdit
                    ? // ? updateAsync(values as UpdateChannelThresholdCommand)
                      updateAsync()
                    : createAsync(values as CreateOverheadPowerLineTowerThresholdCommand);
            })}
        >
            <Flex gap="lg" direction="column">
                <TextInput label={t("Описание")} {...form.getInputProps("Description")} />

                <Input.Wrapper label={t("Значение")}>
                    <Flex align="center" gap="lg">
                        <SegmentedControl
                            value={String(form.values.Direction)}
                            onChange={(value) => form.setFieldValue("Direction", Number(value))}
                            data={[
                                {
                                    label: t("Больше или равно"),
                                    value: String(ThresholdDirectionEnum.Upper),
                                },
                                {
                                    label: t("Меньше или равно"),
                                    value: String(ThresholdDirectionEnum.Lower),
                                },
                                {
                                    label: t("Равно"),
                                    value: String(ThresholdDirectionEnum.Equals),
                                },
                            ]}
                        />
                        <NumberInput maw={150} {...form.getInputProps("Value")} precision={4} />
                    </Flex>
                </Input.Wrapper>

                <ColorInput
                    maw={150}
                    label={t("Цвет")}
                    swatchesPerRow={5}
                    format="hex"
                    withEyeDropper={false}
                    swatches={["#F03E3E", "#F59F00", "#37B24D"]}
                    {...form.getInputProps("Color")}
                />
                {!isEdit && "ThresholdType" in form.values ? (
                    <SegmentedControl
                        value={String(form.values.ThresholdType as ThresholdTypeEnum)}
                        onChange={(value) => form.setFieldValue("ThresholdType", Number(value))}
                        data={[
                            {
                                label: (
                                    <Center>
                                        <IconInfoCircle color="green" />
                                        <span className="ml-1">{t("Информационная")}</span>
                                    </Center>
                                ),
                                value: String(ThresholdTypeEnum.InformationThreshold),
                            },
                            {
                                label: (
                                    <Center>
                                        <IconAlertCircle color="rgb(200, 200, 0)" />
                                        <span className="ml-1">{t("Предупредительная")}</span>
                                    </Center>
                                ),
                                value: String(ThresholdTypeEnum.WarningThreshold),
                            },
                            {
                                label: (
                                    <Center>
                                        <IconAlertOctagon color="red" />
                                        <span className="ml-1">{t("Критическая")}</span>
                                    </Center>
                                ),
                                value: String(ThresholdTypeEnum.CriticalThreshold),
                            },
                        ]}
                    />
                ) : (
                    ""
                )}
                <Textarea label={t("Шаблон уведомления")} {...form.getInputProps("MessageTemplate")} />
                <Switch label={t("Отправлять график")} {...form.getInputProps("ImageEnabled", { type: "checkbox" })} />
                {/*вот это по человечески сделать, тут должны быть каналы устройства для выбора, отправить надо DeviceChannelId*/}
                {form.values.ImageEnabled ? (
                    <Select
                        value={form.values.ImageChannelId ? form.values.ImageChannelId.toString() : ""} //{channels.values.toString()}
                        onChange={(value) => {
                            form.setFieldValue("ImageChannelId", Number(value));
                        }}
                        label={t("Выбор канала для графика")}
                        data={
                            selectedDevice?.DeviceChannels?.map((x) => ({
                                value: x.Id.toString(),
                                label: t(x.Name),
                            })) || []
                        }
                    />
                ) : (
                    ""
                )}
                <Flex justify="flex-end" gap="md">
                    <Button variant="default" onClick={onCancel}>
                        {t("Отмена")}
                    </Button>
                    <Button type="submit">{t("Сохранить")}</Button>
                </Flex>
            </Flex>
        </form>
    );
};

type NotificationsProps = {
    channelId: number;
    channelUnit?: string;
    Tower: Tower_full;
};

export const Notifications: React.FC<NotificationsProps> = ({ channelId, channelUnit, Tower }) => {
    const { t } = useTranslation();
    const { data } = useTowerThresholds(Tower.Id, channelId);

    const [createNewThreshold, setCreateNewThreshold] = useState<boolean>(false);

    return (
        <Flex direction="column" gap="lg">
            <Title weight="400" order={4}>
                {t("Уставки")}
            </Title>
            <Flex direction="column" gap="xs">
                {data?.map((x, index) => (
                    <ChannelThreshold
                        channelUnit={channelUnit}
                        index={index}
                        threshold={x}
                        channelId={channelId}
                        // onEdit={() => setCreateNewThreshold(false)}
                        onEdit={() => {}}
                        Tower={Tower}
                    />
                ))}
            </Flex>

            {createNewThreshold && (
                <Card withBorder>
                    <Flex gap="lg" direction="column">
                        <Title order={6}>{t("Новая уставка")}</Title>
                        <ChannelThresholdForm
                            initialValue={{}}
                            channelId={channelId}
                            onCancel={() => setCreateNewThreshold(false)}
                            onSave={() => setCreateNewThreshold(false)}
                            TowerId={Tower.Id}
                        />
                    </Flex>
                </Card>
            )}
            {!createNewThreshold && (
                <Button variant="default" rightIcon={<IconPlus />} onClick={() => setCreateNewThreshold(true)}>
                    {t("Добавить уставку")}
                </Button>
            )}
        </Flex>
    );
};

type ThresholdWindowProps = {
    opened: boolean;
    close: () => void;
    channel: { Id: number; Unit: string };
    Tower: Tower_full;
};

export const ThresholdWindow: React.FC<ThresholdWindowProps> = ({ opened, close, channel, Tower }) => {
    const { t } = useTranslation();

    return (
        <Modal title={<Title order={3}>{t("Настройки канала")}</Title>} opened={opened} onClose={close} size="xl">
            <Notifications channelUnit={channel.Unit} channelId={channel.Id} Tower={Tower} />
        </Modal>
    );
};
