import { Button, Chip, Group, SegmentedControl, Select, TextInput, Tooltip } from "@mantine/core";
import { OverheadPowerLineTowersPhaseEnum } from "src/features/OPL/types";
import { useForm } from "@mantine/form";
import { useOPLs } from "src/features/OPL/api/getOPLs";
import { useTowers } from "src/features/OPL/api/getTowers";
import { SimpleSearchList } from "src/features/companies/components/OP";
import { showNotification } from "@mantine/notifications";
import { useTower } from "src/features/OPL/api/getTower";
import { DatePicker } from "@mantine/dates";
import { useDevices } from "src/features/devices/api/getDevices";
import dayjs from "dayjs";
import { GraphicSettings } from "./GraphicList";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { IconX } from "@tabler/icons-react";

export type targetDeviceChannel = {
    OPL?: number | null;
    Tower?: number | null;
    Phase?: OverheadPowerLineTowersPhaseEnum | null;
    ChannelId: number;
    device: number;
    name: string;
};

type GraphicFormProps = {
    index: number;
    close: () => void;
    save: (settings: GraphicSettings) => void;
    editing?: {
        settings: GraphicSettings & { index: number };
        stop: (settings?: GraphicSettings & { index: number }) => void;
    };
};

export const PhaseToString = (phase: OverheadPowerLineTowersPhaseEnum) => {
    if (phase === OverheadPowerLineTowersPhaseEnum.A) return "A";
    if (phase === OverheadPowerLineTowersPhaseEnum.B) return "B";
    if (phase === OverheadPowerLineTowersPhaseEnum.C) return "C";
};

export const GraphicForm: React.FC<GraphicFormProps> = ({ index, close, save, editing }) => {
    const currentDate = new Date();
    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const { t } = useTranslation();

    const form = useForm<GraphicSettings>({
        initialValues: editing
            ? {
                  Name: editing.settings.Name,
                  targetDevices: editing.settings.targetDevices,
                  startDt: editing.settings.startDt
                      ? new Date(editing.settings.startDt as unknown as string)
                      : new Date(currentDate.getTime() - millisecondsInDay),
                  endDt: editing.settings.endDt ? new Date(editing.settings.endDt as unknown as string) : currentDate,
                  averaging: editing.settings.averaging || 0,
                  OPLs: editing.settings.OPLs,
                  Towers: editing.settings.Towers,
                  Devices: editing.settings.Devices,
                  Channels: editing.settings.Channels,
              }
            : {
                  Name: `${t("График")} ${index + 1}`,
                  targetDevices: [],
                  startDt: new Date(currentDate.getTime() - millisecondsInDay),
                  endDt: currentDate,
                  averaging: 0,
                  OPLs: [],
                  Towers: [],
                  Devices: [],
                  Channels: [],
              },
    });

    const { data: OPLs } = useOPLs();
    const { data: Towers } = useTowers();

    const [choosingOPL, setChoosingOPL] = useState<number | null>();
    const [choosingTower, setChoosingTower] = useState<number | null>();
    const [choosingPhase, setChoosingPhase] = useState<OverheadPowerLineTowersPhaseEnum | null>();
    const [choosingChannel, setChoosingChannel] = useState<number | null>();
    const [choosingDevice, setChoosingDevice] = useState<number | null>();

    const [towerDeviceType, setTowerDeviceTab] = useState("Tower");

    const { data: devices } = useDevices();

    const getTowerId = () => {
        if (towerDeviceType === "Tower") {
            return choosingTower ? choosingTower : undefined;
        } else {
            let deviceFull = devices?.filter((device) => device.Id === choosingDevice)[0];
            if (deviceFull)
                return deviceFull.OverheadPowerLineTowerId ? deviceFull.OverheadPowerLineTowerId : undefined;
        }
    };

    const { data: TowerFull } = useTower(getTowerId());

    const addDevice = (
        OPL: number | null | undefined,
        Tower: number | null | undefined,
        Phase: OverheadPowerLineTowersPhaseEnum | null | undefined,
        Channel: number | null | undefined,
        device: number | null | undefined,
        name: string
    ) => {
        if (towerDeviceType === "Tower" && OPL && Tower && Phase && Channel) {
            let tmp = form.values.targetDevices ? form.values.targetDevices : [];
            if (
                tmp.filter(
                    (x) =>
                        JSON.stringify(x) ===
                        JSON.stringify({ OPL: OPL, Tower: Tower, Phase: Phase, ChannelId: Channel, name: name })
                ).length === 0
            )
                tmp.push({
                    OPL: OPL,
                    Tower: Tower,
                    Phase: Phase,
                    ChannelId: Channel,
                    device: device ? device : -1,
                    name: name,
                });
            form.setFieldValue("targetDevices", tmp);
        } else if (towerDeviceType === "Device" && device && Channel) {
            let tmp = form.values.targetDevices ? form.values.targetDevices : [];
            if (
                tmp.filter(
                    (x) =>
                        JSON.stringify(x) ===
                        JSON.stringify({ OPL: OPL, Tower: Tower, Phase: Phase, ChannelId: Channel, name: name })
                ).length === 0
            )
                tmp.push({
                    OPL: null,
                    Tower: null,
                    Phase: null,
                    ChannelId: Channel,
                    device: device,
                    name: name,
                });
            form.setFieldValue("targetDevices", tmp);
        } else {
            showNotification({
                title: t("Ошибка!"),
                message: t("Не все параметры заданы"),
                autoClose: 5000,
                color: "red",
            });
        }
    };

    const getChannels = () => {
        if (choosingPhase)
            if (
                TowerFull &&
                TowerFull.OverheadPowerLineTowerDeviceСhannelsDtos &&
                TowerFull.DevicesDtos &&
                TowerFull.DevicesDtos.filter((device) => device.OverheadPowerLineTowersPhase === choosingPhase).length >
                    0
            ) {
                let tmp = [];
                let deviceId = TowerFull.DevicesDtos.filter(
                    (device) => device.OverheadPowerLineTowersPhase === choosingPhase
                )[0].Id;
                for (let x of TowerFull.OverheadPowerLineTowerDeviceСhannelsDtos) {
                    let channels = [];
                    let allChannels = x.DeviceChannelsDto.map((Channel) => ({
                        Id: x.DeviceModelChannelId,
                        deviceId: Channel.DeviceId,
                        Name: Channel.Name,
                    }));
                    for (let y of allChannels ? allChannels : []) if (y.deviceId === deviceId) channels.push(y);
                    tmp.push(...channels);
                }
                return tmp;
            } else if (
                TowerFull &&
                TowerFull.OverheadPowerLineTowerDeviceСhannelsDtos &&
                TowerFull.DevicesDtos &&
                TowerFull.DevicesDtos.filter((device) => device.Id === choosingDevice).length > 0
            ) {
                let tmp = [];
                let deviceId = TowerFull.DevicesDtos.filter((device) => device.Id === choosingDevice)[0].Id;
                for (let x of TowerFull.OverheadPowerLineTowerDeviceСhannelsDtos) {
                    let channels = [];
                    let allChannels = x.DeviceChannelsDto.map((Channel) => ({
                        Id: x.DeviceModelChannelId,
                        deviceId: Channel.DeviceId,
                        Name: Channel.Name,
                    }));
                    for (let y of allChannels ? allChannels : []) if (y.deviceId === deviceId) channels.push(y);
                    tmp.push(...channels);
                }
                return tmp;
            }
        return [];
    };

    const [channels, setChannels] = useState<{ Id: number; deviceId: number; Name: string }[]>();

    useEffect(() => {
        if (TowerFull || choosingDevice) setChannels(getChannels());
    }, [TowerFull, choosingDevice]);

    const deleteChannel = (channel: targetDeviceChannel) => {
        form.setFieldValue(
            "targetDevices",
            form.values.targetDevices.filter((x) => x !== channel)
        );
    };

    const handleSelectAveraging = (value: string) => {
        form.setFieldValue("averaging", value !== "" ? parseInt(value) : null);
    };

    function getChartTimes() {
        return (
            <div className="md:space-x-2 md:flex">
                <Select
                    id="veraging-select"
                    value={form.values.averaging ? form.values.averaging.toString() : "0"}
                    onChange={handleSelectAveraging}
                    label={t("Усреднение").toString()}
                    data={[
                        { value: "0", label: t("Без усреднения") },
                        { value: "5", label: t("5 минут") },
                        { value: "10", label: t("10 минут") },
                        { value: "30", label: t("30 минут") },
                        { value: "60", label: t("1 час") },
                        { value: "120", label: t("2 часа") },
                        { value: "240", label: t("4 часа") },
                    ]}
                />
                <DatePicker
                    value={form.values.startDt}
                    maxDate={
                        new Date(Math.min(form.values.endDt?.getTime() || currentDate.getTime(), currentDate.getTime()))
                            ? new Date(
                                  Math.min(form.values.endDt?.getTime() || currentDate.getTime(), currentDate.getTime())
                              )
                            : undefined
                    }
                    inputFormat="DD.MM.YYYY"
                    onChange={(x) => form.setFieldValue("startDt", x)}
                    label={t("От").toString()}
                />
                <DatePicker
                    value={form.values.endDt}
                    minDate={form.values.startDt ? form.values.startDt : undefined}
                    inputFormat="DD.MM.YYYY"
                    onChange={(x) => form.setFieldValue("endDt", x)}
                    label={t("До").toString()}
                />
            </div>
        );
    }

    useEffect(() => {
        setChoosingPhase(OverheadPowerLineTowersPhaseEnum.A);
    }, []);

    useEffect(() => {
        if (towerDeviceType === "Tower")
            setChoosingDevice(
                TowerFull &&
                    TowerFull.DevicesDtos &&
                    TowerFull.DevicesDtos.filter((x) => x.OverheadPowerLineTowersPhase === choosingPhase).length > 0
                    ? TowerFull.DevicesDtos.filter((x) => x.OverheadPowerLineTowersPhase === choosingPhase)[0].Id
                    : null
            );
    }, [choosingPhase, TowerFull]);

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                if (editing) editing.stop({ ...form.values, index: editing.settings.index });
                else save(form.values);
                close();
                form.reset();
            }}
        >
            <div className="space-y-2 flex-auto mb-6">
                <span className="mb-2 text-base font-medium text-gray-900">{t("График")}</span>
                <TextInput
                    id="Name"
                    name="Name"
                    placeholder={t("Название")}
                    label={t("Название")}
                    {...form.getInputProps("Name")}
                />
            </div>

            <div className="space-y-2 flex-auto mb-6">
                <span className="mb-2 text-base font-medium text-gray-900">
                    {t("Использовать опору или устройство")}
                </span>
                <SegmentedControl
                    value={towerDeviceType}
                    data={[
                        { value: "Tower", label: "Опора" },
                        { value: "Device", label: "Устройство" },
                    ]}
                    onChange={setTowerDeviceTab}
                />
            </div>

            <div className="space-y-4 flex-auto">
                <span className="mb-2 text-base font-medium text-gray-900">{t("Каналы")}</span>
                {towerDeviceType === "Tower" ? (
                    <>
                        <div>
                            <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                                {t("Выберите ВЛ")}
                            </span>
                            <SimpleSearchList
                                items={OPLs ? OPLs : []}
                                setValue={(x) => setChoosingOPL(x)}
                                chosenId={choosingOPL ? choosingOPL : null}
                            />
                        </div>

                        {choosingOPL ? (
                            <div>
                                <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                                    {t("Выберите опору")}
                                </span>
                                <SimpleSearchList
                                    items={Towers ? Towers.filter((x) => x.OverheadPowerLineId === choosingOPL) : []}
                                    setValue={(x) => setChoosingTower(x)}
                                    chosenId={choosingTower ? choosingTower : null}
                                />
                            </div>
                        ) : (
                            ""
                        )}

                        {choosingTower ? (
                            <div>
                                <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                                    {t("Выберите фазу")}
                                </span>
                                <SegmentedControl
                                    className="mt-2"
                                    fullWidth
                                    value={choosingPhase ? choosingPhase.toString() : ""}
                                    onChange={(event) => {
                                        setChoosingPhase(parseInt(event) as OverheadPowerLineTowersPhaseEnum);
                                    }}
                                    data={[
                                        { label: "A", value: OverheadPowerLineTowersPhaseEnum.A.toString() },
                                        { label: "B", value: OverheadPowerLineTowersPhaseEnum.B.toString() },
                                        { label: "C", value: OverheadPowerLineTowersPhaseEnum.C.toString() },
                                    ]}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </>
                ) : (
                    <div>
                        <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                            {t("Выберите устройство")}
                        </span>
                        <SimpleSearchList
                            items={devices ? devices : []}
                            setValue={(x) => setChoosingDevice(x)}
                            chosenId={choosingDevice ? choosingDevice : null}
                        />
                    </div>
                )}

                {channels && (choosingDevice ? choosingDevice : -1) > 0 ? (
                    <div>
                        <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                            {t("Выберите канал")}
                        </span>
                        <SimpleSearchList
                            items={channels}
                            setValue={(x) => setChoosingChannel(x)}
                            chosenId={choosingChannel ? choosingChannel : null}
                        />
                    </div>
                ) : (
                    ""
                )}

                {choosingChannel ? (
                    <Button
                        variant="outline"
                        style={{ width: "100%" }}
                        onClick={() =>
                            addDevice(
                                choosingOPL,
                                choosingTower,
                                choosingPhase,
                                choosingChannel,
                                choosingDevice,
                                towerDeviceType === "Tower"
                                    ? `${
                                          OPLs?.filter((y) => y.Id === choosingOPL)[0]
                                              ? OPLs?.filter((y) => y.Id === choosingOPL)[0].Name
                                              : ""
                                      }/${
                                          Towers?.filter((y) => y.Id === choosingTower)[0]
                                              ? Towers?.filter((y) => y.Id === choosingTower)[0].Name
                                              : ""
                                      }/${choosingPhase ? PhaseToString(choosingPhase) : ""}/${
                                          getChannels().filter((y) => y.Id === choosingChannel)[0]
                                              ? getChannels().filter((y) => y.Id === choosingChannel)[0].Name
                                              : ""
                                      }`
                                    : `${devices?.filter((device) => device.Id === choosingDevice)[0].Name}/${
                                          getChannels().filter((y) => y.Id === choosingChannel)[0]
                                              ? getChannels().filter((y) => y.Id === choosingChannel)[0].Name
                                              : ""
                                      }`
                            )
                        }
                    >
                        {t("Готово")}
                    </Button>
                ) : (
                    ""
                )}

                {form.values.targetDevices && form.values.targetDevices.length !== 0 ? (
                    <>
                        <Chip.Group>
                            {form.values.targetDevices.map((x) => (
                                <Chip>
                                    <Tooltip
                                        label={
                                            x.Phase
                                                ? `${
                                                      OPLs?.filter((y) => y.Id === x.OPL)[0]
                                                          ? OPLs?.filter((y) => y.Id === x.OPL)[0].Name
                                                          : ""
                                                  }/
                                            ${
                                                Towers?.filter((y) => y.Id === x.Tower)[0]
                                                    ? Towers?.filter((y) => y.Id === x.Tower)[0].Name
                                                    : ""
                                            }/
                                            ${PhaseToString(x.Phase)}/
                                            ${
                                                getChannels().filter((y) => y.Id === x.ChannelId)[0]
                                                    ? getChannels().filter((y) => y.Id === x.ChannelId)[0].Name
                                                    : ""
                                            }`
                                                : `${devices?.filter((device) => device.Id === x.device)[0].Name}/${
                                                      getChannels().filter((y) => y.Id === x.ChannelId)[0]
                                                          ? getChannels().filter((y) => y.Id === x.ChannelId)[0].Name
                                                          : ""
                                                  }`
                                        }
                                    >
                                        <Group>
                                            <span
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    width: 300,
                                                }}
                                            >
                                                {x.Phase
                                                    ? `${
                                                          OPLs?.filter((y) => y.Id === x.OPL)[0]
                                                              ? OPLs?.filter((y) => y.Id === x.OPL)[0].Name
                                                              : ""
                                                      }/
                                            ${
                                                Towers?.filter((y) => y.Id === x.Tower)[0]
                                                    ? Towers?.filter((y) => y.Id === x.Tower)[0].Name
                                                    : ""
                                            }/
                                            ${PhaseToString(x.Phase)}/
                                            ${
                                                getChannels().filter((y) => y.Id === x.ChannelId)[0]
                                                    ? getChannels().filter((y) => y.Id === x.ChannelId)[0].Name
                                                    : ""
                                            }`
                                                    : `${devices?.filter((device) => device.Id === x.device)[0].Name}/${
                                                          getChannels().filter((y) => y.Id === x.ChannelId)[0]
                                                              ? getChannels().filter((y) => y.Id === x.ChannelId)[0]
                                                                    .Name
                                                              : ""
                                                      }`}
                                            </span>
                                            <IconX size={18} color="#a1aab3" onClick={() => deleteChannel(x)} />
                                        </Group>
                                    </Tooltip>
                                </Chip>
                            ))}
                        </Chip.Group>
                        {getChartTimes()}
                        <Button style={{ width: "100%" }} type="submit">
                            {t("Создать график")}
                        </Button>
                    </>
                ) : (
                    ""
                )}
            </div>
        </form>
    );
};
