import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ParameterDtoSmall } from "src/features/devices/types";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";

export const getAllParams = (): Promise<ParameterDtoSmall[]> => {
    return axios.get(`/Parameters/all`);
};

type QueryFnType = typeof getAllParams;

export const useAllParams = () => {
    const { t } = useTranslation();
    let result = useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [`AllParameters`],
        queryFn: () => getAllParams(),
        refetchInterval: 30000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка при загрузке параметров"),
            });
        },
    });

    return result;
};
