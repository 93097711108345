import { Button, Drawer, Paper, SegmentedControl } from "@mantine/core"
import { OPL_full } from "src/features/OPL/types"
import { SearchingList } from "./SearchingList"
import { useTranslation } from "react-i18next"
import { NotificationsPage } from "src/features/UserNotifications/components/NotificationsPage"
import { useOPLnotifications } from "src/features/UserNotifications/api/getOPLnotifications"
import { DBoffMock } from "src/features/OPL/components/DBoffMock"
import { Towerform } from "src/features/companies/components/OP"
import { useAuth } from "src/lib/auth"
import { useDisclosure } from "@mantine/hooks"
import { useState } from "react"
import { BindingTowerForm } from "src/features/OPL/components/BindingTowerForm"

type CurrentOPLProps = {
    tab?: string,
    OPL: OPL_full,
    id: number | null,
    setId: (x: number | null) => void,
}

export const CurrentOPL: React.FC<CurrentOPLProps> = ({tab, OPL, id, setId}) => {

    const { t } = useTranslation()

    const { data: notifications } = useOPLnotifications(OPL.Id)

    const { user } = useAuth()

    const [opened, {open, close}] = useDisclosure(false)

    const [bindingTowerType, setBindingTowerType] = useState<string>("new")

    return(
        <Paper className="mb-4" p="md" shadow="sm" style={{overflowY: "scroll", overflowX: "hidden", marginBottom: 0, boxSizing: "border-box", flexGrow: 1}}>
            {tab === "list" ?
                OPL.OverheadPowerLineTowers.length ?
                <SearchingList
                    elements={OPL.OverheadPowerLineTowers}
                    label={t("Опоры")}
                    setId={setId}
                    id={id}
                    styles={{width: "100%", height: "fit-content", padding: 0}}
                    topButton={<Button onClick={open} variant="filled" fullWidth>{t("Добавить опору")}</Button>}
                />
                : <DBoffMock label={t("Опор нет")}/>
            :
                <NotificationsPage
                    notifications = {notifications ? notifications : []}
                />
            }
            <Drawer
                opened={opened}
                onClose={close}
                title   = {t("Добавление")}
                padding="xl"
                size="xl"
                styles={{
                    drawer: {
                        overflowY: "auto",
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        "&::WebkitScrollbar": { width: 0, }
                    },
                }}
                position="right"
            >
                <SegmentedControl
                    data={[
                        {label: t("Создать новую"), value: "new"},
                        {label: t("Выбрать существующую"), value: "existing"},
                    ]}
                    value={bindingTowerType}
                    onChange={setBindingTowerType}
                />
                {bindingTowerType === "new" ?
                <Towerform
                    close={close}
                    company={user?.CompanyId || 0}
                    forcingOPL={OPL.Id}
                />
                :
                <BindingTowerForm
                    close={close}
                    OPLid={OPL.Id}
                />}
            </Drawer>
        </Paper>
    )
}