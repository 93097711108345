import { useState, useEffect } from "react";
import { useAuth } from "src/lib/auth";
import { useTranslation } from "react-i18next";
import { Stepper } from "@mantine/core";
import { Stack, Button, TextInput, PasswordInput, Textarea, Popover, Progress, Group, Center } from "@mantine/core";

import { Page } from "src/components/layout/Page";
import { useRegistration } from "../api/registration";

const RegistrationForm: React.FC = () => {
    const { t } = useTranslation();

    const [active, setActive] = useState(0);

    const { user } = useAuth();

    const [FIO, setFIO] = useState("");
    const [login, setLogin] = useState("");
    const [desc, setDesc] = useState("");

    const [FIOinvalid, setFIOinvalid] = useState(true);
    const [loginInvalid, setLoginInvalid] = useState(false);

    const register_mutation = useRegistration(user?.CompanyId || -1);

    const regexpFIO = RegExp("^[\\p{L}]+(?:-[\\p{L}]+)?\\s[\\p{L}]+(?:-[\\p{L}]+)?\\s?[\\p{L}]+(?:-[\\p{L}]+)?$", "u");
    useEffect(() => {
        setFIOinvalid(!regexpFIO.test(FIO) || FIO.length === 0);
    }, [FIO]);

    const regexpLogin = RegExp("^[\\p{L}0-9._-]+$", "u");
    useEffect(() => {
        setLoginInvalid(!regexpLogin.test(login) || login.length === 0);
    }, [login]);

    const [currentPassword, setCurrentPassword] = useState("");
    const [firstNewPassword, setFirstNewPassword] = useState("");
    const [secondNewPassword, setSecondNewPassword] = useState("");

    const [result, setResult] = useState({
        Id: -1,
        UserName: "",
        FIO: "",
        Description: "",
        Password: "",
    });

    useEffect(() => {
        setResult({
            Id: user?.Id || -1,
            UserName: login,
            FIO: FIO,
            Description: desc,
            Password: firstNewPassword,
        });
    }, [FIO, login, desc, firstNewPassword]);

    const [popoverOpened, setPopoverOpened] = useState(false);

    enum PassValidationEnum {
        invalid = -1,
        weak = 0,
        medium = 1,
        strong = 2,
    }

    const [currentPasswordValid, setCurrentPasswordValid] = useState(false);
    const [firstNewPasswordValid, setFirstNewPasswordValid] = useState(PassValidationEnum.weak);
    const [secondNewPasswordValid, setSecondNewPasswordValid] = useState(false);

    useEffect(() => {
        setCurrentPasswordValid(currentPassword.length !== 0);
    }, [currentPassword]);

    const ValidatePassword = (pass: string) => {
        let validation_map = {
            long: false,
            validSymbols: false,
            specSymbols: false,
            bigSymbols: false,
            smallSymbols: false,
            numbers: false,
        };
        let UpperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let specSymbols = ".:,;?!@#$%^&*_-+=";
        let numbers = "0123456789";
        let result = [];
        const PasswordRegex = RegExp("^[A-Za-z0-9\\.:,;\\?!@#\\$%\\^&\\*_\\-\\+=]+$");
        validation_map.long = pass.length >= 8;
        validation_map.validSymbols = PasswordRegex.test(pass);
        for (let symb of pass) {
            if (specSymbols.includes(symb)) validation_map.specSymbols = true;
            if (UpperCase.includes(symb)) validation_map.bigSymbols = true;
            if (UpperCase.toLowerCase().includes(symb)) validation_map.smallSymbols = true;
            if (numbers.includes(symb)) validation_map.numbers = true;
        }
        if (!validation_map.validSymbols) return PassValidationEnum.invalid;
        if (
            !validation_map.long ||
            !validation_map.numbers ||
            !validation_map.smallSymbols ||
            !validation_map.bigSymbols
        )
            return PassValidationEnum.weak;
        if (!validation_map.specSymbols) return PassValidationEnum.medium;
        return PassValidationEnum.strong;
    };

    useEffect(() => {
        setFirstNewPasswordValid(ValidatePassword(firstNewPassword));
    }, [firstNewPassword]);

    useEffect(() => {
        setSecondNewPasswordValid(secondNewPassword === firstNewPassword);
    }, [firstNewPassword, secondNewPassword]);

    return (
        <Center style={{ height: "100%", width: "100%", backgroundColor: "#FFF" }}>
            <Page
                header={{
                    title: t("Регистрация"),
                }}
                style={{ height: "100%", boxSizing: "border-box" }}
            >
                <Stepper active={active}>
                    <Stepper.Step label={t("Информация")} description={t("ФИО, логин и описание")}>
                        <Stack justify="center">
                            <TextInput
                                label={t("ФИО")}
                                placeholder={t("Фамилия Имя Отчество")}
                                value={FIO}
                                onChange={(event) => setFIO(event.currentTarget.value)}
                                error={FIOinvalid && FIO.length > 0 ? t("Введите валидное ФИО") : false}
                                withAsterisk
                            />

                            <TextInput
                                label={t("Логин")}
                                placeholder={t("Username")}
                                value={login}
                                onChange={(event) => setLogin(event.currentTarget.value)}
                                error={loginInvalid && login.length > 0 ? t("Введите валидный логин") : false}
                                withAsterisk
                            />

                            <Textarea
                                label={t("Описание")}
                                placeholder={t("Введите описание")}
                                value={desc}
                                onChange={(event) => setDesc(event.currentTarget.value)}
                                // withAsterisk
                            />

                            <Button
                                styles={{
                                    root: {
                                        borderRadius: "8px",
                                        width: "fit-content",
                                        margin: "auto",
                                    },
                                }}
                                disabled={FIOinvalid || loginInvalid}
                                onClick={() => setActive(1)}
                            >
                                {t("Далее")}
                            </Button>
                        </Stack>
                    </Stepper.Step>
                    <Stepper.Step label={t("Пароль")} description={t("Установка пароля")}>
                        <Stack>
                            <PasswordInput
                                label={t("Текущий пароль")}
                                placeholder={t("Введите текущий пароль")}
                                value={currentPassword}
                                onChange={(event) => setCurrentPassword(event.currentTarget.value)}
                                withAsterisk
                            />
                            <Popover opened={popoverOpened} position="top" width="target" transition="pop">
                                <Popover.Target>
                                    <PasswordInput
                                        label={t("Новый пароль")}
                                        placeholder={t("Введите новый пароль")}
                                        value={firstNewPassword}
                                        onChange={(event) => setFirstNewPassword(event.currentTarget.value)}
                                        withAsterisk
                                        onFocusCapture={() => setPopoverOpened(true)}
                                        onBlurCapture={() => setPopoverOpened(false)}
                                    />
                                </Popover.Target>
                                <Popover.Dropdown>
                                    <Progress
                                        color={
                                            firstNewPasswordValid === PassValidationEnum.invalid ||
                                            firstNewPasswordValid === PassValidationEnum.weak
                                                ? "red"
                                                : firstNewPasswordValid === PassValidationEnum.medium
                                                ? "yellow"
                                                : "green"
                                        }
                                        value={
                                            firstNewPasswordValid === PassValidationEnum.invalid
                                                ? 1
                                                : firstNewPasswordValid === PassValidationEnum.weak
                                                ? 25
                                                : firstNewPasswordValid === PassValidationEnum.medium
                                                ? 50
                                                : 100
                                        }
                                        style={{ marginBottom: 15 }}
                                    />
                                    {firstNewPasswordValid === PassValidationEnum.invalid
                                        ? t(
                                              "Пароль должен содержать только буквы латинского алфавита, цифры и следующие символы"
                                          ) + ": .:,;?!@#$%^&*_-+="
                                        : firstNewPasswordValid === PassValidationEnum.weak
                                        ? t(
                                              "Пароль должен быть не короче 8 символов, а также содержать цифры и буквы латинского алфавита обоих регистров."
                                          )
                                        : firstNewPasswordValid === PassValidationEnum.medium
                                        ? t("Данный пароль подходит. Для увеличения надёжности добавьте спец. символы.")
                                        : t("Данный пароль надёжен.")}
                                </Popover.Dropdown>
                            </Popover>

                            <PasswordInput
                                label={t("Подтверждение нового пароля")}
                                placeholder={t("Подтвердите новый пароль")}
                                value={secondNewPassword}
                                onChange={(event) => setSecondNewPassword(event.currentTarget.value)}
                                withAsterisk
                                error={secondNewPasswordValid ? false : t("Пароли не совпадают")}
                            />
                            <Group position={"center"}>
                                <Button
                                    styles={{
                                        root: {
                                            borderRadius: "8px",
                                            width: "fit-content",
                                        },
                                    }}
                                    variant="outline"
                                    onClick={() => setActive(0)}
                                >
                                    {t("Назад")}
                                </Button>

                                <Button
                                    styles={{
                                        root: {
                                            borderRadius: "8px",
                                            width: "fit-content",
                                        },
                                    }}
                                    disabled={
                                        !(
                                            currentPasswordValid &&
                                            (firstNewPasswordValid === PassValidationEnum.strong ||
                                                firstNewPasswordValid === PassValidationEnum.medium) &&
                                            secondNewPasswordValid
                                        )
                                    }
                                    onClick={async () => {
                                        await register_mutation.mutateAsync(result);
                                    }}
                                >
                                    {t("Сохранить")}
                                </Button>
                            </Group>
                        </Stack>
                    </Stepper.Step>
                </Stepper>
            </Page>
        </Center>
    );
};

export default RegistrationForm;
