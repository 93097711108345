import { Paper } from "@mantine/core"
import { Mock } from "src/features/monitoring_towers/components/Mock"
import { Tower_full } from "src/features/OPL/types"
import { RemoteControl } from "./RemoteControl"
import { Size } from "./Size"
import { Firmware } from "./Firmware"
import { DBoffMock } from "./DBoffMock"
import { useTranslation } from "react-i18next"
import { useTowerNotifications } from "src/features/UserNotifications/api/getTowerNotifications"
import { NotificationsPage } from "src/features/UserNotifications/components/NotificationsPage"
import { useResizeObserver } from "@mantine/hooks"

type SetupCurrentTowerProps = {
    tab:        string,
    tower?:     Tower_full,
}

export const SetupCurrentTower: React.FC<SetupCurrentTowerProps>  = ({ tab, tower }) => {

    const filters = {
        "GIO": [
            "Измеренное значение температуры провода ВЛ",
            "Измеренное значение угла стрелы провеса",
            "Гололед (кг на метр)",
            "Гололед (кг на пролет)",
            "Измеренное значение амплитуды вибрации",
            "Измеренное значение частоты вибрации",
            "Измеренное значение амплитуды пляски",
            "Измеренное значение температуры акселерометра",
        ],
        "KZ": [
            "Длительность периода тока до события",
            "Длительность периода тока в момент события",
            "Длительность периода тока после события",
            "Код события",
            "Значение тока короткого замыкания",
            "Разница периодов тока до КЗ и при КЗ",
        ],
        "GP": [
            "Обнаружение обрыва",
            "Габарит",
        ],
        "WP": [
            "Напряжение на ионисторах",
            "Уровень сигнала GSM",
            "GSM result code",
            "Код причины последней перезагрузки",
            "ICCID",
            "MAC adress",
            "Версия ПО",
        ]
    }

    const { t } = useTranslation()

    const { data: notifications } = useTowerNotifications(tower?.Id || null)

    const [ref, rect] = useResizeObserver();

    return(
        <Paper className="mb-4" p="md" shadow="sm" style={{overflowX: "hidden", marginBottom: 0, boxSizing: "content-box", minHeight: `${rect.height}px`, flexGrow: 1}}>
            <div style={{height: "fit-content"}} ref={ref}>
            {
                tower ?
                    tower.DevicesDtos && tower.DevicesDtos?.length>0 ?
                        tab === "RemoteControl" && (tower.OverheadPowerLineTowerDeviceParametersDtos?.length !== 0) ?
                            <RemoteControl
                                selectedTower={tower}
                            />
                        :
                        tab === "Firmware" ?
                            // <Mock/>
                            <Firmware
                                tower={tower}
                            />
                        :
                        tab === "GIO" ?
                            <Mock/>
                        :
                        tab === "ShortCircuit" ?
                            <Mock/>
                        :
                        tab === "Size" ?
                            // <Mock/>
                            <Size
                                tower={tower}
                            />
                        :
                        tab === "Notifications" ? 
                            <NotificationsPage
                                notifications={notifications || []}
                            />
                        :'!tab'
                    : <DBoffMock label={t("Устройств нет")}/>
                :'!tower'
                
            }
            </div>
        </Paper>
    )
}