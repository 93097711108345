import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";
import { OverheadPowerLineTowersPhaseEnum } from "../types";

export type unbindOPLcommand = {
    OverheadPowerLineId: number;
    CompanyId: number;
};

export const unbindOPL = (command: unbindOPLcommand): Promise<EntityId<number>> => {
    return axios.post(`/Companies/${command.CompanyId}/tower/${command.OverheadPowerLineId}/unbind`, command);
};

type UseUnbindOPLoptions = {
    command: unbindOPLcommand;
    config?: MutationConfig<typeof unbindOPL>;
};

export const useUnbindOPL = ({ command, config }: UseUnbindOPLoptions) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries([`CompanyOPLs/${command.CompanyId}`, `OPL${command.OverheadPowerLineId}`]);
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка отвязки ВЛ"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries([`CompanyOPLs/${command.CompanyId}`, `OPL${command.OverheadPowerLineId}`]);
            showNotification({
                title: t("Успех!"),
                message: t("ВЛ успешно отвязана"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: unbindOPL,
    });
};
