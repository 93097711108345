import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";
import { ThresholdDirectionEnum } from "src/features/notifications/types";
import { ThresholdTypeEnum } from "src/features/notifications/api/createChannelThreshold";

export type CreateOverheadPowerLineTowerThresholdCommand = {
    Value:              number,
    Description:        string | null,
    Direction:          ThresholdDirectionEnum,
    Color:              string | null,
    MessageTemplate:    string | null,
    ShowOnChart:        boolean,
    ChannelId:          number,
    TowerId:            number,
    ImageEnabled:       boolean,
    ImageChannelId:     number | null,
    CompanyId:          number | null,
    ThresholdType:      ThresholdTypeEnum,
}

export const createTowerThreshold = (command: CreateOverheadPowerLineTowerThresholdCommand): Promise<EntityId<number>> => {
    return axios.post(`/OverheadPowerLineTower/${command.TowerId}/channels/${command.ChannelId}/thresholds`, command);
};

type UseCreateTowerThresholdOptions = {
    config?: MutationConfig<typeof createTowerThreshold>;
};

export const useCreateTowerThreshold = ({ config }: UseCreateTowerThresholdOptions = {}, TowerId: number) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries(`TowerThresholds${TowerId}`);
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка создания уставки"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(`TowerThresholds${TowerId}`);
            showNotification({
                title: t("Успех!"),
                message: t("Уставка успешно создана"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: createTowerThreshold,
    });
};
