import { TextInput, Button } from "@mantine/core"
import { useTranslation } from "react-i18next";
import { useForm, yupResolver } from "@mantine/form";

import { useCreateGroup, CreateGroupCommand } from "src/features/companies/api/createCompany";

interface CreateGroupFormProps {
    setOpen: (value: boolean) => void;
    current_company_id: number | null;
}

const CreateGroupForm: React.FC<CreateGroupFormProps> = ({setOpen, current_company_id}) => {

    const { t } = useTranslation();

    function handleClose() {
        setOpen(false);
        form.reset();
    }

    const createGroupMutation = useCreateGroup(current_company_id ? current_company_id : -1) 

    const form = useForm<CreateGroupCommand>({
        initialValues: {
            Name: "",
            CompanyParentId: current_company_id ? current_company_id : null
        }
    });

    return(
        <form
            className="flex flex-col overflow-y-scroll"
            onSubmit={form.onSubmit(async (values) => {
            await createGroupMutation.mutateAsync(values);
            handleClose();
        })}
        >
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "9px"
            }}
        >
            <TextInput
                value={form.values.Name.toString()}
                onChange={(event) => {
                    form.setFieldValue("Name", event.target.value);
                }}
                label={t("Название группы")}
                placeholder={t("Введите название группы")}
                styles={{
                    root: {
                        width: "100%"
                    }
                }}
                withAsterisk
            />
            <Button
                type="submit"
            >
                {t("Создать")}
            </Button>
        </div>
        </form>
    )
}

export default CreateGroupForm