import React from "react";
import { Paper } from "@mantine/core"
import { useTranslation } from "react-i18next";

import Groups from "./Groups";
import Users from "src/features/users_new/components/Users";

import { CompanyT } from "src/features/companies/api/getCompanies";
import { UserT } from "src/features/users_new/api/getUsers";
import OP from "./OP";
import { useCompanyNotifications } from "src/features/UserNotifications/api/getCompanyNotifications";
import { NotificationsPage } from "src/features/UserNotifications/components/NotificationsPage";

interface CurrentCompanyI {
    company: CompanyT | null;
    tab:     string;
    select_company:    (company: CompanyT | null) => void;
    movingUser: UserT | null;
    setMovingUser: (user: UserT | null)=>void;
}

const CurrentCompany: React.FC<CurrentCompanyI> = ({company, tab, select_company, movingUser, setMovingUser}) => {

    const { t } = useTranslation();

    const {data: notifications} = useCompanyNotifications(company?.Id || null)

    return (
        <Paper className="mb-4" p="md" shadow="sm" style={{overflow: "scroll", marginBottom: 0, boxSizing: "border-box", flexGrow: 1}}>
            {
                tab === "groups" ?
                    <Groups
                        company        = {company}
                        select_company = {select_company}
                    />
                :
                tab === "users" ?
                    <Users
                        company         = {company}
                        movingUser      = {movingUser}
                        setMovingUser   = {setMovingUser}
                    />
                    :
                tab === "OP" ?
                    company ? 
                    <OP
                        company        = {company?.Id}
                    />
                    : ''
                :
                tab === "notifications" ?
                    company ? 
                    <NotificationsPage
                        notifications={notifications || []}
                    />
                    : ''
                : ''
            }
        </Paper>
    );
};

export default CurrentCompany