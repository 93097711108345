import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { PagedCollection, PagedRequest } from "../../../types";
import { Tower_small } from "src/features/OPL/types";

export interface GetCompanyTowersQuery extends PagedRequest {
    CompanyId: number;
}

export const getCompanyTowers = (request: GetCompanyTowersQuery): Promise<PagedCollection<Tower_small>> => {
    return axios.post(`/Companies/${request.CompanyId}/overheadpowerlinetowers`, request);
};

type QueryFnType = typeof getCompanyTowers;

type UseCompanyTowersOptions = {
    request: GetCompanyTowersQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useCompanyTowers = ({ request, config }: UseCompanyTowersOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: [`CompanyTowers/${request.CompanyId}`],
        queryFn: () => getCompanyTowers(request),
    });
};
