import { Button, Drawer, Flex, Group, Paper, SegmentedControl } from "@mantine/core"
import { Mock } from "./Mock"
import { Tower_full } from "src/features/OPL/types"
import { ChannelsTable } from "./ChannelsTable"
import { IconPlus } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"
import { useCreateChannel } from "src/features/OPL/api/createChannel"
import { NotificationsPage } from "src/features/UserNotifications/components/NotificationsPage"
import { useTowerNotifications } from "src/features/UserNotifications/api/getTowerNotifications"
import { CreationDrawerForm } from "src/features/companies/components/OP"
import { useAuth } from "src/lib/auth"
import { useDisclosure, useResizeObserver } from "@mantine/hooks"
import { CreateDeviceDrawer } from "src/features/devices/components/CreateDeviceDrawer"
import { DBoffMock } from "src/features/OPL/components/DBoffMock"
import { CreateOPLChannelDrawer } from "src/features/OPL/components/CreateOPLChannelDrawer"
import { CreateOPLParameterDrawer } from "src/features/OPL/components/CreateOPLParameterDrawer"
import { useState } from "react"
import { BindingDeviceForm } from "src/features/OPL/components/BindingDeviceForm"

type CurrentTowerProps = {
    tab:        string,
    tower:     Tower_full,
}

// const filters = {
//     "GIO": [
//         "Измеренное значение температуры провода ВЛ",
//         "Измеренное значение угла стрелы провеса",
//         "Гололед (кг на метр)",
//         "Гололед (кг на пролет)",
//         "Измеренное значение амплитуды вибрации",
//         "Измеренное значение частоты вибрации",
//         "Измеренное значение амплитуды пляски",
//         "Измеренное значение температуры акселерометра",
//     ],
//     "KZ": [
//         "Длительность периода тока до события",
//         "Длительность периода тока в момент события",
//         "Длительность периода тока после события",
//         "Код события",
//         "Значение тока короткого замыкания",
//         "Разница периодов тока до КЗ и при КЗ",
//     ],
//     "GP": [
//         "Обнаружение обрыва",
//         "Габарит",
//     ],
//     "WP": [
//         "Напряжение на ионисторах",
//         "Уровень сигнала GSM",
//         "GSM result code",
//         "Код причины последней перезагрузки",
//         "ICCID",
//         "MAC adress",
//         "Версия ПО",
//     ]
// }

const filters = {
    "GIO": [
        "Измеренное значение температуры провода ВЛ",
        "Измеренное значение угла стрелы провеса",
        "Измеренное значение амплитуды вибрации",
        "Измеренное значение частоты вибрации",
        "Гололед (кг на метр)",
        "Гололед (кг на пролет)",
        "Измеренное значение амплитуды пляски",
        "Измеренное значение температуры акселерометра",
    ],
    "KZ": [
        "Измеренное значение тока",
        "Разница периодов тока до КЗ и при КЗ",
        "Значение тока короткого замыкания",
        "Код события",
        "Длительность периода тока до события",
        "Длительность периода тока в момент события",
        "Длительность периода тока после события",
    ],
    "GP": [
        "Обнаружение обрыва",
        "Габарит",
    ],
    "WP": [
        "Напряжение на ионисторах",
        "Уровень сигнала GSM",
        "GSM result code",
        "Код причины последней перезагрузки",
        "ICCID",
        "MAC adress",
        "Версия ПО",
    ]
}

export const CurrentTower: React.FC<CurrentTowerProps>  = ({ tab, tower }) => {

    const { t } = useTranslation()

    const { data: notifications } = useTowerNotifications(tower.Id)

    const { user } = useAuth()

    const [opened, {open, close}] = useDisclosure(false)

    const [ref, rect] = useResizeObserver();

    const [openedAddParameter, {open: openAddParameter, close: closeAddParameter}] = useDisclosure(false)
    const [openedAddChannel, {open: openAddChannel, close: closeAddChannel}] = useDisclosure(false)

    const [bindingDeviceType, setBindingDeviceType] = useState<string>("new")

    return(
        <Paper className="mb-4" p="md" shadow="sm" style={{overflowX: "hidden", marginBottom: 0, boxSizing: "content-box", minHeight: `${rect.height}px`, flexGrow: 1}}>
            <div style={{height: "fit-content"}} ref={ref}>
            {tab !== "notifications" ?
            <Flex className="mb-4" style={{width: "100%", height: "fit-content"}} justify="space-between">
                <Button onClick={open} variant="filled">{t("Добавить устройство")}</Button>
                <Group>
                    <Button leftIcon={<IconPlus/>} onClick={openAddParameter}>
                        {t("Добавить параметр")}
                    </Button>
                    <Button leftIcon={<IconPlus/>} onClick={openAddChannel}>
                        {t("Добавить канал")}
                    </Button>
                </Group>
            </Flex> : '' }
            {
                tab === "GIO" && tower && (tower.DevicesDtos?.length !== 0) ?
                    <ChannelsTable
                        tower={tower}
                        filter={filters["GIO"]}
                    />
                :
                tab === "KZ" && tower && (tower.DevicesDtos?.length !== 0)?
                    <ChannelsTable
                        tower={tower}
                        filter={filters["KZ"]}
                    />
                :
                tab === "GP" && tower && (tower.DevicesDtos?.length !== 0)?
                    <ChannelsTable
                        tower={tower}
                        filter={filters["GP"]}
                    />
                :
                tab === "ASKDTN" && tower && (tower.DevicesDtos?.length !== 0)?
                    <Mock/>
                :
                tab === "WP" && tower && (tower.DevicesDtos?.length !== 0)?
                    <ChannelsTable
                        tower={tower}
                        filter={filters["WP"]}
                    />
                :
                tab === "notifications" && tower && (tower.DevicesDtos?.length !== 0)?
                    <NotificationsPage
                        notifications={notifications ? notifications : []}
                    />
                :   
                    <DBoffMock
                        label={t("Нет устройств")}
                    />
            }
            </div>
            <Drawer
                opened={opened}
                onClose={close}
                title   = {t("Добавление")}
                padding="xl"
                size="xl"
                styles={{
                    drawer: {
                        overflowY: "auto",
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        "&::WebkitScrollbar": { width: 0, }
                    },
                }}
                position="right"
            >
                <SegmentedControl
                    data={[
                        {label: t("Создать новое"), value: "new"},
                        {label: t("Выбрать существующее"), value: "existing"},
                    ]}
                    value={bindingDeviceType}
                    onChange={setBindingDeviceType}
                />
                {bindingDeviceType === "new" ?
                <CreateDeviceDrawer
                    company={user?.CompanyId || 0}
                    onComplete={close}
                    defaults={{
                        OverheadPowerLineTowerId: tower.Id
                    }}
                />
                :
                <BindingDeviceForm
                    towerId={tower.Id}
                    close={close}
                />
                }
            </Drawer>
            <CreateOPLParameterDrawer
                opened={openedAddParameter}
                close={closeAddParameter}
                tower={tower}
            />
            <CreateOPLChannelDrawer
                opened={openedAddChannel}
                close={closeAddChannel}
                tower={tower}
            />
        </Paper>
    )
}