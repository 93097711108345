import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { Tower_small } from "../types";
import { ChannelThresholdDto } from "src/features/notifications/types";

export const getTowerThresholds = (TowerId: number, channelId: number): Promise<ChannelThresholdDto[]> => {
    return axios.get(`/OverheadPowerLineTower/${TowerId}/channels/${channelId}/thresholds`);
};

type QueryFnType = typeof getTowerThresholds;

export const useTowerThresholds = (TowerId: number, channelId: number) => {
    const { t } = useTranslation();
    let result = useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [`TowerThresholds${TowerId}`],
        queryFn: () => getTowerThresholds(TowerId, channelId),
        refetchInterval: 30000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка при загрузке уставок"),
            });
        },
    });

    return result;
};
