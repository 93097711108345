import { ActionIcon, Badge, Center, Group, Paper, Stack, Title } from "@mantine/core";
import { GraphicSettings } from "./GraphicList";
import { useTowerChartData } from "../api/getTowerChartData";
import { useEffect, useState } from "react";
import { getChartDataCommand, useChartData } from "../api/getChartData";
import { IconDatabaseOff, IconEdit, IconTrash } from "@tabler/icons-react";
import Chart from "react-apexcharts";
import { uniq } from "lodash";
import _ from "lodash";
import { t } from "msw/lib/glossary-de6278a9";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useDevices } from "src/features/devices/api/getDevices";
import { СhannelDeviceModelEnum } from "src/features/devices/types";
import { OverheadPowerLineTowersPhaseEnum } from "src/features/OPL/types";
import { useOPLs } from "src/features/OPL/api/getOPLs";
import { useAllChannels } from "src/features/OPL/api/getAllChannels";

const colors = [
    // "#6929c4",
    // "#1192e8",
    // "#005d5d",
    // "#9f1853",
    // "#520408",

    "#BF2600",
    "#FF8B00",
    "#006644",
    "#008DA6",
    "#403294",
    "#0747A6",
    "#091E42",

    // "#DE350B",
    // "#FF991F",
    // "#00875A",
    // "#00A3BF",
    // "#5243AA",
    // "#0052CC",
    // "#253858",

    // "#FF5630",
    // "#FFAB00",
    // "#36B37E",
    // "#00B8D9",
    // "#8777D9",
    // "#0065FF",
    // "#42526E",
];

const colorsOneChannelMode = {
    [OverheadPowerLineTowersPhaseEnum.A]: "#ffff00",
    [OverheadPowerLineTowersPhaseEnum.B]: "#00ff00",
    [OverheadPowerLineTowersPhaseEnum.C]: "#ff0000",
};

export const PhasesVerbose = {
    [OverheadPowerLineTowersPhaseEnum.A]: "A",
    [OverheadPowerLineTowersPhaseEnum.B]: "B",
    [OverheadPowerLineTowersPhaseEnum.C]: "C",
};

type GraphicProps = {
    graphicSettings: GraphicSettings;
    graphicSettingsOneChannelMode?: {};
    onDelete?: () => void;
    onEdit?: () => void;
    OneChannelMode?: boolean;
    DevicePhases?: { deviceId: number; phase: OverheadPowerLineTowersPhaseEnum }[];
};

export const Graphic: React.FC<GraphicProps> = ({
    graphicSettings,
    onDelete,
    onEdit,
    OneChannelMode,
    DevicePhases,
}) => {
    const [graphicReqCommand, setGraphicReqCommand] = useState<getChartDataCommand>();

    const { t } = useTranslation();

    const { data: devices } = useDevices();

    const { data: OPLs } = useOPLs();

    useEffect(() => {
        // setGraphicReqCommand({
        //     DeviceIds: graphicSettings.targetDevices.map(x=>x.device),
        //     OverheadPowerLineIds: graphicSettings.targetDevices.filter(x=>x.OPL && x.OPL > 0).map(x=>x.OPL) as number[],
        //     OverheadPowerLineTowerIds: graphicSettings.targetDevices.filter(x=>x.Tower && x.Tower > 0).map(x=>x.Tower) as number[],
        //     ChannelIds: graphicSettings.targetDevices.map(x=>x.ChannelId),
        //     averaging: graphicSettings.averaging ? graphicSettings.averaging : undefined,
        //     startDt: graphicSettings.startDt ? graphicSettings.startDt : undefined,
        //     endDt: graphicSettings.endDt ? graphicSettings.endDt : undefined,
        // })
        setGraphicReqCommand({
            DeviceIds: graphicSettings.Devices,
            OverheadPowerLineIds: graphicSettings.OPLs,
            OverheadPowerLineTowerIds: graphicSettings.Towers,
            ChannelIds: graphicSettings.Channels,
            averaging: graphicSettings.averaging ? graphicSettings.averaging : undefined,
            startDt: graphicSettings.startDt ? graphicSettings.startDt : undefined,
            endDt: graphicSettings.endDt ? graphicSettings.endDt : undefined,
        });
    }, [graphicSettings]);

    const { data: chartData } = useChartData(graphicReqCommand);

    const [formedData, setFormedData] = useState<{
        data: { name: string; data: number[]; deviceId: number; type: СhannelDeviceModelEnum }[];
        timestamps: Date[];
    }>({ data: [], timestamps: [] });

    // const updateFormedData = () => {
    //     let tmp = []
    //     let timestamps = new Set()
    //     for (let i of chartData?.ChartChannelData ? chartData.ChartChannelData : []){
    //         if (i && i.OverheadPowerLineTowerChartChannelDtos)
    //             for (let k of _.uniq(i.OverheadPowerLineTowerChartChannelDtos)){
    //                 if (
    //                     graphicSettings.targetDevices.map(elem => elem.device).includes(k.DeviceId)
    //                     && tmp.filter(x=>x.name === graphicSettings.targetDevices.filter(elem=>elem.device === k.DeviceId && elem.ChannelId === i.DeviceModelChannelId)[0].name).length === 0
    //                 ) {
    //                     tmp.push({
    //                         name: graphicSettings.targetDevices.filter(elem=>elem.device === k.DeviceId && elem.ChannelId === i.DeviceModelChannelId)[0].name,
    //                         data: k.Values ? k.Values : [],
    //                     })
    //                     if (k.Timestamps)
    //                         k.Timestamps.forEach(elem => {
    //                             timestamps.add(elem)
    //                     })
    //                 }
    //             }
    //     }
    //     let timestampsArr = Array.from(timestamps)
    //     timestampsArr.sort(((a, b) => new Date(a as Date).valueOf() - new Date(b as Date).valueOf()))
    //     setFormedData(
    //         {
    //             data: tmp,
    //             timestamps: timestampsArr as Date[],
    //         }
    //     )
    // }

    const { data: allChannels } = useAllChannels();

    function alignDataWithTimestamps(
        timestampsArr: Date[],
        dataWithTimestampsArr: {
            name: string;
            data: number[];
            deviceId: number;
            type: СhannelDeviceModelEnum;
            timestamps: Date[];
        }[]
    ): { name: string; data: number[]; deviceId: number; type: СhannelDeviceModelEnum; timestamps: Date[] }[] {
        return dataWithTimestampsArr.map((item) => {
            const newData: number[] = [];
            const newTimestamps: Date[] = [];
            let dataIndex = 0;

            for (const targetTimestamp of timestampsArr) {
                const currentTimestamp = item.timestamps[dataIndex];

                // Если текущая метка в dataWithTimestamps совпадает с меткой из timestampsArr
                if (new Date(currentTimestamp).valueOf() === new Date(targetTimestamp).valueOf()) {
                    newData.push(item.data[dataIndex]);
                    newTimestamps.push(currentTimestamp);
                    dataIndex++;
                } else {
                    // Если метки не совпадают, значит, в timestampsArr есть метка, которой нет в dataWithTimestamps
                    // Вставляем недостающую метку и добавляем соответствующее значение data
                    const previousValue = newData.length > 0 ? newData[newData.length - 1] : item.data[0];
                    newData.push(previousValue);
                    newTimestamps.push(targetTimestamp);
                }
            }

            return {
                data: newData,
                timestamps: newTimestamps,
                deviceId: item.deviceId,
                name: item.name,
                type: item.type,
            };
        });
    }

    const updateFormedData = () => {
        let tmp = [];
        let timestamps = new Set();
        for (let i of chartData?.ChartChannelData || []) {
            if (i && i.OverheadPowerLineTowerChartChannelDtos)
                for (let k of i.OverheadPowerLineTowerChartChannelDtos) {
                    let device = devices?.filter((x) => x.Id === k.DeviceId)[0];
                    let Name =
                        device?.OverheadPowerLineTowersPhase && OneChannelMode
                            ? PhasesVerbose[device?.OverheadPowerLineTowersPhase]
                            : device?.Name;
                    if (k.Values && k.Values.length)
                        tmp.push({
                            type: i.DeviceModelChannelId,
                            name: `${Name} ${t(
                                allChannels?.filter(channel => channel.Id === i.DeviceModelChannelId)[0]?.Name
                            )}`,
                            data: k.Values ? k.Values : [],
                            deviceId: k.DeviceId,
                            color: !OneChannelMode
                                ? undefined
                                : colorsOneChannelMode[
                                      DevicePhases?.filter((rec) => rec.deviceId === k.DeviceId)[0].phase ||
                                          OverheadPowerLineTowersPhaseEnum.A
                                  ],
                            timestamps: k.Timestamps || [],
                        });
                    if (k.Timestamps)
                        k.Timestamps.forEach((elem) => {
                            timestamps.add(elem);
                        });
                }
        }
        let timestampsArr = Array.from(timestamps);
        timestampsArr.sort((a, b) => new Date(a as Date).valueOf() - new Date(b as Date).valueOf());
        // for (let channel_data_key in tmp){
        //     let channel_data = tmp[channel_data_key].data || []
        //     let channel_timestamps = tmp[channel_data_key].timestamps || []

        //     let new_channel_data: number[] = []
        //     let new_channel_timestamps: Date[] | null | undefined = []

        //     for (let timestamp_key in timestampsArr){
        //         if (channel_timestamps.includes(timestampsArr[timestamp_key] as Date)){
        //             new_channel_data.push(cha)
        //         }
        //     }

        //     tmp[channel_data_key].data = new_channel_data
        //     tmp[channel_data_key].timestamps = new_channel_timestamps
        // }
        tmp = alignDataWithTimestamps(timestampsArr as Date[], tmp);
        setFormedData({
            data: tmp,
            timestamps: timestampsArr as Date[],
        });
    };

    useEffect(() => {
        updateFormedData();
    }, [chartData]);

    return (
        <Paper
            p="md"
            shadow="sm"
            style={{ overflow: "hidden", marginBottom: 16, boxSizing: "border-box", flexGrow: 1 }}
        >
            <Group style={{ width: "100%", justifyContent: "space-between" }}>
                <Title order={3} style={{ wordBreak: "break-word" }}>
                    {graphicSettings.Name}
                </Title>
                {(!OneChannelMode) &&
                <Group>
                    <ActionIcon onClick={onEdit} size="lg" variant="default">
                        <IconEdit size={20} />
                    </ActionIcon>
                    <ActionIcon size="lg" variant="outline" color="red" onClick={onDelete}>
                        <IconTrash size={20} />
                    </ActionIcon>
                </Group>}
            </Group>
            <Stack>
                <Group>
                    {graphicSettings.averaging ? (
                        <Badge radius="xs" variant="filled">
                            {`${t("Усреднение")}: ${
                                graphicSettings.averaging > 60
                                    ? Math.floor(graphicSettings.averaging / 60)
                                    : graphicSettings.averaging
                            } ${graphicSettings.averaging > 60 ? t("часа") : t("минут")}
                            `}
                        </Badge>
                    ) : (
                        <Badge radius="xs" variant="filled">
                            {t("Без усреднения")}
                        </Badge>
                    )}
                    <Badge radius="xs" variant="filled">
                        {chartData ? dayjs(chartData.StartDt || "").format("DD.MM.YY HH:mm") : ""}
                    </Badge>
                    <Badge radius="xs" variant="filled">
                        {chartData ? dayjs(chartData.EndDt || "").format("DD.MM.YY HH:mm") : ""}
                    </Badge>
                    {!formedData.data.reduce((flag, current, index) => {
                        return flag || current.data.length !== 0;
                    }, false) && (
                        <Badge radius="xs" variant="filled" style={{ backgroundColor: "#868E96" }}>
                            <Center style={{ padding: 5 }}>
                                <Group>
                                    <IconDatabaseOff size={15} />
                                    {t("Данных за этот период нет")}
                                </Group>
                            </Center>
                        </Badge>
                    )}
                </Group>

                {formedData.data.reduce((flag, current, index) => {
                    return flag || current.data.length !== 0;
                }, false) && (
                    <Chart
                        options={{
                            chart: {
                                id: "basic-bar",
                                toolbar: {
                                    tools: {
                                        pan: true,
                                    },
                                },
                            },
                            stroke: {
                                width: 2,
                            },
                            colors:
                                formedData && !OneChannelMode
                                    ? formedData.data.map((x, i) => colors[i % 7])
                                    : formedData && OneChannelMode
                                    ? formedData.data.map(
                                          (x, i) =>
                                              colorsOneChannelMode[
                                                  DevicePhases?.filter((rec) => rec.deviceId === x.deviceId)[0]
                                                      ?.phase || OverheadPowerLineTowersPhaseEnum.A
                                              ]
                                      )
                                    : undefined,
                            xaxis: {
                                type: "datetime",
                                categories: formedData.timestamps,
                                tickAmount: 30,
                                axisBorder: {
                                    show: true,
                                },
                            },
                            yaxis:
                                formedData &&
                                formedData.data.map((x, i) => ({
                                    axisBorder: {
                                        show: true,
                                        color: !OneChannelMode
                                            ? colors[i % 7]
                                            : colorsOneChannelMode[
                                                  DevicePhases?.filter((rec) => rec.deviceId === x.deviceId)[0]
                                                      ?.phase || OverheadPowerLineTowersPhaseEnum.A
                                              ],
                                    },
                                    title: {
                                        text: t(x.name),
                                        style: {
                                            color: !OneChannelMode
                                                ? colors[i % 7]
                                                : colorsOneChannelMode[
                                                      DevicePhases?.filter((rec) => rec.deviceId === x.deviceId)[0]
                                                          ?.phase || OverheadPowerLineTowersPhaseEnum.A
                                                  ],
                                        },
                                    },
                                    labels: {
                                        formatter: function (value) {
                                            return value.toFixed(2); // 2 знака после запятой
                                        },
                                    },
                                    min: Math.min(
                                        ...formedData.data
                                            .filter((d) => d.type === x.type)
                                            .map((d) => Math.min(...d.data))
                                    ),
                                    max: Math.max(
                                        ...formedData.data
                                            .filter((d) => d.type === x.type)
                                            .map((d) => Math.max(...d.data))
                                    ),
                                })),
                        }}
                        series={
                            Array.isArray(formedData && formedData.data)
                                ? formedData.data.map((x) => ({ data: x.data, name: x.name }))
                                : []
                        }
                        type="line"
                        width="100%"
                        height="500"
                    />
                )}
            </Stack>
        </Paper>
    );
};
