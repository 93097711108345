import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";
import { NotificationsDto } from "../types";

export type ReadNotificationCommand = {
    NotificationId: number,
    CompanyId: number,
};

export const readNotification = (command: ReadNotificationCommand): Promise<EntityId<number>> => {
    return axios.put(`/Companies/${command.CompanyId}/notifications/${command.NotificationId}/read`, {NotificationId: command.NotificationId});
};

export const useReadNotification = (notification: NotificationsDto) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries([
                "companyNotifications"+(notification.CompanyId ? notification.CompanyId : "root"),
                "OPLnotifications"+(notification.OverheadPowerLineId ? notification.OverheadPowerLineId : "root"),
                "TowerNotifications"+(notification.OverheadPowerLineTowerId ? notification.OverheadPowerLineTowerId : "root"),
            ]);
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка чтения уведомления"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries([
                "companyNotifications"+(notification.CompanyId ? notification.CompanyId : "root"),
                "OPLnotifications"+(notification.OverheadPowerLineId ? notification.OverheadPowerLineId : "root"),
                "TowerNotifications"+(notification.OverheadPowerLineTowerId ? notification.OverheadPowerLineTowerId : "root"),
            ]);
            showNotification({
                title: t("Успех!"),
                message: t("Уведомление прочитано"),
                autoClose: 5000,
                color: "teal",
            });
        },
        mutationFn: readNotification,
    });
};