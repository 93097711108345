import { Button, Group, Modal, Title } from "@mantine/core"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CompanyT, useCompanies } from "src/features/companies/api/getCompanies"
import { useCompany } from "src/features/companies/api/getCompany"
import CompaniesList from "src/features/companies/components/CompaniesList"
import { useAuth } from "src/lib/auth"
import { useBindOPL } from "../api/bindOPL"

type BindingOPLmodalProps = {
    opened: boolean,
    close: () => void,
    OPLid: number,
}

export const BindingOPLmodal: React.FC<BindingOPLmodalProps> = ({OPLid, opened, close}) => {
    const { t } = useTranslation()

    const { user } = useAuth();
    const [companyFull, setCompany           ] = useState<CompanyT | null>(null     )
    const [path,        setPath              ] = useState<CompanyT[]     >([]     )
    const {data: userCompany} = useCompany(user?.CompanyId || null)

    const BindOPLMutation = useBindOPL({command: {
        OverheadPowerLineId: OPLid,
        CompanyId: companyFull?.Id || -1,
    }})
    
    useEffect(()=>{
        let tmp = JSON.parse(JSON.stringify(path))
        if(companyFull && path.length !== 0 && path[path.length-1] && path[path.length-1].Id === companyFull?.CompanyParentId){
            tmp.push(companyFull)
            setPath(tmp)
        }
        else if(companyFull && path.length !== 0 && path[path.length-1] && path[path.length-1].CompanyParentId === companyFull?.Id){
            tmp.pop()
            setPath(tmp)
        }
        else if(companyFull && path.length !== 0 && path[path.length-1] && path[path.length-1].CompanyParentId === companyFull?.CompanyParentId){
            tmp.pop()
            tmp.push(companyFull)
            setPath(tmp)
        }
        else {
            tmp = []
            tmp.push(companyFull)
            setPath(tmp)
        }
}, [companyFull])

    return(
        <Modal
            title={<Title order={3}>{t("Привязка ВЛ")}</Title>}
            opened={opened}
            onClose={()=>{close();setCompany(null);}}
            size="xl"
        >
            <div className="mt-4">
                <span className="mantine-InputWrapper-label mantine-NumberInput-label mantine-ittua2">{t("Привязка к компании")}</span><br/>
                {companyFull && <span className="font-normal text-sm text-gray-500">{`Компания: ${companyFull?.Name || ""}`}</span>}
                <CompaniesList
                    depth                   = {path.length} 
                    select_company          = {setCompany}
                    current_company         = {companyFull}
                    path                    = {path}
                    setPath                 = {setPath}
                    userCompany             = {userCompany?userCompany:null}
                    hideCollapser
                    fw
                    hideLabel
                    maxHeight               = {"300px"}
                    disableInputMargins
                />
                {companyFull && 
                <Group className="mt-2" position="center">
                    <Button 
                        onClick={()=>{setCompany(null)}} 
                        variant="outline" 
                        color="red" 
                    >
                        {t("Отменить")}
                    </Button>
                    <Button 
                        onClick={()=>{BindOPLMutation.mutateAsync({
                            OverheadPowerLineId: OPLid,
                            CompanyId: companyFull?.Id || -1,
                        })}} 
                    >
                        {t("Привязать")}
                    </Button>
                </Group>}
            </div>
        </Modal>
    )
}