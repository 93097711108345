import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";
import { NotificationsDto } from "../types";

export type ReadAllCompanyNotificationsCommand = {
    CompanyId: number,
};

export const readAllCompanyNotifications = (command: ReadAllCompanyNotificationsCommand): Promise<EntityId<number>> => {
    return axios.put(`/Companies/${command.CompanyId}/notifications/read`, {CompanyId: command.CompanyId});
};

export const useReadAllCompanyNotifications = (CompanyId: number | null) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries([
                "companyNotifications"+(CompanyId || "root"),
            ]);
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка чтения уведомлений"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries([
                "companyNotifications"+(CompanyId || "root"),
            ]);
            showNotification({
                title: t("Успех!"),
                message: t("Все уведомления прочитаны"),
                autoClose: 5000,
                color: "teal",
            });
        },
        mutationFn: readAllCompanyNotifications,
    });
};