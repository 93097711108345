import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { DeviceDto } from "src/features/devices/types";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";

export const getDevice = async (id: number | undefined): Promise<DeviceDto | undefined> => {
    if (id && id > 0) return axios.get(`/devices/${id}`);
};

type QueryFnType = typeof getDevice;

export const useDevice = (id: number | undefined) => {
    const { t } = useTranslation();
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: [`device${id}`],
        queryFn: () => getDevice(id),
        refetchInterval: 30000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка при загрузке устройства"),
            });
        },
    });
};
