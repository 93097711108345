import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { RoleEnum, UserDto } from "src/features/auth";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { useAuth } from "../../../lib/auth";

export const getUsers = (): Promise<UserDto[]> => {
    return axios.get(`/users`);
};

type QueryFnType = typeof getUsers;

type UseUsersOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useUsers = ({ config }: UseUsersOptions = {}) => {
    const { t } = useTranslation();
    const { user } = useAuth();

    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ["users"],
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка при загрузке пользователей"),
            });
        },
        queryFn: () => getUsers(),
        enabled: user?.Role !== RoleEnum.User,
        ...config,
    });
};
