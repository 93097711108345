export enum NotificationTypeEnum {
    CriticalThreshold = 1,
    WarningThreshold = 2,
    InformationThreshold = 3,
    DeviceOffline = 4
}

export type NotificationsDto = {
    Id:	number,
    CompanyId:	number,
    NotificationType:	NotificationTypeEnum,
    ChannelThresholdId:	number,
    OverheadPowerLineId?:	number | null,
    OverheadPowerLineName:	string | null,
    OverheadPowerLineTowerId:	number | null,
    OverheadPowerLineTowerName:	string  | null,
    DeviceId:	number | null,
    DeviceName:	string | null,
    Date:	Date | null,
    UserId:	number | null,
    UserName:	string | null,
    ReadDate:	Date | null,
}