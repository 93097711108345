import { Button, Pagination, Stack } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { UserNotification } from "src/features/UserNotifications/components/Notification";
import { NotificationsDto, NotificationTypeEnum } from "src/features/UserNotifications/types";
import { Logo } from "../EnergyLogo";
import { useDisclosure } from "@mantine/hooks";
import { useAuth } from "src/lib/auth";
import { NoNotificationsMock } from "src/features/UserNotifications/components/NoNotificationsMock";
import dayjs from "dayjs";
import { IconChecks } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useReadAllCompanyNotifications } from "src/features/UserNotifications/api/readAllCompanyNotifications";
import { useUserNotifications } from "src/features/UserNotifications/api/getUserNotifications";

type NotificationsLayoutProps = {
    expanded?: boolean,
}

function useOutsideAlerter(onOutsideClick: () => void) {
    const ref = useRef<HTMLDivElement | null>(null);
  
    useEffect(() => {
        function handleClick(event: { target: any; }) {
            if (ref.current && !(ref.current.contains(event.target))) {
                onOutsideClick();
            }
        }
      
        document.addEventListener('click', handleClick);
      
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [onOutsideClick]);
  
    return ref;
  }

export const NotificationsLayout: React.FC<NotificationsLayoutProps> = ({expanded}) => {

    const [notificationsOpened, {close: closeNotifications, open: openNotifications}] = useDisclosure(false)

    const outsideAlerterRef = useOutsideAlerter(notificationsOpened ? closeNotifications : ()=>{});

    const { user } = useAuth()

    const { data: notificationsList } = useUserNotifications() 

    const [activePage, setPage] = useState(1);

    const { t } = useTranslation()

    const ReadAllMessagesMutations = useReadAllCompanyNotifications(user?.CompanyId ? user?.CompanyId : null)

    const [UnreadNotifications, setUnreadNotifications] = useState<NotificationsDto[]>([])

    useEffect(()=>{
        setUnreadNotifications(notificationsList?.filter(
            notification => (!notification.ReadDate) || (dayjs(notification.ReadDate || '').format("DD.MM.YY HH:mm").length === 0)
        ) || [])
    }, [notificationsList])

    return(

        <div 
            style={{
                position: notificationsOpened ? "absolute" : "static",
                width: notificationsOpened ? "600px" : expanded ? "fit-content" : "44px",
                height: notificationsOpened ? "500px" : expanded ? "fit-content" :"40px",
                borderRadius: "4px",
                boxShadow: notificationsOpened ? "5px 5px 15px #bebebe" : "none",
                backgroundColor: "white",
                zIndex: "2",
                transition: "0.1s linear all",
                display: "flex",
                alignItems: "flex-end",
                padding: notificationsOpened ? "10px" : 0,
                flexDirection: "column",
            }}
            ref={outsideAlerterRef}
        >
            {expanded ? 
                <Logo /> 
            : 
                <Logo 
                    iconOnly 
                    onClick={notificationsOpened ? closeNotifications : openNotifications}
                    notificationsNumber={notificationsOpened ? null : UnreadNotifications.length}
                    style={notificationsOpened ? {
                        position: "absolute",
                        top: 3,
                        left: 3,
                    } : {}}
                    notificationsCounterMarkerColor={(UnreadNotifications||[])
                        .filter(
                            notification => (!notification.ReadDate) || (dayjs(notification.ReadDate || '').format("DD.MM.YY HH:mm").length === 0)
                        ).reduce( (currentLevel: string | undefined, notification) => {
                            let lvl_color = {
                                [NotificationTypeEnum.CriticalThreshold]:       'red',
                                [NotificationTypeEnum.WarningThreshold]:        'yellow',
                                [NotificationTypeEnum.InformationThreshold]:    'green',
                                [NotificationTypeEnum.DeviceOffline]:           'gray'
                            }
                            if (currentLevel){
                                if (currentLevel === 'red')
                                    return 'red'
                                if (currentLevel === 'yellow' && notification.NotificationType === NotificationTypeEnum.CriticalThreshold)
                                    return 'red'
                                if (currentLevel === 'green' && (notification.NotificationType === NotificationTypeEnum.CriticalThreshold || notification.NotificationType === NotificationTypeEnum.WarningThreshold))
                                    return lvl_color[notification.NotificationType]
                                if (currentLevel === 'gray' && notification.NotificationType)
                                    return lvl_color[notification.NotificationType] 
                                return currentLevel   
                            }
                            return lvl_color[notification.NotificationType]
                        }, undefined)
                    }
                />}
            {notificationsOpened ? <>
            <Button rightIcon={<IconChecks/>} onClick={()=>ReadAllMessagesMutations.mutateAsync({CompanyId: user?.CompanyId || 0})} className="mb-2">
                {t("Прочитать все уведомления")}
            </Button>
                
            <Stack style={{
                overflowY: "scroll",
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
            }}>
                {UnreadNotifications && UnreadNotifications.length > 0 ? 
                    UnreadNotifications
                    .sort((a,b)=>
                            b.Id-a.Id
                    )
                    .slice(((activePage - 1) * 10), Math.min(UnreadNotifications.length, (activePage - 1) * 10 + 11)).map(notification => 
                    <UserNotification
                        notification={notification}
                    />
                ) : <NoNotificationsMock/>}
            </Stack>
            <Pagination style={{margin: "auto", marginTop: "10px"}} page={activePage} onChange={setPage} total={
                (Math.floor((UnreadNotifications?.length || 0) / 10) + ((UnreadNotifications?.length || 0) % 10 > 0 ? 1 : 0))
            } /></>
            : ''}
        </div>
    )
}