import { Select, Stack } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChartWrapper from "src/components/Monitoring/Chart"
import { useChartData, useChartDataSeveral } from "src/features/devices/api/getChartData";
import { OverheadPowerLineTowerDevicesDto, OverheadPowerLineTowerDeviceСhannelsDto, OverheadPowerLineTowersPhaseEnum } from "src/features/OPL/types";
import { Chart } from "./Chart";
import { Graphic } from "src/features/graphic/components/Graphic";
import { СhannelDeviceModelEnum } from "src/features/devices/types";
import { useAllChannels } from "src/features/OPL/api/getAllChannels";

type HiddableChartProps = {
    channel:    OverheadPowerLineTowerDeviceСhannelsDto,
    opened:     boolean,
    devices?:   OverheadPowerLineTowerDevicesDto[],
}

export const HiddableChart: React.FC<HiddableChartProps> = ({ channel, opened, devices }) => {

    const [endDt, setEndDt] = useState<Date | undefined | null>(dayjs().toDate());
    const [startDt, setStartDt] = useState<Date | undefined | null>(dayjs().add(-1, "day").toDate());

    const { t } = useTranslation()
    const [averaging, setAveraging] = useState<number>(0);

    const { data: allChannels } = useAllChannels();

    return(
        <Stack 
            style={opened ? 
                {} 
            : 
                {
                    display: "none", 
                    overflow: "hidden",
                }
            }
        >
            <div className="md:space-x-2 md:flex" style={{width: "100%"}}>
                <Select
                    id="veraging-select"
                    value={averaging.toString()}
                    onChange={x=>setAveraging(Number(x))}
                    label={t("Усреднение").toString()}
                    data={[
                        { value: "0", label: t("Без усреднения") },
                        { value: "5", label: t("5 минут") },
                        { value: "10", label: t("10 минут") },
                        { value: "30", label: t("30 минут") },
                        { value: "60", label: t("1 час") },
                        { value: "120", label: t("2 часа") },
                        { value: "240", label: t("4 часа") },
                    ]}
                />
                <DatePicker
                    value={startDt!}
                    maxDate={endDt!}
                    inputFormat="DD.MM.YYYY"
                    onChange={setStartDt}
                    label={t("От").toString()}
                    styles={{
                        weekdayCell: {borderColor: "white !important"},
                        calendarBase: {width: "fit-content !important", justifyContent: "center", gap: 0},
                        cell: {padding: "0 !important", borderColor: "white !important"}
                    }}
                />
                <DatePicker
                    value={endDt!}
                    minDate={startDt!}
                    inputFormat="DD.MM.YYYY"
                    onChange={setEndDt}
                    label={t("До").toString()}
                    styles={{
                        weekdayCell: {borderColor: "white !important"},
                        calendarBase: {width: "fit-content !important", justifyContent: "center", gap: 0},
                        cell: {padding: "0 !important", borderColor: "white !important"}
                    }}
                />
            </div>
            
            {/* {channel.DeviceChannelsDto.map((device_channel, index)=>
                // <Chart
                //     channelsIds={[device_channel.Id]}
                //     deviceId=   {device_channel.DeviceId}
                //     averaging=  {averaging}
                //     startDt=    {startDt}
                //     endDt=      {endDt}
                //     label=      {devices ? (devices[index].Name + ' ' + t("Фаза") + ' ' + (devices[index].OverheadPowerLineTowersPhase ? OverheadPowerLineTowersPhaseEnum[devices[index].OverheadPowerLineTowersPhase] : '?')) : ''}
                // />
                <Graphic
                    OneChannelMode
                    graphicSettings={{
                        Name: "test",
                        targetDevices: [],
                        OPLs: [],
                        Towers: [],
                        Devices: devices?.map(x=>x.Id) || [],
                        Channels: [channel.DeviceModelChannelId],
                        startDt: startDt,
                        endDt: endDt,
                        averaging: averaging,
                    }} 
                    DevicePhases={devices?.map(x=>({deviceId: x.Id, phase: x.OverheadPowerLineTowersPhase}))}
                />
            )} */}
            <Graphic
                OneChannelMode
                graphicSettings={{
                    Name: t(allChannels?.filter(chnl => chnl.Id === channel.DeviceModelChannelId)[0]?.Name),
                    targetDevices: [],
                    OPLs: [],
                    Towers: [],
                    Devices: devices?.map(x=>x.Id) || [],
                    Channels: [channel.DeviceModelChannelId],
                    startDt: startDt,
                    endDt: endDt,
                    averaging: averaging,
                }} 
                DevicePhases={devices?.map(x=>({deviceId: x.Id, phase: x.OverheadPowerLineTowersPhase}))}
            />
        </Stack>
    )
}