import { Drawer, Select } from "@mantine/core"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { СhannelDeviceModelEnum } from "src/features/devices/types"
import { useCreateChannel } from "../api/createChannel"
import { Tower_full } from "../types"
import { IconCheck } from "@tabler/icons-react"
import { Button } from "@mantine/core"
import { useAllChannels } from "../api/getAllChannels"

type CreateOPLChannelDrawerProps = {
    opened: boolean,
    close: ()=>void,
    tower: Tower_full,
}

export const CreateOPLChannelDrawer: React.FC<CreateOPLChannelDrawerProps> = ({opened, close, tower}) => {

    const { t } = useTranslation()

    const [addingChannel, setAddingChannel] = useState<СhannelDeviceModelEnum | null>()

    const createChannelMutation = useCreateChannel({id: tower?.Id})

    const { data: allChannels } = useAllChannels();

    return(
        <Drawer
            opened={opened}
            onClose={()=>{setAddingChannel(null);close()}}
            title   = {t("Добавление канала")}
            padding="xl"
            size="xl"
            styles={{
                drawer: {
                    overflowY: "auto",
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    "&::WebkitScrollbar": { width: 0, }
                },
            }}
            position="right"
        >
            <Select
                placeholder={t("Выберите канал")}
                searchable
                nothingFound={t("Ничего не найдено")}
                maxDropdownHeight={280}
                value={addingChannel ? (addingChannel as number).toString() : null}
                data={
                    allChannels?.map(channel => ({
                        label: t(channel.Name),
                        value: (channel.Id as number).toString()
                    })) || []
                }
                onChange={(e)=>{if (e) setAddingChannel(parseInt(e))}}
            />
            {addingChannel &&
            <Button 
                className="mt-2" 
                fullWidth 
                variant="outline" 
                leftIcon={<IconCheck/>}
                onClick={()=>{
                    if (addingChannel)
                        createChannelMutation.mutateAsync({
                            ChannelId: addingChannel,
                            OverheadPowerLineTowerId: tower.Id,
                        })
                }}
            >
                {t("Добавить")}
            </Button>}
        </Drawer>
    )
}