import { UnstyledButton, Group, ThemeIcon, Text, Transition } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export type MainLinkProps = {
    icon: React.ReactNode;
    label: string;
    to?: string;
    onClick?(): void;
    showLabel?: boolean;
};

export const MainLink: React.FC<MainLinkProps> = ({ icon, label, to, onClick, showLabel = false }) => {
    const { t } = useTranslation();

    return (
        <UnstyledButton
            onClick={onClick}
            // @ts-ignore
            component={to ? Link : "button"}
            to={to!}
            sx={(theme) => ({
                display: "flex",
                width: "100%",
                padding: "8px 17px 8px 17px",
                borderRadius: theme.radius.sm,
                color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.dark[6],

                "&:hover": {
                    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[1],
                },
            })}
        >
            <Group noWrap position={showLabel ? "left" : "center"}>
                <ThemeIcon
                    sx={{
                        backgroundColor: "inherit",
                        color: "inherit",
                    }}
                    variant="light"
                >
                    {icon}
                </ThemeIcon>

                <Transition mounted={showLabel} duration={500} transition="slide-right">
                    {(style) => (
                        <Text style={{ ...style, whiteSpace: "nowrap" }} weight={500} size="sm">
                            {t(label)}
                        </Text>
                    )}
                </Transition>
            </Group>
        </UnstyledButton>
    );
};

MainLink.displayName = "MainLink";
