import { useQuery } from "react-query";
import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { PagedCollection, PagedRequest } from "../../../types";
import { OPL_small } from "src/features/OPL/types";

export interface GetCompanyOPLsQuery extends PagedRequest {
    CompanyId: number;
}

export const getCompanyOPLs = (request: GetCompanyOPLsQuery): Promise<PagedCollection<OPL_small>> => {
    return axios.post(`/Companies/${request.CompanyId}/overheadpowerlines`, request);
};

type QueryFnType = typeof getCompanyOPLs;

type UseCompanyOPLsOptions = {
    request: GetCompanyOPLsQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useCompanyOPLs = ({ request, config }: UseCompanyOPLsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: [`CompanyOPLs/${request.CompanyId}`],
        queryFn: () => getCompanyOPLs(request),
    });
};
