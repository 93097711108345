import { Accordion, ActionIcon, Button, Center, Checkbox, Drawer, Flex, Group, NumberInput, Popover, ScrollArea, SegmentedControl, Select, Stack, Text, Textarea, TextInput } from "@mantine/core"
import styled from "@emotion/styled";
import tw from "twin.macro";
import { useTranslation } from "react-i18next";
import { IconAsterisk, IconEdit, IconLink, IconLinkOff, IconSearch, IconTrash } from "@tabler/icons-react";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { useForm } from "@mantine/form";
import { DatePicker } from "@mantine/dates";

import { CreateOPLCommand, useCreateOPL } from "src/features/OPL/api/createOPL";
import { OPL_small, OverheadLineTypeEnum, OverheadPowerLineTagEnum, Tower_small } from "src/features/OPL/types";
import { useOPLs } from "src/features/OPL/api/getOPLs";
import { CreateOverheadPowerLineTowerCommand, createTower, useCreateTower } from "src/features/OPL/api/createTower";
import { debounce } from "lodash";
import SelectPositionMap from "./MapComponents";
import { useTowers } from "src/features/OPL/api/getTowers";
import Fuse from "fuse.js";
import { useDeleteTower } from "src/features/OPL/api/deleteTower";
import { RoleEnum } from "src/features/auth/types";
import { useAuth } from "src/lib/auth";
import { useDeleteOPL } from "src/features/OPL/api/deleteOPL";
import { useTower } from "src/features/OPL/api/getTower";
import { useOPL } from "src/features/OPL/api/getOPL";
import { updateOPLCommand, useUpdateOPL } from "src/features/OPL/api/updateOPL";
import { updateTowerCommand, useUpdateTower } from "src/features/OPL/api/updateTower";
import { showNotification } from "@mantine/notifications";
import { Device_small, useCompanyDevices } from "../api/getCompanyDevices";
import { CreateDeviceDrawer } from "src/features/devices/components/CreateDeviceDrawer";
import { useDeleteDevice } from "src/features/devices/api/deleteDevice";
import { DBoffMock } from "src/features/OPL/components/DBoffMock";
import { useCompanyOPLs } from "../api/getCompanyOPLs";
import { useCompanyTowers } from "../api/getCompanyTowers";
import CompaniesList from "./CompaniesList";
import { CompanyT, useCompanies } from "../api/getCompanies";
import { useCompany } from "../api/getCompany";
import { ChipGroup } from "@mantine/core/lib/Chip/ChipGroup/ChipGroup";
import { BindingOPLmodal } from "src/features/OPL/components/BindingOPLmodal";
import { useUnbindOPL } from "src/features/OPL/api/unbindOPL";

type ListItemProps = {
    name?: string,
    OPL?: OPL_small,
    tower?: Tower_small,
    device?: Device_small,
    Name?: string,
    selected?: boolean,
    onClick?: () => void,
    onDelete?: () => void,
    edit?: ()=> void,
    binding?: ()=> void,
    unbinding?: {
        companyId: number,
        OPLid:     number,
    },
}

const Card = styled.div((props) => ({
    ...tw`cursor-pointer border-b border-gray-200 bg-white transition-colors hover:bg-gray-100 relative`,
    height: "50px"
}));

const ListItem: React.FC<ListItemProps> = ({device, name, OPL, tower, Name, selected, onClick, onDelete, edit, binding, unbinding}) => {

    const [deleteOpened, setDeleteOpened] = useState(false);
    const [unbindingOpened, setUnbindingOpened] = useState(false);
    const { user } = useAuth();
    const { t } = useTranslation()

    const ListDelition = {
        OPL:
            user?.Role !== RoleEnum.User ? (
                <Popover
                    opened={deleteOpened}
                    onClose={() => setDeleteOpened(false)}
                    position="left-start"
                    transition="pop-top-right"
                >
                    <Popover.Target>
                        <ActionIcon
                            onClick={() => {
                                setDeleteOpened(true);
                            }}
                            size="lg"
                            variant="outline"
                            color="red"
                        >
                            <IconTrash size={20} />
                        </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <Button
                            variant={"subtle"}
                            color="red"
                            onClick={() => {
                                if (onDelete)
                                    onDelete();
                                setDeleteOpened(false)
                            }}
                        >
                            {t("Удалить ВЛ!").toString()}
                        </Button>
                    </Popover.Dropdown>
                </Popover>
            ) : (
                ""
            ),
        Tower:
            user?.Role !== RoleEnum.User ? (
                <Popover
                    opened={deleteOpened}
                    onClose={() => setDeleteOpened(false)}
                    position="left-start"
                    transition="pop-top-right"
                >
                    <Popover.Target>
                        <ActionIcon
                            onClick={() => {
                                setDeleteOpened(true);
                            }}
                            size="lg"
                            variant="outline"
                            color="red"
                        >
                            <IconTrash size={20} />
                        </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <Button
                            variant={"subtle"}
                            color="red"
                            onClick={() => {
                                if (onDelete)
                                    onDelete();
                                setDeleteOpened(false)
                            }}
                        >
                            {t("Удалить опору!").toString()}
                        </Button>
                    </Popover.Dropdown>
                </Popover>
            ) : (
                ""
            ),
        Device:
            user?.Role !== RoleEnum.User ? (
                <Popover
                    opened={deleteOpened}
                    onClose={() => setDeleteOpened(false)}
                    position="left-start"
                    transition="pop-top-right"
                >
                    <Popover.Target>
                        <ActionIcon
                            onClick={() => {
                                setDeleteOpened(true);
                            }}
                            size="lg"
                            variant="outline"
                            color="red"
                        >
                            <IconTrash size={20} />
                        </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <Button
                            variant={"subtle"}
                            color="red"
                            onClick={() => {
                                if (onDelete)
                                    onDelete();
                                setDeleteOpened(false)
                            }}
                        >
                            {t("Удалить устройство!").toString()}
                        </Button>
                    </Popover.Dropdown>
                </Popover>
            ) : (
                ""
            ),
    }

    const unbindingMutation = useUnbindOPL({command: {
        OverheadPowerLineId: unbinding?.OPLid || -1,
        CompanyId: unbinding?.companyId || -1,
    }})

    const ListInfo = {
        OPL:
            <Flex align="center" justify="space-between" style={{height: "100%", padding: "0 15px"}}>
                <Text>{OPL?.Name}</Text>
                <Group>
                    {ListDelition.OPL}
                    {user?.Role !== RoleEnum.User ? (<>
                        <Popover
                            opened={unbindingOpened}
                            onClose={() => setUnbindingOpened(false)}
                            position="left-start"
                            transition="pop-top-right"
                        >
                            <Popover.Target>
                                <ActionIcon color="red" onClick={()=>setUnbindingOpened(true)} size="lg" variant="outline">
                                    <IconLinkOff color="red" size={20} />
                                </ActionIcon>
                            </Popover.Target>

                            <Popover.Dropdown>
                                <Button
                                    variant={"subtle"}
                                    color="red"
                                    onClick={() => {
                                        if (unbinding && unbinding.OPLid && unbinding.companyId)
                                            unbindingMutation.mutateAsync({
                                                OverheadPowerLineId: unbinding?.OPLid || -1,
                                                CompanyId: unbinding?.companyId || -1,
                                            });
                                        setUnbindingOpened(false)
                                    }}
                                >
                                    {t("Отвязать ВЛ!").toString()}
                                </Button>
                            </Popover.Dropdown>
                        </Popover>

                        <ActionIcon onClick={binding} size="lg" variant="default">
                            <IconLink size={20} />
                        </ActionIcon>
                    
                        <ActionIcon onClick={edit} size="lg" variant="default">
                            <IconEdit size={20} />
                        </ActionIcon>
                    </>) : null}
                </Group>
            </Flex>,
        Tower:
            <Flex align="center" justify="space-between" style={{height: "100%", padding: "0 15px"}}>
                <Text>{tower?.Name}</Text>
                <Group>
                    {ListDelition.Tower}
                    {user?.Role !== RoleEnum.User ? (
                        <ActionIcon onClick={edit} size="lg" variant="default">
                            <IconEdit size={20} />
                        </ActionIcon>
                    ) : null}
                </Group>
            </Flex>,
        Device:
        <Flex align="center" justify="space-between" style={{height: "100%", padding: "0 15px"}}>
            <Text>{device?.Name}</Text>
            <Group>
                {ListDelition.Device}
                {edit ? user?.Role !== RoleEnum.User ? (
                    <ActionIcon onClick={edit} size="lg" variant="default">
                        <IconEdit size={20} />
                    </ActionIcon>
                ) : null :''}
            </Group>
        </Flex>,
        Universal:
            <Flex align="center" style={{height: "100%", padding: "0 15px"}}>
                <div
                    style={{
                        width: "5px",
                        height: "40px",
                        borderRadius: "0 5px 5px 0",
                        backgroundColor: selected ? '#CED4DA' : "inherit",
                        position: "absolute",
                        left: 0,
                    }}
                ></div>
                <Text>{Name}</Text>
            </Flex>
    }

    if (name)
        return(
            <Card
                onClick={onClick}
            >
                <Flex align="center" style={{height: "100%", padding: "0 15px"}}>
                    <Text>{name}</Text>
                </Flex>
            </Card>
        )
    else if (OPL){
        return(
            <Card
                onClick={onClick}
            >
                {ListInfo.OPL}
            </Card>
        )
    }
    else if (tower){
        return(
            <Card
                onClick={onClick}
            >
                {ListInfo.Tower}
            </Card>
        )
    }
    else if (device){
        return(
            <Card
                onClick={onClick}
            >
                {ListInfo.Device}
            </Card>
        )
    }
    else if (Name){
        return(
            <Card
                onClick={onClick}
            >
                {ListInfo.Universal}
            </Card>
        )
    }
    else
        return(
            <></>
        )
}

type SimpleSearchListProps = {
    items: any[],
    setValue: (x: number) => void
    chosenId: number | null,
}

export const SimpleSearchList: React.FC<SimpleSearchListProps> = ({items, setValue, chosenId}) => {

    const { t } = useTranslation()

    const [filter, setFilter] = useState("")

    const fuse = new Fuse(items ?? [], {
        keys: ["Name"],
    });

    return(<>
        <TextInput
            className="mt-2"
            placeholder={t("Поиск")}
            icon={<IconSearch/>}
            value={filter}
            onChange={event=>setFilter(event.target.value)}
            styles={{
                input: {
                    fontSize: "20px",
                    borderRadius: "4px 4px 0 0"
                },
                root: {
                    flexGrow: 2,
                }
            }}
        /> 
        <ScrollArea style={{ height: 250, border: "1px solid rgb(206, 212, 218)", borderRadius: "0 0 4px 4px" }}>
            <Stack style={{gap: 0}}>                
                {filter ?
                    fuse.search(filter).map(x=><ListItem Name={x.item.Name} selected={x.item.Id === chosenId} onClick={()=>{setValue(x.item.Id)}}/>)
                :
                    items.map(x=><ListItem Name={x.Name} selected={x.Id === chosenId} onClick={()=>{setValue(x.Id)}}/>)
                }
            </Stack>
        </ScrollArea>
    </>)
}

type formProps = {
    close:      () => void,
    company:    number,
    id?:        number,
    editing?:   boolean,
    forcingOPL?:number,
}

const OPform: React.FC<formProps> = ({close, company, id, editing}) => {

    const { t } = useTranslation()

    const createOPLMutation = useCreateOPL();
    const updateOPLMutation = useUpdateOPL(id);

    function handleClose() {
        close();
        form.reset();
    }

    const { data: towers } = useTowers()

    const { data: OPLs } = useOPLs()

    const { data: current } = useOPL(id) 

    const form = useForm<CreateOPLCommand | updateOPLCommand>(
        !editing ?
            {
                initialValues: {
                    Name: "",
                    Comment: "",
                    FirstOverheadPowerLineTowerId: null,
                    LastOverheadPowerLineTowerId: null,
                    OverheadPowerLineTag: OverheadPowerLineTagEnum.Begin,
                    OverhedLineType: OverheadLineTypeEnum.OverheadPowerLine,
                    ParentOverheadPowerLineId: null,
                    CompanyId: company,
                }
            }
        :
            {
                initialValues: {
                    Id: current?.Id || 0,
                    Name: current?.Name || '',
                    Comment: current?.Comment || "",
                    FirstOverheadPowerLineTowerId: current?.FirstOverheadPowerLineTowerId || null,
                    LastOverheadPowerLineTowerId: current?.LastOverheadPowerLineTowerId || null,
                    OverheadPowerLineTag: current?.OverheadPowerLineTag || OverheadPowerLineTagEnum.Begin,
                    OverhedLineType: current?.OverhedLineType || OverheadLineTypeEnum.OverheadPowerLine,
                    ParentOverheadPowerLineId: current?.ParentOverheadPowerLineId || null,
                }
            }
    );

    useEffect(()=>{
        if (editing)
            form.setValues({
                Id: current?.Id || 0,
                Name: current?.Name || '',
                Comment: current?.Comment || "",
                FirstOverheadPowerLineTowerId: current?.FirstOverheadPowerLineTowerId || null,
                LastOverheadPowerLineTowerId: current?.LastOverheadPowerLineTowerId || null,
                OverheadPowerLineTag: current?.OverheadPowerLineTag || OverheadPowerLineTagEnum.Begin,
                OverhedLineType: current?.OverhedLineType || OverheadLineTypeEnum.OverheadPowerLine,
                ParentOverheadPowerLineId: current?.ParentOverheadPowerLineId || null,
                CompanyId: company,
            })
    }, [current])

    return(
        <form
            onSubmit={form.onSubmit(async (values) => {
                if (!editing)
                    await createOPLMutation.mutateAsync(values as CreateOPLCommand);
                else
                    await updateOPLMutation.mutateAsync({Id: id as unknown as number, ...values});
                handleClose();
            })}
        >
            <Stack className="mb-4" mt="md">
                <Stack spacing={0}>
                    <div className="mt-4">
                        <div style={{display: "flex", flexDirection: "row"}}>
                            {t('Название ВЛ')}
                            <div style={{display: "flex", flexDirection: "column", alignContent: "flex-start", width: "fit-content"}}>
                                <IconAsterisk size={6} color="red"/>
                            </div>
                        </div>
                        <TextInput 
                            placeholder={t("Название")} 
                            className="mt-2"
                            value={form.values.Name?.toString()}
                            onChange={(event) => {
                                form.setFieldValue("Name", event.target.value);
                            }}
                            withAsterisk
                        />
                    </div>
                    <div className="mt-4">
                        <span>
                            {t('Комментарий')}
                        </span>
                        <Textarea 
                            placeholder={t("Комментарий")} 
                            className="mt-2"
                            value={form.values.Comment?.toString()}
                            onChange={(event) => {
                                form.setFieldValue("Comment", event.target.value);
                            }}
                        />
                    </div>
                    <div className="mt-4">
                        <span>
                            {t('Начальная опора')}
                        </span>
                        <Select
                            placeholder={t("Выберите опору")}
                            searchable
                            nothingFound={t("Ничего не найдено")}
                            maxDropdownHeight={280}
                            value={form.values.FirstOverheadPowerLineTowerId?.toString()}
                            data={
                                towers?.map(x=>({label: x.Name, value: x.Id.toString()})) || []
                            }
                            onChange={(e)=>{if (e) form.setFieldValue("FirstOverheadPowerLineTowerId", parseInt(e))}}
                        />
                    </div>
                    <div className="mt-4">
                        <span>
                            {t('Конечная опора')}
                        </span>
                        <Select
                            placeholder={t("Выберите опору")}
                            searchable
                            nothingFound={t("Ничего не найдено")}
                            maxDropdownHeight={280}
                            value={form.values.LastOverheadPowerLineTowerId?.toString()}
                            data={
                                towers?.map(x=>({label: x.Name, value: x.Id.toString()})) || []
                            }
                            onChange={(e)=>{if (e) form.setFieldValue("LastOverheadPowerLineTowerId", parseInt(e))}}
                        />
                    </div>
                    <div className="mt-4">
                        <span>
                            {t('Тип питания')}
                        </span><br/>
                        <SegmentedControl
                            className="mt-2"
                            styles={{root: {width: "100%", boxSizing: "border-box"}}}
                            value={
                                form.values.OverheadPowerLineTag === OverheadPowerLineTagEnum.Begin ? 
                                    'Begin'
                                :
                                form.values.OverheadPowerLineTag === OverheadPowerLineTagEnum.Double ? 
                                    'Double'
                                :
                                    'End'
                            }
                            onChange={(event) => {
                                form.setFieldValue("OverheadPowerLineTag", 
                                    event === 'Begin' ? 
                                        OverheadPowerLineTagEnum.Begin
                                    :
                                    event === 'Double' ? 
                                        OverheadPowerLineTagEnum.Double
                                    :
                                        OverheadPowerLineTagEnum.End
                                );
                            }}
                            data={[
                                { label: t("С начала"),         value: 'Begin' },
                                { label: t("Двухстороннее"),    value: 'Double' },
                                { label: t("С конца"),          value: 'End' },
                            ]}
                        />
                    </div>
                    <div className="mt-4">
                        <div style={{display: "flex", flexDirection: "row"}}>
                            {t('Отпайка')}
                            <div style={{display: "flex", flexDirection: "column", alignContent: "flex-start", width: "fit-content"}}>
                                <IconAsterisk size={6} color="red"/>
                            </div>
                        </div>
                        <Checkbox 
                            className="mt-2" 
                            label={t("Отпайка")}
                            checked={form.values.OverhedLineType === OverheadLineTypeEnum.Decoupling}
                            onChange={event=>form.setFieldValue('OverhedLineType', form.values.OverhedLineType === OverheadLineTypeEnum.Decoupling ? OverheadLineTypeEnum.OverheadPowerLine: OverheadLineTypeEnum.Decoupling)}
                        ></Checkbox>
                    </div>
                    { form.values.OverhedLineType === OverheadLineTypeEnum.Decoupling ?
                        <div className="mt-4">
                            <div style={{display: "flex", flexDirection: "row"}}>
                                {t('Родительская ВЛ')}
                                <div style={{display: "flex", flexDirection: "column", alignContent: "flex-start", width: "fit-content"}}>
                                    <IconAsterisk size={6} color="red"/>
                                </div>
                            </div>
                            <Select
                                placeholder={t("Выберите ВЛ")}
                                searchable
                                nothingFound={t("Ничего не найдено")}
                                maxDropdownHeight={280}
                                value={form.values.ParentOverheadPowerLineId?.toString()}
                                data={
                                    OPLs?.map(x=>({label: x.Name, value: x.Id.toString()})) || []
                                }
                                onChange={(e)=>{if (e) form.setFieldValue("ParentOverheadPowerLineId", parseInt(e))}}
                            />
                        </div>
                    : ''}

                    <div className="mt-4" style={{width: "100%"}}>
                        <Button type="submit" style={{width: "100%"}}>{t("Сохранить")}</Button>
                    </div>
                </Stack>
                
            </Stack>
        </form>
    )
}

export const Towerform: React.FC<formProps> = ({close, company, id, editing, forcingOPL}) => {

    const { t } = useTranslation()

    const createTowerMutation = useCreateTower();

    const { data: towers } = useTowers()

    const { data: current } = useTower(id)

    const updateTowerMutation = useUpdateTower(id)

    function handleClose() {
        close();
        form.reset();
    }

    const [coordinatesLocal, setCoordinatesLocal] = useState<[number | null | undefined, number | null | undefined]>([
        null,
        null,
    ]);

    const form = useForm<CreateOverheadPowerLineTowerCommand | updateTowerCommand>(
        !editing ?
        {
            initialValues: {
                Name: null,
                TowerId: 0,
                Comment: null,
                OverheadPowerLineId: forcingOPL ? forcingOPL : null,
                Latitude: null,
                Longitude: null,
                AutoTimeZoneDetection: false,
                AutoTimeZoneSeasonChanging: false,
                SummerTimeZoneChanging: null,
                WinterTimeZoneChanging: null,
                TimeZone: 0,
                CompanyId: company,
            }
        }
        :
        {
            initialValues: {
                Id: id ? id : 0,
                Name: current?.Name || null,
                TowerId: current?.TowerId || null,
                Comment: current?.Comment || null,
                Latitude: current?.Latitude || null,
                Longitude: current?.Longitude || null,
                AutoTimeZoneDetection: current?.AutoTimeZoneDetection || false,
                AutoTimeZoneSeasonChanging: current?.AutoTimeZoneSeasonChanging || false,
                SummerTimeZoneChanging: current?.SummerTimeZoneChanging || null,
                WinterTimeZoneChanging: current?.WinterTimeZoneChanging || null,
                TimeZone: current?.TimeZone || null,
                CompanyId: current?.CompanyId || 0,
                OverheadPowerLineId: current?.OverheadPowerLineId ? current?.OverheadPowerLineId : null,
            }
        }
    );

    useEffect(()=>{
        form.setValues(
            !editing ? {
                Name: null,
                TowerId: 0,
                Comment: null,
                OverheadPowerLineId: null,
                Latitude: null,
                Longitude: null,
                AutoTimeZoneDetection: false,
                AutoTimeZoneSeasonChanging: false,
                SummerTimeZoneChanging: null,
                WinterTimeZoneChanging: null,
                TimeZone: 0,
                CompanyId: company,
            }
            :
            {
                Id: id ? id : 0,
                Name: current?.Name || null,
                TowerId: current?.TowerId || 0,
                Comment: current?.Comment || null,
                Latitude: current?.Latitude || null,
                Longitude: current?.Longitude || null,
                AutoTimeZoneDetection: current?.AutoTimeZoneDetection || false,
                AutoTimeZoneSeasonChanging: current?.AutoTimeZoneSeasonChanging || false,
                SummerTimeZoneChanging: current?.SummerTimeZoneChanging || null,
                WinterTimeZoneChanging: current?.WinterTimeZoneChanging || null,
                TimeZone: current?.TimeZone || null,
                CompanyId: current?.CompanyId || 0,
                OverheadPowerLineId: current?.OverheadPowerLineId ? current?.OverheadPowerLineId : null,
            }
        )

        setCoordinatesLocal([current?.Latitude, current?.Longitude])
    }, [current])

    useEffect(()=>{
        if (forcingOPL) 
            form.setFieldValue("OverheadPowerLineId", forcingOPL)
    }, [forcingOPL])

    const {data: OPLs} = useOPLs()

    const { user } = useAuth();
    const [companyFull, setCompany           ] = useState<CompanyT | null>(null     )
    const [path,        setPath              ] = useState<CompanyT[]     >([]     )
    const {data: userCompany} = useCompany(user?.CompanyId || null)
    const { data: companies } = useCompanies(user?.CompanyId || -1)

    useEffect(()=>{
        let tmp = JSON.parse(JSON.stringify(path))
        if(companyFull && path.length !== 0 && path[path.length-1] && path[path.length-1].Id === companyFull?.CompanyParentId){
            tmp.push(companyFull)
            setPath(tmp)
        }
        else if(companyFull && path.length !== 0 && path[path.length-1] && path[path.length-1].CompanyParentId === companyFull?.Id){
            tmp.pop()
            setPath(tmp)
        }
        else if(companyFull && path.length !== 0 && path[path.length-1] && path[path.length-1].CompanyParentId === companyFull?.CompanyParentId){
            tmp.pop()
            tmp.push(companyFull)
            setPath(tmp)
        }
        else {
            tmp = []
            tmp.push(companyFull)
            setPath(tmp)
        }
    }, [companyFull])

    useEffect(()=>{
        if (companyFull)
            form.setFieldValue("CompanyId", companyFull.Id)
    }, [companyFull])

    return(
        <form
            onSubmit={form.onSubmit(async (values) => {
                // if (form.values.Latitude && form.values.Longitude){
                    if (!editing)
                        await createTowerMutation.mutateAsync(values as CreateOverheadPowerLineTowerCommand);
                    else
                        await updateTowerMutation.mutateAsync(values as updateTowerCommand)
                // }
                // else
                //     showNotification({
                //         title: t("Ошибка!"),
                //         message: t("Необходимо указать расположение опоры"),
                //         autoClose: 5000,
                //         color: "red",
                //     });
            handleClose();
        })}
        >
            {/* <div style={{width: "200px", wordBreak: "break-all"}}>
            {JSON.stringify(form.values)}
            </div> */}
            <Stack className="mb-4" mt="md">
                <Stack spacing={0}>
                    <div className="mt-4">
                        <div style={{display: "flex", flexDirection: "row"}}>
                            {t('Название опоры')}
                            <div style={{display: "flex", flexDirection: "column", alignContent: "flex-start", width: "fit-content"}}>
                                <IconAsterisk size={6} color="red"/>
                            </div>
                        </div>
                        <TextInput 
                            placeholder={t("Название")} 
                            className="mt-2"
                            value={form.values.Name ? form.values.Name.toString() : ''}
                            onChange={(event) => {
                                form.setFieldValue("Name", event.target.value);
                            }}
                        />
                    </div>
                    <div className="mt-4">
                        <span>
                            {t('Комментарий')}
                        </span>
                        <Textarea 
                            placeholder={t("Комментарий")} 
                            className="mt-2"
                            value={form.values.Comment ? form.values.Comment.toString() : ''}
                            onChange={(event) => {
                                form.setFieldValue("Comment", event.target.value);
                            }}
                        />
                    </div>
                    <div className="mt-4">
                        <span>
                            {t('ВЛ')}
                        </span>
                        {forcingOPL ? <><br/><span className="font-bold">{OPLs?.filter(x=>x.Id === forcingOPL)[0].Name}</span></> :
                            // <SimpleSearchList
                            //     items={OPLs ? OPLs : []}
                            //     setValue={x => form.setFieldValue("OverheadPowerLineId", x)}
                            //     chosenId={form.values.OverheadPowerLineId}
                            // />
                            <Select
                                placeholder={t("Выберите ВЛ")}
                                searchable
                                nothingFound={t("Ничего не найдено")}
                                maxDropdownHeight={280}
                                value={form.values.OverheadPowerLineId?.toString()}
                                data={
                                    OPLs?.map(x=>({label: x.Name, value: x.Id.toString()})) || []
                                }
                                onChange={(e)=>{if (e) form.setFieldValue("OverheadPowerLineId", parseInt(e))}}
                            />
                        }
                    </div>
                    <div className="mt-4">
                        <span>
                            {t('Номер внутри ВЛ')}
                        </span>
                        <NumberInput
                            placeholder={t("Номер влутри ВЛ")}
                            className="mt-2"
                            value={form.values.TowerId ? form.values.TowerId : 0}
                            onChange={(event)=>{
                                form.setFieldValue("TowerId", event ? event : null)
                            }}
                        />
                    </div>

                    <div className="mt-4">
                        <SelectPositionMap
                            object={{
                                Name: form.values.Name ? form.values.Name : '' ,
                                Comment: form.values.Comment ? form.values.Comment : '',
                                Id: form.values.TowerId ? form.values.TowerId : 0,
                                Latitude: coordinatesLocal[0] ? coordinatesLocal[0] : 0,
                                Longitude: coordinatesLocal[1] ? coordinatesLocal[1] : 0,
                            }}
                            onChange={debounce((value) => {
                                form.setFieldValue("Latitude", value[0]);
                                form.setFieldValue("Longitude", value[1]);
                            }, 300)}
                            onClick={(value: SetStateAction<[number | null | undefined, number | null | undefined]>) => {
                                setCoordinatesLocal(value);
                            }}
                        />
                        <div className="mt-2">
                            <span>
                                {t('Широта')}
                            </span>
                        </div>
                        <TextInput 
                            className="mt-2" 
                            placeholder={t('Широта')}
                            value={form.values.Latitude ? form.values.Latitude.toString() : ''}
                            onChange={(event) => {
                                form.setFieldValue("Latitude", parseFloat(event.target.value));
                            }}
                        />
                        <div className="mt-2">
                            <span>
                                {t('Долгота')}
                            </span>
                        </div>
                        <TextInput 
                            className="mt-2" 
                            placeholder={t('Долгота')}
                            value={form.values.Longitude ? form.values.Longitude.toString() : ''}
                            onChange={(event) => {
                                form.setFieldValue("Longitude", parseFloat(event.target.value));
                            }}
                        />
                    </div>

                    <div className="mt-4">
                        <div style={{display: "flex", flexDirection: "row"}}>
                            {t('Автоматическое определение временной зоны')}
                            <div style={{display: "flex", flexDirection: "column", alignContent: "flex-start", width: "fit-content"}}>
                                <IconAsterisk size={6} color="red"/>
                            </div>
                        </div>
                        <Checkbox 
                            className="mt-2" 
                            label={t('Автоматическое определение временной зоны')}
                            disabled={!form.values.Latitude || !form.values.Longitude}
                            checked={form.values.AutoTimeZoneDetection}
                            onChange={(event) => {
                                form.setFieldValue("AutoTimeZoneDetection", !form.values.AutoTimeZoneDetection);
                            }}
                        />
                    </div>
                    {!form.values.AutoTimeZoneDetection ?
                    <div className="mt-4">
                        <span>
                            {t('Часовой пояс')}
                        </span>
                        <NumberInput
                            className="mt-2" 
                            placeholder={t('Часовой пояс')}
                            defaultValue={0}
                            value={form.values.TimeZone ? form.values.TimeZone : 0}
                            onChange={(event) => {
                                form.setFieldValue("TimeZone", event ? event : null);
                            }}
                        />
                    </div>
                    :''}

                    <div className="mt-4">
                        <div style={{display: "flex", flexDirection: "row"}}>
                            {t('Изменение часового времени на летнее')}
                            <div style={{display: "flex", flexDirection: "column", alignContent: "flex-start", width: "fit-content"}}>
                                <IconAsterisk size={6} color="red"/>
                            </div>
                        </div>
                        <Checkbox 
                            className="mt-2" 
                            label={t('Изменение часового времени на летнее')}
                            checked={form.values.AutoTimeZoneSeasonChanging}
                            onChange={(event) => {
                                form.setFieldValue("AutoTimeZoneSeasonChanging", !form.values.AutoTimeZoneSeasonChanging);
                            }}
                        />
                    </div>
                    
                    {form.values.AutoTimeZoneSeasonChanging ?
                    <>
                        <div className="mt-4">
                            <span>
                                {t('Дата перехода на летнее время')}
                            </span>
                            <DatePicker 
                                className="mt-2" 
                                placeholder={t('Дата перехода на летнее время')}
                                value={form.values.SummerTimeZoneChanging}
                                onChange={(event) => {
                                    form.setFieldValue("SummerTimeZoneChanging", event);
                                }}
                            />
                        </div>

                        <div className="mt-4">
                            <span>
                                {t('Дата перехода на зимнее время')}
                            </span>
                            <DatePicker
                                className="mt-2" 
                                placeholder={t('Дата перехода на зимнее время')}
                                value={form.values.WinterTimeZoneChanging}
                                onChange={(event) => {
                                    form.setFieldValue("WinterTimeZoneChanging", event);
                                }}
                            />
                        </div>
                    </>
                    :''}

                    {/* <div className="mt-4">
                        <span>
                            {t('Тип')}
                        </span><br/>
                        <SegmentedControl
                            className="mt-2"
                            styles={{root: {width: "100%", boxSizing: "border-box"}}}
                            data={[
                                { label: t("Портал"),   value: 'Begin' },
                                { label: t("Опора"),        value: 'End' },
                            ]}
                        />
                    </div> */}

                    <div className="mt-4">
                        <span className="mantine-InputWrapper-label mantine-NumberInput-label mantine-ittua2">{t("Привязка к компании")}</span><br/>
                        {/* <span className="font-normal text-sm text-gray-500">{`Компания: ${form.values.CompanyId || "-"}`}</span> */}
                        <CompaniesList
                            depth                   = {path.length} 
                            select_company          = {setCompany}
                            current_company         = {companyFull}
                            path                    = {path}
                            setPath                 = {setPath}
                            userCompany             = {userCompany?userCompany:null}
                            hideCollapser
                            fw
                            hideLabel
                            maxHeight               = {"300px"}
                            disableInputMargins
                        />
                        {companyFull && <Button onClick={()=>{setCompany(companies?.filter(company => company.Id === (current?.CompanyId) || null)[0] || null)}} variant="outline" color="red" style={{width: "100%"}}>{t("Отменить")}</Button>}
                    </div>
                    
                    <div className="mt-4" style={{width: "100%"}}>
                        <Button type="submit" style={{width: "100%"}}>{t("Сохранить")}</Button>
                    </div>
                </Stack>
                
            </Stack>
        </form>
    )
}

type CreationDrawerFormProps = {
    close:          ()=>void,
    company:        number,
    editing?:       boolean,
    tab?:           string,
    id?:            number,
    forcingTab?:    string,
}

export const CreationDrawerForm: React.FC<CreationDrawerFormProps> = ({close, company, editing, tab, id, forcingTab}) => {
    
    const { t } = useTranslation()

    function handleClose() {
        close();
        form.reset();
    }

    const form = useForm({
        initialValues: {
        }
    });

    const [type, setType] = useState('OP')

    useEffect(()=>{
        if (forcingTab)
            setType(forcingTab)
    }, [forcingTab])
    
    return(
        <>
            {!editing && !forcingTab ? 
                <SegmentedControl
                    data={
                        !editing ? [
                            { label: t("Устройство"),   value: 'device' },
                            { label: t("Опора"),        value: 'OPtower' },
                            { label: t("ВЛ"),           value: 'OP' },
                        ]
                        : [
                            { label: t("Опора"),        value: 'OPtower' },
                            { label: t("ВЛ"),           value: 'OP' },
                        ]}
                    value={type}
                    onChange={setType}
                />
            :''}
            { !editing && type === 'OP' ? 
                <OPform close={close} company={company}/>
            : !editing && type === 'OPtower' ?
                <Towerform close={close} company={company}/>
            : !editing && type === 'device' ?
                <CreateDeviceDrawer
                    onComplete={close}
                    company={company}
                />
            : ''
            }
            { editing && tab === 'OP' ? 
                <OPform 
                    close   = {close} 
                    company = {company} 
                    id      = {id}
                    editing = {editing}
                />
            : editing && tab === 'OPtower' ?
                <Towerform 
                    close   = {close} 
                    company = {company} 
                    id      = {id}
                    editing = {editing}
                />
            : editing && tab === 'device' ?
                <CreateDeviceDrawer
                    editing
                    id = {id}
                    onComplete={close}
                    company={company}
                />
            : ''
            }
        </>
    )
}

type CreationDrawerProps = {
    opened:     boolean,
    close:      ()=>void,
    company?:    number,
    editing?:   boolean,
    tab?:       string,
    id?:        number,
}

export const CreationDrawer: React.FC<CreationDrawerProps> = ({opened, close, company, editing, tab, id}) => {
    
    const { t } = useTranslation()
    
    return(
        <Drawer
            opened  = {opened}
            onClose = {close}
            title   = {t(editing ? "Изменение" : "Добавление")}
            padding="xl"
            size="xl"
            styles={{
                drawer: {
                    overflowY: "auto",
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    "&::WebkitScrollbar": { width: 0, }
                },
            }}
            position="right"
        >
            <CreationDrawerForm
                close   = {close}
                company = {company ? company : 0}
                editing = {editing}
                tab     = {tab}
                id      = {id}
            />
        </Drawer>
    )
}

type OPprops = {
    company: number
}

const OP: React.FC<OPprops> = ({company}) => {

    const { t } = useTranslation()

    const [activePageDevices, setPageDevices] = useState(1);
    const [activePageOPLs, setPageOPLs] = useState(1);
    const [activePageTowers, setPageTowers] = useState(1);

    const { data: devices } = useCompanyDevices({
        request: {
            CompanyId: company,
            Skip: (activePageDevices - 1) * 100,
            Take: 100,
        },
        config: {
            keepPreviousData: true,
        },
    });

    const { data: OPs      } = useCompanyOPLs({
        request: {
            CompanyId: company,
            Skip: (activePageOPLs - 1) * 100,
            Take: 100,
        },
        config: {
            keepPreviousData: true,
        },
    })
    const { data: OPtowers } = useCompanyTowers({
        request: {
            CompanyId: company,
            Skip: (activePageTowers - 1) * 100,
            Take: 100,
        },
        config: {
            keepPreviousData: true,
        },
    })

    const [ creationDrawerOpened, setCreationDrawerOpened ] = useState(false)

    const [filter, setFilter] = useState("")

    const fuseOP = new Fuse(OPs?.Page ?? [], {
        keys: ["Name"],
    });

    const fuseOPTowers = new Fuse(OPtowers?.Page ?? [], {
        keys: ["Name"],
    });

    const fuseDevices = new Fuse(devices?.Page ?? [], {
        keys: ["Name"],
    })

    const deleteDeviceMutation = useDeleteDevice({CompanyId: company})

    const deleteOPMutation = useDeleteOPL()
    const deleteTowerMutation = useDeleteTower()

    const [ editing,    setEditing      ]   = useState(false)
    const [ tabEditing, setTabEditing   ]   = useState('')
    const [ editingId,  setEditingId    ]   = useState<number | undefined>()
    
    const [ bindingOPLid, setBindingOPLid ] = useState<number | undefined>(undefined)

    const [ accValue,   setAccValue     ]   = useState<string | null>('')

    return(
        <div style={{overflowY: "hidden", height: "100%"}}>
            <div style={{overflowY: "scroll", height: "100%"}}>
                <Accordion 
                    value={accValue} 
                    onChange={(x)=>setAccValue(x)} 
                >
                    <Flex gap={20}>
                        <TextInput
                            placeholder={t("Поиск")}
                            icon={<IconSearch/>}
                            value={filter}
                            onChange={event=>setFilter(event.target.value)}
                            styles={{
                                input: {
                                    fontSize: "20px"
                                },
                                root: {
                                    flexGrow: 2
                                }
                            }}
                        /> 
                        
                        <Button
                            variant="default"
                            styles={{
                                leftIcon:{
                                    margin: 0
                                },
                                root: {
                                    height: "36px",
                                    width: "36px",
                                    flexGrow: 1,
                                    backgroundColor: "#000",
                                    color: "white",
                                    fontWeight: 600,
                                    padding: "5px 10px",
                                    "&:hover": {
                                        backgroundColor: "#111",
                                    }
                                }
                            }}
                            onClick={()=>setCreationDrawerOpened(true)}
                        >
                            {t("Добавить")}
                        </Button>
                    </Flex>
                      
                    <Accordion.Item value="devices">
                        <Accordion.Control>
                            {t("Устройства")}
                        </Accordion.Control>

                        <Accordion.Panel>
                            <Stack style={{gap: 0}}>
                                {filter ?
                                    fuseDevices && fuseDevices.search(filter).length ? fuseDevices.search(filter).map(x=>
                                        <ListItem
                                            device={x.item}
                                            onDelete={()=>deleteDeviceMutation.mutateAsync(x.item.Id)}
                                            edit={()=>{
                                                setCreationDrawerOpened(true)
                                                setEditing(true);
                                                setTabEditing('device');
                                                setEditingId(x.item.Id);
                                            }}
                                        />
                                    ) : <DBoffMock label={t("Нет устройств")}/>
                                : 
                                    devices && devices.Page.length ? devices.Page.map((x, i)=>
                                        <ListItem 
                                            device={x}
                                            onDelete={()=>deleteDeviceMutation.mutateAsync(x.Id)}
                                            edit={()=>{
                                                setCreationDrawerOpened(true)
                                                setEditing(true);
                                                setTabEditing('device');
                                                setEditingId(x.Id);
                                            }}
                                        />) : <DBoffMock label={t("Нет устройств")}/>
                                    }
                            </Stack>
                        </Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="OPtower">
                        <Accordion.Control>
                            {t("Опоры")}
                        </Accordion.Control>
                        
                        <Accordion.Panel>
                            <Stack style={{gap: 0}}>
                            {filter ?
                                fuseOPTowers && fuseOPTowers.search(filter).length ? fuseOPTowers.search(filter).map(x=>
                                    <ListItem 
                                        tower={x.item} 
                                        onDelete={()=>deleteTowerMutation.mutateAsync({id: x.item.Id})}
                                        edit={()=>{
                                            setCreationDrawerOpened(true)
                                            setEditing(true);
                                            setTabEditing('OPtower');
                                            setEditingId(x.item.Id);
                                        }}
                                    />
                                ) : <DBoffMock label={t("Нет опор")}/>
                            :
                                OPtowers && OPtowers.Page.length ? OPtowers.Page.map(x=>
                                    <ListItem 
                                        tower={x} 
                                        onDelete={()=>deleteTowerMutation.mutateAsync({id: x.Id})}
                                        edit={()=>{
                                            setCreationDrawerOpened(true)
                                            setEditing(true);
                                            setTabEditing('OPtower');
                                            setEditingId(x.Id);
                                        }}
                                    />
                                ) : <DBoffMock label={t("Нет опор")}/>
                            }
                            </Stack>
                        </Accordion.Panel>
                    </Accordion.Item>  

                    <Accordion.Item value="AL">
                        <Accordion.Control>
                            {t("ВЛ")}
                        </Accordion.Control>
                        
                        <Accordion.Panel>
                            <Stack style={{gap: 0}}>
                                {filter ?
                                    fuseOP && fuseOP.search(filter).length ? fuseOP.search(filter).map(x=>
                                        <ListItem 
                                            OPL={x.item}  
                                            onDelete={()=>deleteOPMutation.mutateAsync({id: x.item.Id})}
                                            edit={()=>{
                                                setCreationDrawerOpened(true)
                                                setEditing(true);
                                                setTabEditing('OP');
                                                setEditingId(x.item.Id)
                                            }}
                                            binding={()=>{
                                                setBindingOPLid(x.item.Id)
                                            }}
                                            unbinding={{
                                                companyId: company,
                                                OPLid: x.item.Id,
                                            }}
                                        />
                                    ) : <DBoffMock label={t("Нет ВЛ")}/>
                                :
                                    OPs && OPs.Page.length ? OPs.Page.map(x=>
                                        <ListItem 
                                            OPL={x}  
                                            onDelete={()=>deleteOPMutation.mutateAsync({id: x.Id})}
                                            edit={()=>{
                                                setCreationDrawerOpened(true)
                                                setEditing(true);
                                                setTabEditing('OP');
                                                setEditingId(x.Id);
                                            }}
                                            binding={()=>{
                                                setBindingOPLid(x.Id)
                                            }}
                                            unbinding={{
                                                companyId: company,
                                                OPLid: x.Id,
                                            }}
                                        />
                                    ) : <DBoffMock label={t("Нет ВЛ")}/>
                                }
                            </Stack>
                        </Accordion.Panel>
                    </Accordion.Item>  
                </Accordion>
            </div>

            <CreationDrawer
                opened  = {creationDrawerOpened}
                close   = {()=>{setCreationDrawerOpened(false); setEditing(false)}}
                company = {company}
                editing = {editing}
                tab     = {tabEditing}
                id      = {editingId}
            />

            <BindingOPLmodal
                close={()=>setBindingOPLid(undefined)}
                opened={!!bindingOPLid}
                OPLid={bindingOPLid || -1}
            />
        </div>
    )
}

export default OP