import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { axios } from "src/lib/axios";
import { EntityId } from "src/types";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { showNotification } from "@mantine/notifications";

export type bindTowerCommand = {
    "OverheadPowerLineTowerId": number,
    "OverheadPowerLineId": number,
    "TowerId"?: number | null,
}

export const bindTower = (command: bindTowerCommand): Promise<EntityId<number>> => {
    return axios.post(`/OverheadPowerLine/${command.OverheadPowerLineId}/tower/${command.OverheadPowerLineTowerId}/bind/${command.TowerId}`, command);
};

type UseBindTowerOptions = {
    command: bindTowerCommand,
    config?: MutationConfig<typeof bindTower>;
};

export const useBindTower = ({ command, config }: UseBindTowerOptions) => {
    const { t } = useTranslation();
    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries([`Tower${command.OverheadPowerLineTowerId}`, `Towers`, `OPLs`, `OPL${command.OverheadPowerLineId}`]);
        },
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка привязки опоры"),
                autoClose: 5000,
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries([`Tower${command.OverheadPowerLineTowerId}`, `Towers`, `OPLs`, `OPL${command.OverheadPowerLineId}`]);
            showNotification({
                title: t("Успех!"),
                message: t("Опора успешно привязана"),
                autoClose: 5000,
                color: "teal",
            });
        },
        ...config,
        mutationFn: bindTower,
    });
};
