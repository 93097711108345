import { Button, Drawer, Group, Select, Table, Tooltip } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { FirmwareDTO, OverheadPowerLineTowersPhaseEnum, Tower_full } from "../types";
import { useDisclosure } from "@mantine/hooks";
import { IconUpload } from "@tabler/icons-react";
import { SearchingList } from "src/features/monitoring_towers/components/SearchingList";
import { useFirmwares } from "src/features/firmware/api/getFirmwares";
import { useEffect, useState } from "react";
import { useFirmware } from "../api/getFirmware";
import dayjs from "dayjs";
import { useFirmwareDevices } from "../api/sendFirmwares";

type FirmwareTableRowProps = {
    cols: any[];
};

const FirmwareTableRow: React.FC<FirmwareTableRowProps> = ({ cols }) => {
    return (
        <tr>
            {cols.map((x) => (
                <td align="center">{x}</td>
            ))}
        </tr>
    );
};

type FirmwareProps = {
    tower: Tower_full;
};

export const Firmware: React.FC<FirmwareProps> = ({ tower }) => {
    type device_firmware_map = {
        [deviceId: number]: (FirmwareDTO & { Name?: string }) | null;
    };

    const FirmwareStatuses = {
        0: "NotRequired",
        1: "Waiting",
        2: "Running",
        3: "Sending",
        4: "Finishing",
        5: "Success",
        6: "Error",
        7: "CancelledAutomatically",
        8: "CancelledManually",
    };

    const { t } = useTranslation();

    const [opened, { open, close }] = useDisclosure(false);

    const { data: firmwares } = useFirmwares();
    const [selectedFirmwareId, setSelectedFirmwareId] = useState<number | null>(null);

    const [activePage, setPage] = useState(1);
    const { data: towerDevicesFirmwares } = useFirmware({
        request: {
            TowerId: tower.Id,
            Skip: (activePage - 1) * 100,
            Take: 100,
        },
        config: {
            keepPreviousData: true,
        },
    });

    const [device_firmware_map, set_device_firmware_map] = useState<device_firmware_map>();

    const firmwareDevicesMutation = useFirmwareDevices({ id: tower.Id });

    useEffect(() => {
        let tmp: device_firmware_map = {};
        if (tower && tower.DevicesDtos && towerDevicesFirmwares && firmwares) {
            for (let device of tower.DevicesDtos) {
                let firmware = towerDevicesFirmwares.filter((x) => x.DeviceId === device.Id)[0];
                let firmware_small = firmware ? firmwares?.filter((x) => x.Id === firmware.FirmwareId)[0] : null;
                tmp[device.Id] = firmware
                    ? Object.assign(firmware, firmware_small ? { Name: firmware_small.Name } : {})
                    : null;
            }
        }
        set_device_firmware_map(tmp);
    }, [towerDevicesFirmwares, firmwares]);

    useEffect(() => {
        close();
    }, [selectedFirmwareId]);

    return (
        <>
            {tower && tower.DevicesDtos && tower.DevicesDtos.length > 0 ? (
                <Table withColumnBorders style={{ height: "100%" }}>
                    <thead>
                        <tr>
                            <th></th>
                            {tower.DevicesDtos?.map((device) => (
                                <th
                                    style={{
                                        textAlign: "center",
                                        boxSizing: "border-box",
                                        width: `${tower.DevicesDtos ? 85 / tower.DevicesDtos?.length : 0}%`,
                                    }}
                                >
                                    <Tooltip
                                        label={
                                            <>
                                                <span>{`${t("Название")}: ${device.Name}`}</span>
                                                <br />
                                                <span>{`${t("Комментарий")}: ${device.Comment}`}</span>
                                                <br />
                                                <span>{`${t("CCID")}: ${device.CCID}`}</span>
                                            </>
                                        }
                                    >
                                        <div>
                                            {t("Фаза") +
                                                " " +
                                                (device.OverheadPowerLineTowersPhase
                                                    ? OverheadPowerLineTowersPhaseEnum[
                                                          device.OverheadPowerLineTowersPhase
                                                      ]
                                                    : "?")}
                                        </div>
                                    </Tooltip>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td align="center">{t("Загрузить прошивку")}</td>
                            <td
                                colSpan={(tower.DevicesDtos?.length ? tower.DevicesDtos?.length : 0) + 4}
                                style={{ border: "none", padding: 0 }}
                            >
                                <Group>
                                    <Button
                                        style={{
                                            margin: "10px",
                                            transition: "0.2s all",
                                            height: "30px",
                                            flexGrow: "1",
                                        }}
                                        variant="outline"
                                        onClick={open}
                                    >
                                        {selectedFirmwareId && firmwares
                                            ? firmwares.filter((firmware) => firmware.Id === selectedFirmwareId)[0].Name
                                            : // <IconUpload/>
                                              t("Выберите прошивку")}
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            firmwareDevicesMutation.mutateAsync(
                                                tower.DevicesDtos?.map((device) => ({
                                                    FirmwareId: selectedFirmwareId,
                                                    DeviceId: device.Id,
                                                })) || []
                                            )
                                        }
                                    >
                                        {t("Загрузить")}
                                    </Button>
                                </Group>
                            </td>
                        </tr>
                        <FirmwareTableRow
                            cols={[
                                t("Текущая прошивка"),
                                ...(tower && tower.DevicesDtos
                                    ? tower.DevicesDtos.map((x) =>
                                          device_firmware_map ? device_firmware_map[x.Id]?.Name || t("-") : t("-")
                                      )
                                    : []),
                            ]}
                        />
                        <FirmwareTableRow
                            cols={[
                                t("Статус прошивки"),
                                ...(tower && tower.DevicesDtos
                                    ? tower.DevicesDtos.map((x) =>
                                          device_firmware_map
                                              ? FirmwareStatuses[device_firmware_map[x.Id]?.Status || 0] || t("-")
                                              : t("-")
                                      )
                                    : []),
                            ]}
                        />
                        <FirmwareTableRow
                            cols={[
                                t("Страницы"),
                                ...(tower && tower.DevicesDtos
                                    ? tower.DevicesDtos.map((x) =>
                                          device_firmware_map &&
                                          device_firmware_map[x.Id]?.PageNumber &&
                                          device_firmware_map[x.Id]?.Pages
                                              ? `${device_firmware_map[x.Id]?.PageNumber}/${
                                                    device_firmware_map[x.Id]?.Pages
                                                }` || t("-")
                                              : t("-")
                                      )
                                    : []),
                            ]}
                        />
                        <FirmwareTableRow
                            cols={[
                                t("Начало прошивки"),
                                ...(tower && tower.DevicesDtos
                                    ? tower.DevicesDtos.map((x) =>
                                          device_firmware_map && device_firmware_map[x.Id]?.EndDate
                                              ? dayjs(device_firmware_map[x.Id]?.StartDate).format(
                                                    "DD.MM.YY HH:mm:ss"
                                                ) || t("-")
                                              : t("-")
                                      )
                                    : []),
                            ]}
                        />
                        <FirmwareTableRow
                            cols={[
                                t("Окончание прошивки"),
                                ...(tower && tower.DevicesDtos
                                    ? tower.DevicesDtos.map((x) =>
                                          device_firmware_map && device_firmware_map[x.Id]?.EndDate
                                              ? dayjs(device_firmware_map[x.Id]?.EndDate).format("DD.MM.YY HH:mm:ss") ||
                                                t("-")
                                              : t("-")
                                      )
                                    : []),
                            ]}
                        />
                        {/* <FirmwareTableRow
                cols={[t("Действия"),           ...(tower && tower.DevicesDtos) ? tower.DevicesDtos.map(x=>'') : []]}
            /> */}
                    </tbody>
                </Table>
            ) : (
                ""
            )}
            <Drawer
                opened={opened}
                onClose={close}
                position="right"
                title={t("Выбор прошивки")}
                padding="xl"
                size="xl"
                styles={{
                    drawer: {
                        overflowY: "auto",
                        msOverflowStyle: "none",
                        scrollbarWidth: "none",
                        "&::WebkitScrollbar": { width: 0 },
                    },
                }}
            >
                {/* <SearchingList
            elements={firmwares ? firmwares : []}
            id={selectedFirmwareId}
            setId={id=>setSelectedFirmwareId(id)}
        /> */}
                <Select
                    label={t("Прошивка")}
                    placeholder={t("Прошивка")}
                    searchable
                    nothingFound={t("Ничего не найдено")}
                    maxDropdownHeight={280}
                    value={selectedFirmwareId?.toString()}
                    data={firmwares?.map((x) => ({ label: x.Name, value: x.Id.toString() })) || []}
                    onChange={(e) => {
                        if (e) setSelectedFirmwareId(parseInt(e));
                    }}
                />
            </Drawer>
        </>
    );
};
