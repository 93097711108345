import { useForm } from "@mantine/form";
import { bindTowerCommand, useBindTower } from "../api/bindTower";
import { Button, NumberInput, Select } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useTowers } from "../api/getTowers";

type BindingTowerFormProps = {
    close: ()=>void,
    OPLid: number,
}

export const BindingTowerForm: React.FC<BindingTowerFormProps> = ({close, OPLid}) => {

    const { t } = useTranslation()

    const form = useForm<bindTowerCommand>({initialValues:{
        OverheadPowerLineTowerId: -1,
        OverheadPowerLineId: OPLid,
        TowerId: null,
    }})

    function handleClose() {
        close();
        form.reset();
    }

    const { data: towers } = useTowers()

    const bindTowerMutation = useBindTower({command: form.values})

    return(
        <form
            onSubmit={form.onSubmit(async (values) => {
                await bindTowerMutation.mutateAsync(values);
                handleClose();
            })}
        >

            <div className="mt-4">
                <span>
                    {t('Опора')}
                </span>
                <Select
                    placeholder={t("Выберите опору")}
                    searchable
                    nothingFound={t("Ничего не найдено")}
                    maxDropdownHeight={280}
                    value={form.values.OverheadPowerLineTowerId?.toString()}
                    data={
                        towers?.map(x=>({label: x.Name, value: x.Id.toString()})) || []
                    }
                    onChange={(e)=>{if (e) form.setFieldValue("OverheadPowerLineTowerId", parseInt(e))}}
                />
            </div>

            <div className="mt-4">
                <span>
                    {t('Номер внутри ВЛ')}
                </span>
                <NumberInput
                    placeholder={t("Номер влутри ВЛ")}
                    className="mt-2"
                    value={form.values.TowerId ? form.values.TowerId : 0}
                    onChange={(event)=>{
                        form.setFieldValue("TowerId", event ? event : null)
                    }}
                />
            </div>

            <div className="mt-4" style={{width: "100%"}}>
                <Button type="submit" style={{width: "100%"}}>{t("Сохранить")}</Button>
            </div>

        </form>
    )
}