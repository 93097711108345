import create from "zustand";
import { GraphicSettings } from "../components/GraphicList";
import { persist } from 'zustand/middleware'

export type GraphicsStore = {
    graphicsList?: GraphicSettings[];
    addGraphic: (x: GraphicSettings) => void;
    editGraphic: (x: GraphicSettings & {index: number}) => void,
    removeGraphic: (x: GraphicSettings) => void;
};

const pushToList = (state: {graphicsList?: GraphicSettings[]}, x: GraphicSettings) => {
    let tmp = state.graphicsList || []
    tmp.push (x)
    return tmp
}

const editListElement = (state: {graphicsList?: GraphicSettings[]}, x: GraphicSettings & {index: number}) => {
    let tmp = state.graphicsList || []
    tmp[x.index] = {...x}
    return tmp
}

const removeFromList = (state: {graphicsList?: GraphicSettings[]}, x: GraphicSettings) => {
    let tmp = state.graphicsList || []
    tmp = tmp.filter(elem => JSON.stringify(elem) !== JSON.stringify(x))
    return tmp
}

export const useGraphicsStore = create<GraphicsStore>(persist((set, get) => ({
    addGraphic: (x: GraphicSettings) => {
        set((state) => ({
            graphicsList: pushToList(state, x),
        }));
    },
    editGraphic: (x: GraphicSettings & {index: number}) => {
        set((state) => ({
            graphicsList: editListElement(state, x),
        }));
    },
    removeGraphic: (x: GraphicSettings) => {
        set((state) => ({
            graphicsList: removeFromList(state, x),
        }));
    },
    }), {
        name: "graphics-storage",
        getStorage: () => sessionStorage
    }
));
