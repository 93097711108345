import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { Box, LoadingOverlay } from "@mantine/core";
import { SideNav } from "src/components/layout/SideNav";
import { MainLayout } from "src/components/layout/MainLayout";
import MonitoringView from "src/views/MonitoringView";
import ReportView from "src/views/ReportView";
import { ProfileRoutes } from "src/features/profile";
import AdminView from "src/views/AdminView";
import FirmwareView from "src/views/FirmwareView";
import IECView from "src/views/IECView";
import InfoView from "src/views/InfoView";
import { useAuth } from "src/lib/auth";

import CompaniesView from "src/views/CompaniesView" 
import RegistrationForm from "src/features/users_new/components/RegistrationForm";
import { UserStateEnum } from "src/features/auth";
import { MapView } from "src/views/MapView";
import { MonitoringTowersView } from "src/views/MonitoringTowersView";
import { SetupView } from "src/views/SetupView";
import { GraphicView } from "src/views/GraphicView";

const App = () => {
    const { user } = useAuth()
    return (
        <MainLayout>
            {user?.UserState !== UserStateEnum.Invited ?
                <SideNav></SideNav>
            :''}
            <Suspense
                fallback={
                    <LoadingOverlay loaderProps={{ variant: "bars" }} visible />
                    // <div className="h-full w-full flex items-center justify-center">
                    //   <Loader />
                    // </div>
                }
            >
                <Box
                    sx={{
                        paddingLeft: user?.UserState !== UserStateEnum.Invited ? "81px" : '0px',
                        flex: "1 1",
                        height: "100vh",
                        display: "flex",
                        width: "calc(100% - 81px)",
                    }}
                >
                    <Outlet />
                </Box>
            </Suspense>
        </MainLayout>
    );
};

export const protectedRoutes = [
    {
        path: "/",
        element: <App />,
        children: [
            // { path: '/discussions/*', element: <DiscussionsRoutes /> },
            // { path: '/users', element: <Users /> },
            { path: "/", element: <Navigate to="/map" /> },
            { path: "/devices", element: <MonitoringView /> },
            { path: "/graphic/*", element: <GraphicView /> },
            { path: "/reports/*", element: <ReportView /> },
            { path: "/profile/*", element: <ProfileRoutes /> },
            { path: "/admin/*", element: <AdminView /> },
            { path: "/firmware/*", element: <FirmwareView /> },
            { path: "/info/*", element: <InfoView /> },
            { path: "/iec/*", element: <IECView /> },
            { path: "/map/*", element: <MapView /> },
            { path: "/monitoring/*", element: <MonitoringTowersView /> },
            { path: "/setup/*", element: <SetupView /> },
            { path: "*", element: <Navigate to="/devices" /> },

            { path: "/companies", element: <CompaniesView /> },
        ],
    },
];

export const unregisteredRoutes = [
    {
        path: "/",
        element: <App />,
        children: [
            { path: "/register", element: <RegistrationForm/>},
            { path: "*", element: <Navigate to="/register" />},
        ]
    }
]