import { Button, Drawer, Paper, Title} from "@mantine/core"
import { IconPlus } from "@tabler/icons-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Graphic } from "./Graphic"
import { useDisclosure } from "@mantine/hooks"
import { useGraphicsStore } from "../store"
import { GraphicForm, targetDeviceChannel } from "./GraphicFormNew"

export type GraphicSettings = {
    Name: string,
    targetDevices: targetDeviceChannel[],
    OPLs: number[],
    Towers: number[],
    Devices: number[],
    Channels: number[],
    startDt?: Date | null,
    endDt?: Date | null,
    averaging?: number | null,
} 

export const GraphicList: React.FC = () => {

    // const [ graphics, setGraphics ] = useState<GraphicSettings[]>([])

    const [ opened, {open, close} ] = useDisclosure(false)

    const {t} = useTranslation()

    const GraphicsStorage = useGraphicsStore()

    // const addGraphic = (graphic: GraphicSettings) => {
    //     let tmp = JSON.parse(JSON.stringify(graphics))
    //     tmp.push(graphic)
    //     setGraphics(tmp)
    // }

    const [editingGraphicSettings, setEditingGraphicSettings] = useState<GraphicSettings & {index: number} | undefined>()

    const StartGraphicEditing = (settings: GraphicSettings & {index: number}) => {
        setEditingGraphicSettings(settings)
        open()
    }

    const StopGraphicEditing = (settings?: GraphicSettings & {index: number}) => {
        close()
        if (settings)
            GraphicsStorage.editGraphic(settings)
        setEditingGraphicSettings(undefined)
    }

    return(<>
        <div className="mb-4" style={{overflow: "hidden", marginBottom: 0, boxSizing: "border-box", flexGrow: 1}}>
            <div style={{overflowY: "hidden", height: "100%"}}>
                <div style={{overflowY: "scroll", height: "100%"}}>
                    {(GraphicsStorage.graphicsList || []).length !== 0 ? <>{
                    (GraphicsStorage.graphicsList || []).map((graphic, index)=>
                        <Graphic
                            graphicSettings={graphic}
                            onDelete={()=>GraphicsStorage.removeGraphic(graphic)}
                            onEdit={()=>StartGraphicEditing({...graphic, index: index})}
                        />
                    )}
                    <Button 
                        variant="white" 
                        style={{width: "100%", transition: "0.2s background-color ease"}}
                        onClick={open}
                    >
                        <IconPlus/>
                    </Button></>
                    :
                    <Paper className="mb-4" p="md" shadow="sm" style={{overflow: "hidden", marginBottom: 0, boxSizing: "border-box", flexGrow: 1, textAlign: "left"}}>
                        <Title order={3} style={{ wordBreak: "break-word" }}>
                            {t("Создайте новый график")}
                        </Title>

                        <Button 
                            variant="outline" 
                            style={{width: "100%", transition: "0.2s background-color ease"}}
                            onClick={open}
                            className="mt-4"
                        >
                            <IconPlus/>
                        </Button>
                    </Paper>
                    }
                </div>
            </div>
        </div>
        <Drawer 
            opened={opened}
            onClose={()=>{close(); StopGraphicEditing()}}
            title={editingGraphicSettings ? t("Изменить график") : t("Добавить график")}
            padding="xl"
            size="xl"
            position="right"
            styles={{
                drawer: {
                    overflowY: "auto",
                },
            }}
        >
            <GraphicForm
                index   = {(GraphicsStorage.graphicsList || []).length}
                close   = {close}
                save    = {GraphicsStorage.addGraphic}
                editing = {editingGraphicSettings ? {
                    settings: editingGraphicSettings,
                    stop:     StopGraphicEditing,
                } : undefined}
            />
        </Drawer>
    </>)
}