import { useTranslation } from "react-i18next"
import { MonitoringTowersHead } from "src/features/monitoring_towers/components/MonitoringTowersHead"
import { GraphicList } from "./GraphicList"

export const GraphicPage: React.FC = () => {

    const {t} = useTranslation()
    
    return(
        <div
            className="p-4"
            style={{
                width: "100%",
                maxHeight: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
            }}
        >
            <MonitoringTowersHead
                label={t("Работа с графикой")}
            />
            <GraphicList/>
        </div>
    )
}