import React, {useEffect, useState} from "react";
import { useAuth } from "src/lib/auth";
import { TextInput, Button, Drawer, Paper } from "@mantine/core"
import { IconCaretLeft, IconSearch, IconChevronsLeft, IconChevronsRight } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import Fuse from "fuse.js";

import { RoleEnum, UserStateEnum } from "src/features/auth";
import { useCompanies, CompanyT } from "src/features/companies/api/getCompanies";
import CreateGroupForm from './CreateGroupForm';
import ListCollapser from "src/components/ListCollapser/ListCollapser";

interface CompanyProps {
    company:           CompanyT | null;
    select_company:    (company: CompanyT | null) => void;
    checked:           boolean;
}

const Company: React.FC<CompanyProps> = ({company, select_company, checked}) => {

    const { user } = useAuth()

    const { data: groups } = useCompanies(company?.Id ? company?.Id : 0 || 0);

    const select_parent_company = () => {
        if (groups && groups.length !== 0 && user?.Role !== RoleEnum.User)
            select_company(groups[0])
    }

    const [hover, setHover] = useState(false)
    return(
        <div
            onMouseEnter={()=>setHover(true)}
            onMouseLeave={()=>setHover(false)}
            onClick={()=>select_company(company)}
            onDoubleClick={select_parent_company}
            style={{
                userSelect: "none",
                boxSizing: "border-box",
                width: "100%",
                overflowX: "hidden",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
                backgroundColor: hover ? "#F3F4F6" : "#FFF",
                cursor: "pointer",
            }}
        >

            <div
                style={{
                    width: "5px",
                    height: "40px",
                    borderRadius: "0 5px 5px 0",
                    backgroundColor: checked ? '#CED4DA' : "inherit",
                }}
            ></div>

            <div
                style={{
                    display:       "flex",
                    flexDirection: "column",
                    padding:       "20px 15px",
                    boxSizing:     "border-box",
                    overflowX:     "hidden",
                    width:         "100%",
                }}
            >
                <span
                    style={{
                        width: "100%",
                        overflowX: "hidden",
                    }}
                >
                    {company?.Name}
                </span>
            </div>
        </div>
    )
}

type CompaniesListProps = {
    depth:                 number;
    select_company:        (company: CompanyT | null) => void;
    current_company:       CompanyT | null;
    path:                  CompanyT[];
    setPath:               (path: CompanyT[]) => void;
    userCompany:           CompanyT | null;
    hideCollapser?:        boolean;
    fw?:                   boolean;
    hideLabel?:            boolean;
    maxHeight?:            string;
    disableInputMargins?:  boolean;
}

const CompaniesList: React.FC<CompaniesListProps> = ({depth, select_company, current_company, path, setPath, userCompany, hideCollapser, fw, hideLabel, maxHeight, disableInputMargins}) => {

    const { t } = useTranslation();

    const { user } = useAuth()

    const { data: allCompanies } = useCompanies(0)
    const { data: companies } = useCompanies(current_company?.CompanyParentId ? current_company?.CompanyParentId : 0 || 0);
    const [filter, setFilter] = useState("");
    const [wraped, setWraped] = useState(false);
    const [wrapSectionHover, setWrapSectionHover] = useState(false);
    const [createGrouplDrawerOpen, setCreateGrouplDrawerOpen] = useState(false)

    const [usersCompany, setUsersCompany] = useState<CompanyT>()

    useEffect(()=>{
        if (companies)
            setUsersCompany(companies.filter(comp=>comp.Id === userCompany?.CompanyParentId)[0])
    }, [user, companies])

    const fuse = new Fuse(companies?.filter(comp=> user?.Role !== RoleEnum.SuperAdmin ?
        comp.CompanyParentId ? comp.CompanyParentId !== usersCompany?.CompanyParentId : false || comp.Id === user?.CompanyId
    : true) ?? [], {
        keys: ["Name"],
    });

    const go_back = () => {
        let tmp = path

        if (user?.CompanyId !== current_company?.Id){
            if (tmp.length > 1)
                select_company(tmp[tmp.length-2])
            else if (tmp.length === 1){
                select_company(null)
            }
        }
    }

    useEffect(()=>{
        if (current_company && (current_company?.Id !== user?.CompanyId) && (path.length === 1)){
            let tmp = [current_company] as CompanyT[]
            let flg = false
            let group = current_company
            while (!flg){
                let parComp = allCompanies?.filter(comp => comp.Id === group?.CompanyParentId)[0]
                if (parComp){
                    tmp.unshift(parComp)
                    group = parComp
                }
                flg = (parComp?.Id === user?.CompanyId) || (!(parComp?.CompanyParentId))
            }
            setPath(tmp)
        }
    }, [current_company, path])

    return (
        <>
            <ListCollapser enabled={!hideCollapser}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "#FFF",
                        gap: "0px",
                        width: fw ? "100%" : "400px",
                        height: maxHeight ? maxHeight : "100%"
                    }}
                >
                    <div className="p-2 mb-2"
                        style={{
                            paddingLeft: disableInputMargins ? '0' : undefined,
                            paddingRight: disableInputMargins ? '0' : undefined,
                        }}
                    >
                        {hideLabel ? '' :
                        <div
                            className="font-medium text-xl mb-4"
                        >
                            {t("Компании")}
                        </div>
                    }
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "9px",
                                width: "100%",
                                boxSizing: "border-box",
                            }}
                        >
                            <TextInput
                                value={filter}
                                onChange={event=>setFilter(event.currentTarget.value)}
                                icon={<IconSearch/>}
                                placeholder={t("Поиск")}
                                styles={{
                                    input: {
                                    },
                                    root: {
                                        width: "100%",
                                    }
                                }}
                            />
                            {(user?.CompanyId !== current_company?.Id && user?.Role !== RoleEnum.User && depth > 1) ?
                                <Button
                                    variant="default"
                                    leftIcon={<IconCaretLeft color="#ADB5BD" fill="#ADB5BD"/>}
                                    onClick={go_back}
                                    styles={{
                                        leftIcon:{
                                            margin: 0
                                        },
                                        root: {
                                            padding: 0,
                                            height: "36px",
                                            width: "36px"
                                        }
                                    }}
                                />
                            : ''}
                        </div>
                        {user?.Role === RoleEnum.SuperAdmin ? (
                            <Button
                                onClick={()=>setCreateGrouplDrawerOpen(true)}
                                style={{width: "100%", marginTop: "3px"}}
                            >
                                {t("Добавить компанию")}
                            </Button>
                        ) : ''}
                    </div>
                    
                    <div className="w-full overflow-y-auto">
                        <div className="h-full">
                            {filter ?
                                fuse?.search(filter).map((results)=>(
                                    <Company
                                        company               = {results.item}
                                        select_company        = {select_company}
                                        checked               = {results.item?.Id===current_company?.Id}
                                    />
                                ))
                                :
                                companies?.filter(comp=> (user?.Role !== RoleEnum.SuperAdmin && user?.CompanyId) ?
                                    comp.CompanyParentId ? comp.CompanyParentId !== usersCompany?.CompanyParentId : false || comp.Id === user?.CompanyId
                                : true).map(company=>(
                                    <Company
                                        company=        {company}
                                        select_company= {select_company}
                                        checked=        {company?.Id===current_company?.Id}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </ListCollapser>

            <Drawer
                opened={createGrouplDrawerOpen}
                onClose={() => setCreateGrouplDrawerOpen(false)}
                title={t("Добавить группу")}
                padding="xl"
                size="xl"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                <CreateGroupForm
                    setOpen={setCreateGrouplDrawerOpen}
                    current_company_id = {current_company?.CompanyParentId ? current_company?.CompanyParentId : 0 || null}
                />
            </Drawer>
        </>
    );
};

export default CompaniesList