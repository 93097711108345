// /// <reference path="../../ymaps.d.ts" />
// import * as ymaps from "YandexMaps";

import ymaps from "yandex-maps";

const SET_BOUNDS_DEFAULT_OPTIONS = {
    checkZoomRange: true,
    zoomMargin: 20,
};

export default class YandexMap {
    private mapLayer: ymaps.Map;
    private clusterer: ymaps.Clusterer;
    public ymapsAPI: typeof ymaps;

    constructor(ymapsAPI: typeof ymaps, element: string, options: any) {
        this.ymapsAPI = ymapsAPI;
        this.mapLayer = new ymapsAPI.Map(element, {
            controls: ["rulerControl"],
            ...options,
        });

        const typeSelector = this.createTypeSelectorControl();
        this.mapLayer.controls.add(typeSelector);

        const zoomControl = this.createZoomControl();
        this.mapLayer.controls.add(zoomControl);

        this.clusterer = new ymapsAPI.Clusterer({
            hasBalloon: false,
            //@ts-ignore
            clusterIconLayout: "default#pieChart",
            clusterIconPieChartRadius: 14,
            // Радиус центральной части макета.
            clusterIconPieChartCoreRadius: 10,
            // Ширина линий-разделителей секторов и внешней обводки диаграммы.
            clusterIconPieChartStrokeWidth: 0,
            clusterBalloonLeftColumnWidth: 200,
            clusterDisableClickZoom: false,
        });
        //@ts-ignore
        this.mapLayer.geoObjects.add(this.clusterer);

        const mapType = localStorage.getItem("mapType");
        if (mapType) {
            this.mapLayer.setType(mapType);
        }
        this.mapLayer.events.add("typechange", () => {
            localStorage.setItem("mapType", this.mapLayer.getType().toString());
        });
    }

    public destroy() {
        this.mapLayer.destroy();
    }

    public addLayerToClusterer(positionMarker: any) {
        this.clusterer.add(positionMarker);
    }

    public removeLayerFromClusterer(positionMarker: any) {
        this.clusterer.remove(positionMarker);
    }

    public addLayer(track: any) {
        this.mapLayer.geoObjects.add(track);
    }

    public add_control(control: any, config: any) {
        this.mapLayer.controls.add(control, config)
    }

    public removeLayer(track: any) {
        this.mapLayer.geoObjects.remove(track);
    }

    public addEvent(event: any, callback: any) {
        this.mapLayer.events.add(event, callback);
    }

    public removeEvent(event: any, callback: any) {
        this.mapLayer.events.remove(event, callback);
    }

    public isLayerOnMap(layer: any) {
        return this.mapLayer.geoObjects.indexOf(layer) > -1;
    }

    public setBounds(bounds: any) {
        //@ts-ignore
        this.mapLayer.setBounds(bounds, SET_BOUNDS_DEFAULT_OPTIONS);
    }

    public setCenter(center: any) {
        this.mapLayer.setCenter(center);
    }

    public setZoom(zoom: number = 12) {
        this.mapLayer.setZoom(zoom);
    }

    public getZoom() {
        return this.mapLayer.getZoom();
    }

    public getBounds() {
        return this.mapLayer.getBounds();
    }

    public getCenter() {
        return this.mapLayer.getCenter();
    }

    public focusToLayers(layers: any) {
        const layersBounds = [];
        for (const layer of layers) {
            const layerBounds = layer.getBounds();
            layersBounds.push(layerBounds);
        }
        const bounds = this.ymapsAPI.util.bounds.fromBounds(layersBounds);
        this.setBounds(bounds);
    }

    public fitToViewport() {
        this.mapLayer.container.fitToViewport();
    }

    private createTypeSelectorControl() {
        this.addYandexMapType();
        return new this.ymapsAPI.control.TypeSelector({
            //@ts-ignore
            mapTypes: ["yandex#map"],
            options: {
                panoramasItemMode: "off",
            },
        });
    }

    private addYandexMapType() {
        const osmMapType = new this.ymapsAPI.MapType("Yandex", ["yandex#map"]);
        //@ts-ignore
        this.ymapsAPI.mapType.storage.add("yandex#map", osmMapType);
    }

    private createZoomControl() {
        return new this.ymapsAPI.control.ZoomControl({
            options: {
                position: {
                    top: 60,
                    right: 10,
                },
            },
        });
    }
}
