import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import ReactApexChart from "react-apexcharts";
import { ChartData } from "src/features/devices/types";
import { flatten, isEmpty, max } from "lodash";
import { useTranslation } from "react-i18next";

const colors = [
    // "#6929c4",
    // "#1192e8",
    // "#005d5d",
    // "#9f1853",
    // "#520408",

    "#BF2600",
    "#FF8B00",
    "#006644",
    "#008DA6",
    "#403294",
    "#0747A6",
    "#091E42",

    // "#DE350B",
    // "#FF991F",
    // "#00875A",
    // "#00A3BF",
    // "#5243AA",
    // "#0052CC",
    // "#253858",

    // "#FF5630",
    // "#FFAB00",
    // "#36B37E",
    // "#00B8D9",
    // "#8777D9",
    // "#0065FF",
    // "#42526E",
];

enum ThresholdType {
    Upper,
    Lower,
}

interface ChannelValueThreshold {
    MinValue: number;
    MaxValue: number;
    Value: number; // значение срабатывания уставки
    Description: string; // описание уставки
    Type: ThresholdType; // тип уставки (вниз или вверх срабатывает)
    Color: string;
}

// 121 17990722 МДД ТУЛА2 3289
const thresholds1: ChannelValueThreshold[] = [
    {
        MinValue: 0.15,
        MaxValue: 0.3,
        Value: 0.15,
        Description: "0.15 кг/м",
        Type: ThresholdType.Upper,
        Color: "#FCC419",
    },
    // {
    //     MinValue: 0.3,
    //     MaxValue: 0.6,
    //     Value: 0.3,
    //     Description: "0.3 кг/м",
    //     Type: ThresholdType.Upper,
    //     Color: "#FF922B",
    // },
    // {
    //     MinValue: 0.6,
    //     MaxValue: 1,
    //     Value: 0.6,
    //     Description: "0.6 кг/м",
    //     Type: ThresholdType.Upper,
    //     Color: "#E03131",
    // },
];

// 110 90540921 (МТС) 3295
const thresholds2: ChannelValueThreshold[] = [
    {
        MinValue: 0.3,
        MaxValue: 0.7,
        Value: 0.3,
        Description: "0.3 кг/м",
        Type: ThresholdType.Upper,
        Color: "#FCC419",
    },
    {
        MinValue: 0.7,
        MaxValue: 1.3,
        Value: 0.7,
        Description: "0.7 кг/м",
        Type: ThresholdType.Upper,
        Color: "#FF922B",
    },
    {
        MinValue: 1.3,
        MaxValue: 2,
        Value: 1.3,
        Description: "1.3 кг/м",
        Type: ThresholdType.Upper,
        Color: "#E03131",
    },
];

const thresholds: { [key: number]: ChannelValueThreshold[] } = {
    3289: thresholds1,
    3295: thresholds2,
};

interface ChartProps {
    isMobile?: boolean;
    chartData: ChartData;
    height?: number;
    selectedChannels: number[];
    selectedDevice?: number;
    selectedDeviceName?: string;
}

function getChartData(chartData: ChartData, selectedChannels: number[], t: (key: string) => string) {
    return chartData.ChartChannelData.filter((x) => selectedChannels.includes(x.Id)).map((x, i) => ({
        name: t(x.Name),
        data: x.Values.map((y, j) => ({
            x: x.Timestamps[j],
            y: typeof y === "number" && !isNaN(y) ? y.toFixed(2) : null,
        })),
    }));
}

function getAnnotations(thresholds: ChannelValueThreshold[], index: number, t: (key: string) => string) {
    if (isEmpty(thresholds)) {
        return [];
    }

    return [
        ...thresholds.map((threshold) => ({
            y: threshold.Value,
            y2: threshold.MaxValue,
            fillColor: threshold.Color,
            yAxisIndex: index,
        })),
        ...thresholds.map((threshold) => ({
            y: threshold.Value,
            borderColor: threshold.Color,
            strokeDashArray: 0,
            yAxisIndex: index,
            label: {
                borderColor: threshold.Color,
                style: {
                    color: "#fff",
                    background: threshold.Color,
                },
                text: t(threshold.Description),
            },
        })),
    ];
}

function getChartOptions(
    chartData: ChartData,
    selectedChannels: number[],
    currentZoom: [number, number],
    onZoom: (min: number, max: number) => void,
    t: (key: string) => string,
    deviceId?: number,
    selectedDeviceName?: string,
) {
    return {
        chart: {
            type: "line",
            stacked: false,
            id: selectedDeviceName ? `Device_${selectedDeviceName}_chart` : "energy-chart",
            events: {
                zoomed: function (chartContext: any, { xaxis, yaxis }: any) {
                    onZoom(xaxis.min, xaxis.max);
                },
            },
            zoom: {
                enabled: true,
                autoScaleYaxis: false,
            },
        },
        legend: {
            show: false,
        },
        colors: chartData.ChartChannelData.filter((x) => selectedChannels.includes(x.Id)).map((_x, i) => colors[i % 7]),
        stroke: {
            width: chartData.ChartChannelData.filter((x) => selectedChannels.includes(x.Id)).map(() => 2),
        },
        plotOptions: {
            bar: {
                columnWidth: "20%",
            },
        },
        xaxis: {
            min: currentZoom[0] === 0 ? undefined : currentZoom[0],
            max: currentZoom[1] === 0 ? undefined : currentZoom[1],
            type: "datetime",
            labels: {
                rotate: 0,
                formatter: function (value: string) {
                    return dayjs(value).format("DD.MM.YY HH:mm");
                },
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: chartData.ChartChannelData.filter((x) => selectedChannels.includes(x.Id)).map((x, i) => ({
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: colors[i % 7],
            },
            labels: {
                style: {
                    colors: colors[i % 7],
                },
                formatter: function (value: string) {
                    const numberValue = Number(value);
                    return t(x.Unit)
                        ? `${
                              Number.isInteger(numberValue) ? value : !isNaN(numberValue) ? numberValue.toFixed(2) : ""
                          } ${t(x.Unit)}`
                        : numberValue.toFixed(2);
                },
            },
            title: {
                text: t(x.Name),
                style: {
                    color: colors[i % 7],
                },
            },
            max: function getmax(maxValue: number) {
                return x.Id === 3289 ? 0.3 : x.Id === 3295 ? max([0.4, maxValue]) : maxValue;
            },
        })),
        annotations: {
            yaxis: flatten(
                chartData.ChartChannelData.filter((x) => selectedChannels.includes(x.Id)).map((x, i) =>
                    getAnnotations(thresholds[x.Id], i, t)
                )
            ),
        },
    };
}

const ChartWrapper: React.FC<ChartProps> = ({ chartData, height, selectedChannels, selectedDevice, selectedDeviceName }) => {
    const { t } = useTranslation();
    const [zoom, setZoom] = useState<[number, number]>([0, 0]);
    function setCurrentZoom(min: number, max: number) {
        setZoom([min, max]);
    }
    const [chartOptions, setChartOptions] = useState<any>(() =>
        getChartOptions(chartData, selectedChannels, zoom, setCurrentZoom, t, selectedDevice, selectedDeviceName)
    );
    const [chartSeries, setChartSeries] = useState<any>(getChartData(chartData, selectedChannels, t));
    const chartRef = useRef<ReactApexChart>(null);

    useEffect(() => {
        setZoom([0, 0]);
    }, [selectedDevice]);

    useEffect(() => {
        function drawChart() {
            if (chartData) {
                // if (!chartOptions.xaxis) {
                setChartOptions(getChartOptions(chartData, selectedChannels, zoom, setCurrentZoom, t, selectedDevice, selectedDeviceName));
                // }

                setChartSeries(getChartData(chartData, selectedChannels, t));
            }
        }

        drawChart();
    }, [chartData, selectedChannels, selectedDeviceName]);

    return <Chart ref={chartRef} options={chartOptions} series={chartSeries} height={height} width="100%"></Chart>;
};

export default ChartWrapper;
