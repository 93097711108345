import React from "react";
import { useTranslation } from "react-i18next";
import { Group, SegmentedControl, Stack, Button, Paper, Title, Text, ActionIcon } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useAuth } from "src/lib/auth";

import { CompanyT } from "src/features/companies/api/getCompanies";
import { useDeleteGroup } from "../api/deleteCompany";
import { RoleEnum } from "src/features/auth";

interface CompaniesHeadProps {
    tab: string;
    select_tab: (tab: string) => void;
    company: CompanyT | null;
    path: CompanyT[];
    openEditor: () => void;
}

const CompaniesHead: React.FC<CompaniesHeadProps> = ({ tab, select_tab, company, path, openEditor }) => {
    const { t } = useTranslation();

    const mutation = useDeleteGroup(company?.CompanyParentId || -1);

    const { user } = useAuth();

    return (
        <Paper className="mb-4" p="md" shadow="sm" style={{ boxSizing: "border-box" }}>
            <Stack>
                <Group position="apart" grow>
                    <Stack>
                        <Title order={2} style={{ wordBreak: "break-word" }}>
                            {company?.Name || t("Компания не выбрана")}
                        </Title>
                        {path && path.length > 1 && path[0] ? (
                            <Text color="gray" size="md">
                                {path.reduce(
                                    (accumulator, item) =>
                                        item.Name.length > 15
                                            ? accumulator + "/" + item.Name.slice(0, 5) + "..." + item.Name.slice(-5)
                                            : accumulator + "/" + item.Name,
                                    ""
                                )}
                            </Text>
                        ) : (
                            ""
                        )}
                    </Stack>
                    {company && user?.Role !== RoleEnum.User ? (
                        <Group style={{ maxWidth: "fit-content" }}>
                            <ActionIcon onClick={company ? openEditor : () => {}} size="lg" variant="default">
                                <IconEdit size={20} />
                            </ActionIcon>

                            <ActionIcon
                                onClick={() => mutation.mutateAsync({ id: company?.Id || -1 })}
                                size="lg"
                                variant="outline"
                                color="red"
                            >
                                <IconTrash size={20} />
                            </ActionIcon>
                        </Group>
                    ) : (
                        ""
                    )}
                </Group>

                <SegmentedControl
                    data={
                        user?.Role !== RoleEnum.User
                            ? [
                                  { label: t("ВЛ"), value: "OP" },
                                  { label: t("Пользователи"), value: "users" },
                                  { label: t("Группы"), value: "groups" },
                                  { label: t("Уведомления"), value: "notifications" },
                              ]
                            : [
                                  { label: t("ВЛ"), value: "VL" },
                                  { label: t("Пользователи"), value: "users" },
                                  { label: t("Группы"), value: "groups" },
                                  { label: t("Уведомления"), value: "notifications" },
                              ]
                    }
                    styles={{
                        label: {
                            width: "fit-content",
                        },
                        control: {
                            width: "fit-content",
                        },
                        root: {
                            width: "fit-content",
                        },
                    }}
                    value={tab}
                    onChange={select_tab}
                />
            </Stack>
        </Paper>
    );
};

export default CompaniesHead;
