import { useForm } from "@mantine/form";
import { Button, NumberInput, SegmentedControl, Select } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useTowers } from "../api/getTowers";
import { bindDeviceCommand, useBindDevice } from "../api/bindDevice";
import { OverheadPowerLineTowersPhaseEnum } from "../types";
import { useDevices } from "src/features/devices/api/getDevices";

type BindingDeviceFormProps = {
    close: ()=>void,
    towerId: number,
}

export const BindingDeviceForm: React.FC<BindingDeviceFormProps> = ({close, towerId}) => {

    const { t } = useTranslation()

    const form = useForm<bindDeviceCommand>({initialValues:{
        OverheadPowerLineTowerId: towerId,
        OverheadPowerLineTowersPhase: OverheadPowerLineTowersPhaseEnum.A,
        Id: -1,
    }})

    function handleClose() {
        close();
        form.reset();
    }

    const { data: devices } = useDevices()

    const bindTowerMutation = useBindDevice({command: form.values})

    return(
        <form
            onSubmit={form.onSubmit(async (values) => {
                await bindTowerMutation.mutateAsync(values);
                handleClose();
            })}
        >

            <div className="mt-4">
                <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                    {t("Выберите устройство")}
                </span>
                <Select
                    placeholder={t("Выберите устройство")}
                    searchable
                    nothingFound={t("Ничего не найдено")}
                    maxDropdownHeight={280}
                    value={form.values.Id?.toString() || ''}
                    data={
                        devices?.map(x=>({label: x.Name, value: x.Id.toString()})) || []
                    }
                    onChange={e=>form.setFieldValue('Id', parseInt(e || ''))}
                />
            </div>

            <div className="mt-4">
                <span className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2">
                    {t("Выберите фазу")}
                </span>
                <SegmentedControl
                    className="mt-2"
                    fullWidth
                    value={form.values.OverheadPowerLineTowersPhase.toString()}
                    onChange={event=>{
                        form.setFieldValue("OverheadPowerLineTowersPhase", parseInt(event) as OverheadPowerLineTowersPhaseEnum)
                    }}
                    data={[
                        {label: 'A', value: OverheadPowerLineTowersPhaseEnum.A.toString()},
                        {label: 'B', value: OverheadPowerLineTowersPhaseEnum.B.toString()},
                        {label: 'C', value: OverheadPowerLineTowersPhaseEnum.C.toString()},
                    ]}
                />
            </div>

            <div className="mt-4" style={{width: "100%"}}>
                <Button type="submit" style={{width: "100%"}}>{t("Сохранить")}</Button>
            </div>

        </form>
    )
}